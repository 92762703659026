// import the react components
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

// import the custome components
import Modal from "./../modal";
import TextEditor from "./../../components/text-editor";

// import the styles
import {input_style} from "./../../assets/data/style";

// import the api components
import axios from "axios";
import {CASE_API} from "./../../assets/data/api";

// import the context
import {useStateContext} from "./../../context/";

// the case modal functional based component
const CaseModal = ({patient = null}) => {
    const {set_case_modal, selected_case, set_selected_case, is_loged, dispatch_case, set_alert} = useStateContext();
    const [state, set_state] = useState({
        title: selected_case ? selected_case.title : "",
        description: selected_case ? selected_case.description : "",
    });

    // the navigate
    const navigate = useNavigate();

    // close the modal
    const handle_close = () => {
        set_case_modal(false);
        set_selected_case(null);
    };

    // handle the create and update
    const handle_submit = () => {

        // prepare the query
        const endpoint = CASE_API + `${selected_case ? "update.php" : "create.php"}`;
        const data = {
            ...state,
            id: selected_case ? selected_case.id : "",
            username: is_loged.username,
            patient,
        };

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success){
                selected_case ? dispatch_case({type: "update", payload: data.case}) : dispatch_case({type: "push", payload: data.case});
                set_alert({type: "success", text: "the case saved successfully"});
                handle_close();
            }

            // if the error occured
            else if (data.error) set_alert({type: "error", text: data.error});
        })
    };

    // handle the delete
    const handle_delete = () => {

        // prepare the query
        const endpoint = CASE_API + "delete.php";
        const data = {id: selected_case.id};

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success){
                dispatch_case({type: "delete", payload: selected_case});
                set_alert({type: "success", text: "the case deleted successfully"});
                handle_close();
            }

            // if the error occured
            else if (data.error) set_alert({type: "error", text: data.error});
        });
    };

    // the footer buttons
    const buttons = [
        {
            title: "save",
            handler: handle_submit,
        },
    ];
    if (selected_case){
        buttons.unshift({
            title: "delete",
            handler: handle_delete,
        });
        buttons.unshift({
            title: "view",
            handler: () => navigate(`/view/case/${selected_case.id}`),
        });
    }

    // extract the data
    const {title, description} = state;

    // return to the component data
    return (
        <Modal handle_close={handle_close} buttons={buttons} z_index={10}>
            <div className="grid grid-cols-1 gap-y-4">
                <input
                    type="text"
                    name="title"
                    placeholder="set the title"
                    value={title}
                    onChange={event => set_state({...state, title: event.target.value})}
                    className={`${input_style}`}
                    required
                />

                <TextEditor name="description" html={description} placeholder="set the description" handler={event => set_state({...state, description: event.target.value})} />
            </div>
        </Modal>
    );
};
export default CaseModal;