// import the react components
import React from "react";

// import the context
import {useStateContext} from "./../../context/";

// the tip item functional based component
const TipItem = ({item}) => {
    const {is_loged, set_tip_modal, set_selected_tip} = useStateContext();

    // handle the click
    const handle_click = () => {
        if (item.username === is_loged.username){
            set_tip_modal(true);
            set_selected_tip(item);
        };
    };

    // return to the component data
    return (
        <blockquote className="relative border-l-4 border-primary pl-4 sm:pl-6 dark:border-gray-700 cursor-pointer hover:opacity-80" onClick={handle_click}>
            <p className="text-gray-700 sm:text-xl dark:text-white">{item.tip}</p>

            <footer className="mt-4 ml-4">
                <div className="text-base font-semibold text-gray-800 dark:text-gray-400">- {item.username}</div>
            </footer>
        </blockquote>
    );
};
export default TipItem;