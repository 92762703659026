// import the react components
import React, {useState} from "react";

// import the custome components
import Button from "./../button";

// import the api components
import axios from "axios";
import {CONTACT_API} from "./../../assets/data/api";

// import the inputs
import {contact_inputs} from "./../../assets/data/inputs";

// import the context
import {useStateContext} from "./../../context/";

// the email us functional based component
const EmailUs = () => {
    const {is_loged, set_alert, is_disable, set_is_disable} = useStateContext();
    const init_state = {
        email: is_loged ? is_loged.mail : "",
        subject: "",
        message: "",
    };
    const [state, set_state] = useState(init_state);

    // send the data to the server
    const submit_handler = event => {
        event.preventDefault();
        set_is_disable(true);

        // prepare the query
        const endpoint = CONTACT_API + "create.php";
        const username = is_loged ? is_loged.username : "demo";
        const data = {...state, username};

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success){
                set_state(init_state);
                set_alert({type: "success", text: "message sent successfuly"});
            }

            // if the error occured
            else if (data.error) set_alert({type: "error", text: data.error});

            // remove the disable
            set_is_disable(false);
        });
    };

    // the state handler
    const state_handler = event => set_state(preview => ({...preview, [event.target.name]: event.target.value}));

    // return to the component data
    return (
        <div className={`p-2 mx-auto max-w-screen-md`}>
            <div className="space-y-5">
                {contact_inputs.map(item => (item.type === "text" ?
                <div key={item.name}>
                    <label htmlFor={item.name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300 capitalize">{item.name}</label>
                    <input type="text" name={item.name} id={item.name} className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-md border border-gray-300 shadow-sm focus:ring-primary focus:border-primary dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary dark:focus:border-primary dark:shadow-sm-light" placeholder={item.placeholder} value={state[item.value]} onChange={state_handler} required />
                </div>
                :
                <div className="sm:col-span-2" key={item.name}>
                    <label htmlFor={item.name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400 capitalize">{item.name}</label>
                    <textarea id={item.name} name={item.name} rows="5" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-md shadow-sm border border-gray-300 focus:ring-primary focus:border-primary dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary dark:focus:border-primary" placeholder={item.placeholder} value={state[item.value]} onChange={state_handler} required></textarea>
                </div>))}

                <Button title="send message" handler={submit_handler} action="button" custom_class="w-40" disable={is_disable} />
            </div>
        </div>
    );
};
export default EmailUs;