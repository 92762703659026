// import the react componentns
import React from "react";
import {Link, NavLink, useLocation} from "react-router-dom";

// import the sidebar links and styles
import {sidebar_links, active_link, passive_link} from "./../assets/data/sidebar";

// import the general data
import {company_name} from "./../assets/data/general";

// import the context
import {useStateContext} from "./../context/";

// import the logo
import logo from "./../assets/images/logo.png";

// import the colors
import {gradient_primary} from "./../assets/data/colors";

// the sidebar functional based component
const SideBar = () => {
    const {active_menu, set_active_menu, screen_size} = useStateContext();

    // close the sidebar
    const handle_close_sidebar = () => {
        if (active_menu && screen_size <= 900) set_active_menu(false);
    };

    // check if the current webpage is active
    const {pathname} = useLocation();

    // return to the component data
    return (
        <div className="h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-5" style={{zIndex: 200}}>

            {/* the top of the sidebar */}
            <div className="flex justify-center items-center">
                <Link to="/schedule" onClick={handle_close_sidebar} className="flex justify-center items-center gap-x-2 mt-4">
                    <img src={logo} alt="peyvin dashboard" className="w-12" />
                    <span className="text-2xl font-medium tracking-tight dark:text-white text-slate-900 text-primary">{company_name}</span>
                </Link>
            </div>

            {/* the items of the sidebar */}
            <div className="mt-5 tracking-wider">
                {sidebar_links.map(item => (
                    <div key={item.title}>
                        <p className="text-gray-400 m-3 mt-4 uppercase">{item.title}</p>
                        {item.links.map(link => (
                            <NavLink key={link.name} to={`/${link.name}`} onClick={handle_close_sidebar} className={pathname === `/${link.name}` ? active_link : passive_link} style={{background: pathname === `/${link.name}` && gradient_primary}}>
                                <span>{link.icon}</span>
                                <span className="">
                                    {link.name}
                                </span>
                            </NavLink>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};
export default SideBar;