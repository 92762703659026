// import the react components
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import useNavigation from "./../context/navigation";

// import the custome components
import Alerter from "./../components/alerter";
import Header from "./../ui/registration/header";
import Input from "./../ui/registration/input";
import FormAction from "./../ui/registration/form-action";
import Footer from "./../components/footer";

// import the api data
import axios from "axios";
import {USER_API} from "./../assets/data/api";

// import the context
import {useStateContext} from "./../context/";

// import the login data
import {logup_fields} from "./../assets/data/registration";
const fields = logup_fields;
let field_state = {};
fields.forEach(field => field_state[field.id] = "");

// the logup functional based component
const LogUp = () => {
    const {set_alert, set_is_loged, set_is_disable} = useStateContext();
    const [logup, set_logup] = useState(field_state);
    useNavigation();

    // the navigation
    const navigate = useNavigate();

    // handle the input change
    const handle_change = event => set_logup({...logup, [event.target.id]: event.target.value});

    // handle the form submit
    const handle_submit = event => {
        event.preventDefault();
        set_is_disable(true);

        // prepare the query
        const endpoint = USER_API + "create.php";
        const data = {...logup};

        // handle the query
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success){
                set_is_loged(data.user);
                navigate("/auth/verification");
            }

            // if error occured show the alerter
            else if (data.error) set_alert({type: "error", text: data.error});

            // remove the disable
            set_is_disable(false);
        });
    };

    // return to the component data
    return (
        <div className="-m-5 min-h-screen h-full flex flex-col items-center justify-center py-6 px-4 sm:px-6 lg:px-8">
            <Alerter />

            {/* the logup form */}
            <div className="max-w-md w-full space-y-8 p-4">
                <Header
                    heading="Logup a dashboard"
                    paragraph="so far have a dashboard?"
                    link_name="Login"
                    link_url="/login"
                />

                <form className="space-y-6">
                    <div>
                        {fields.map(field=>
                            <Input
                                key={field.id}
                                input_data={field}
                                handle_change={handle_change}
                                value={logup[field.id]}
                            />
                        )}
                    </div>

                    <FormAction text="request" handle_submit={handle_submit} />
                </form>
            </div>

            {/* the footer */}
            <Footer copy={false} />
        </div>
    );
};
export default LogUp;