// import the react components
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

// import the custome components
import Modal from "./../modal";
import TextEditor from "./../../components/text-editor";

// import the styles
import {input_style} from "./../../assets/data/style";

// import the api components
import axios from "axios";
import {COURSE_API} from "./../../assets/data/api";

// import the context
import {useStateContext} from "./../../context/";

// import the categories
import {clear_book_categories} from "./../../assets/data/book";

// the course modal functional based component
const CourseModal = () => {
    const {set_course_modal, selected_course, set_selected_course, is_loged, dispatch_course, set_alert} = useStateContext();
    const [state, set_state] = useState({
        category: selected_course ? selected_course.category : clear_book_categories[0],
        title: selected_course ? selected_course.title : "",
        subtitle: selected_course ? selected_course.subtitle : "",
        introduction: selected_course ? selected_course.introduction : "",
        learn: selected_course ? selected_course.learn : "",
        content: selected_course ? selected_course.content : "",
        requirement: selected_course ? selected_course.requirement : "",
        description: selected_course ? selected_course.description : "",
        cover: selected_course ? selected_course.cover : "",
        price: selected_course ? selected_course.price : "",
        discount: selected_course ? selected_course.discount : "",
        intro: selected_course ? selected_course.intro : "",
        due_date: selected_course ? selected_course.due_date : "",
    });
    const navigate = useNavigate();

    // close the modal
    const handle_close = () => {
        set_course_modal(false);
        set_selected_course(null);
    };

    // handle the create and update
    const handle_submit = () => {

        // prepare the form data
        const form_data = new FormData();

        // if the action is update
        if (selected_course){
            form_data.append("id", selected_course.id);
            form_data.append("uniq", selected_course.uniq);
        }

        // prepare the query
        const endpoint = COURSE_API + `${selected_course ? "update.php" : "create.php"}`;
        for (const key in state) form_data.append(key, state[key]);
        form_data.append("username", is_loged.username);
        const headers = {"content-type": "multipart/form-data"};

        // send the query to the server
        axios.post(endpoint, form_data, {headers}).then(response => {
            const {data} = response;
            if (data.success){
                selected_course ? dispatch_course({type: "update", payload: data.course}) : dispatch_course({type: "push", payload: data.course});
                set_alert({type: "success", text: "the course saved successfully"});
                handle_close();
            }

            // if the error occured
            else if (data.error) set_alert({type: "error", text: data.error});
        });
    };

    // handle the delete
    const handle_delete = () => {

        // prepare the query
        const endpoint = COURSE_API + "delete.php";
        const data = {id: selected_course.id};

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success){
                dispatch_course({type: "delete", payload: selected_course});
                set_alert({type: "success", text: "the course deleted successfully"});
                handle_close();
            }

            // if the error occured
            else if (data.error) set_alert({type: "error", text: data.error});
        });
    };

    // handle the view
    const handle_view = () => navigate(`/view/course/${selected_course.id}`);

    // the state handler
    const state_handler = event => set_state({...state, [event.target.name]: event.target.type === "file" ? event.target.files[0] : event.target.value});

    // the footer buttons
    const buttons = [
        {
            title: "save",
            handler: handle_submit,
        },
    ];
    if (selected_course){
        buttons.unshift(
            {
                title: "delete",
                handler: handle_delete,
            },
            {
                title: "view",
                handler: handle_view,
            }
        );
    };

    // extract the data
    const {category, title, subtitle, introduction, learn, content, requirement, description, cover, price, discount, intro, due_date} = state;

    // return to the component data
    return (
        <Modal handle_close={handle_close} buttons={buttons} z_index={10}>
            <div className="grid grid-cols-1 gap-y-4">
                <select className={input_style} name="category" value={category} onChange={event => state_handler(event)}>
                    {clear_book_categories.map(item => <option key={item} value={item}>{item}</option>)}
                </select>

                <input
                    type="text"
                    name="title"
                    placeholder="set the title"
                    value={title}
                    onChange={event => state_handler(event)} 
                    required
                    className={`${input_style}`}
                />
                <input
                    type="text"
                    name="subtitle"
                    placeholder="set the subtitle"
                    value={subtitle}
                    onChange={event => state_handler(event)} 
                    required
                    className={`${input_style}`}
                />

                <TextEditor name="introduction" html={introduction} placeholder="set the introduction" handler={event => state_handler(event)} />
                <TextEditor name="learn" html={learn} placeholder="what dentist will learn" handler={event => state_handler(event)} />
                <TextEditor name="content" html={content} placeholder="set course contents" handler={event => state_handler(event)} />
                <TextEditor name="requirement" html={requirement} placeholder="what is requirements" handler={event => state_handler(event)} />
                <TextEditor name="description" html={description} placeholder="set the description" handler={event => state_handler(event)} />

                <div className="flex justify-center items-center gap-x-4">
                    <input
                        type="text"
                        name="price"
                        placeholder="set the price"
                        value={price}
                        onChange={event => state_handler(event)} 
                        required
                        className={`${input_style}`}
                    />
                    <input
                        type="text"
                        name="discount"
                        placeholder="set the discount"
                        value={discount}
                        onChange={event => state_handler(event)} 
                        required
                        className={`${input_style}`}
                    />
                </div>

                <input
                    type="text"
                    name="due_date"
                    placeholder="set the due date"
                    value={due_date}
                    onChange={event => state_handler(event)} 
                    required
                    className={`${input_style}`}
                />

                <div className="flex justify-center items-center gap-x-4">
                    <div>
                        <span className="text-gray-200">set the cover</span>
                        <input
                            type="file"
                            name="cover"
                            onChange={event => state_handler(event)} 
                            required
                            className={`${input_style}`}
                        />
                    </div>
                    <div>
                        <span className="text-gray-200">set the intro video</span>
                        <input
                            type="file"
                            name="intro"
                            onChange={event => state_handler(event)} 
                            required
                            className={`${input_style}`}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};
export default CourseModal;