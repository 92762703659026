// import the react components
import React, {useEffect, useState} from "react";

// import the custome components
import Title from "./../ui/title";
import SearchBar from "./../ui/search-bar";
import FilterHeader from "./../ui/filter-header";
import BookItem from "./../ui/book/book-item";
import NoData from "./../ui/no-data";

// import the api components
import axios from "axios";
import {BOOK_API} from "./../assets/data/api";

// import the worker categories
import {book_categories} from "./../assets/data/book";

// import the icons
import {ImBook} from "react-icons/im";

// import the context
import {useStateContext} from "./../context/";
import useNavigation from "./../context/navigation";

// the books functional based component
const Books = () => {
    const {saved_books, dispatch_book, book_filter, set_book_filter, set_is_loading} = useStateContext();
    const [search, set_search] = useState();
    useNavigation();

    // get the workers
    useEffect(() => {
        saved_books.length === 0 && set_is_loading(true);

        // prepare the query
        const endpoint = BOOK_API + "read.php";

        // send the query to the server
        axios.get(endpoint).then(response => {
            const {data} = response;
            if (data.success) dispatch_book({type: "add", payload: data.books});
            set_is_loading(false);
        });
    }, []);

    // return to the component data
    return (
        <div>

            {/* the title of the webpage */}
            <Title text="books" icon={<ImBook />} />

            {/* the header of the webpage */}
            <div className="flex justify-between items-center">
                <SearchBar placeholder="search book" value={search} set_search={value => set_search(value)} />
            </div>

            {/* the books category */}
            <FilterHeader data={book_categories} active={book_filter} handler={item => set_book_filter(item)} />

            {/* the list of the books */}
            {saved_books.length > 0 ? <div className="mt-16">
                {saved_books.map(item => (book_filter === "all" || book_filter === item.category) && <BookItem key={item.id} item={item} />)}
            </div>
            :
            <NoData message="No books available." />}
        </div>
    );
};
export default Books;