// import the react components
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import useNavigation from "./../context/navigation";

// import the custome components
import Alerter from "./../components/alerter";
import Header from "./../ui/registration/header";
import Input from "./../ui/registration/input";
import FormAction from "./../ui/registration/form-action";
import Footer from "./../components/footer";

// import the api components
import axios from "axios";
import {USER_API} from "./../assets/data/api";

// import the context
import {useStateContext} from "./../context/";

// initiate the forgot inputs
import {forgot_fields} from "./../assets/data/registration";
let fields = forgot_fields;
let field_state = {};
fields.forEach(field => field_state[field.id] = "");

// the forgot functional based component
const Forgot = () => {
    const {set_alert, set_is_disable} = useStateContext();
    const [forgot, set_forgot] = useState(field_state);
    const [stage, set_stage] = useState(1);
    const [resend, set_resend] = useState(true);
    useNavigation();

    // prepare the inputs
    let fields = forgot_fields;
    if (stage === 1) fields = fields.slice(0, 1);
    else if (stage === 2) fields = fields.slice(1, 2);
    else if (stage === 3) fields = fields.slice(2);

    // the navigation
    const navigate = useNavigate();

    // handle the input change
    const handle_change = event => set_forgot({...forgot, [event.target.id]: event.target.value});

    // handle the submit
    const handle_submit = event => {
        event.preventDefault();
        set_is_disable(true);

        // prepare the query
        const endpoint = USER_API + "auth.php";
        const data = {...forgot, stage};

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success){

                // update the stage
                if (stage === 1){
                    set_stage(preview => preview + 1);
                    set_alert({type: "success", text: "code sent successfully"});
                }
                else if (stage === 2){
                    set_stage(preview => preview + 1);
                    set_alert({type: "success", text: "the code is correct"});
                }
                else if (stage === 3){
                    navigate("/login");
                    set_alert({type: "success", text: "the password is changed"});
                };
            }

            // if the error occured
            else if (data.error) set_alert({type: "error", text: data.error});

            // remove the disable
            set_is_disable(false);
        });
    };

    // activate the resned
    const activate_resend = () => {
        setTimeout(() => {
            set_resend(false);
        }, 60000);
    };
    useEffect(() => {
        activate_resend();
    }, []);

    // handle the resend
    const handle_resend = event => {
        event.preventDefault();

        // prepare the query
        const endpoint = USER_API + "auth.php";
        const data = {...forgot, stage: "resend"};

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success){
                set_alert({type: "success", text: "the code resent"});
                set_resend(true);
                activate_resend();
            }

            // if the error occured
            else if (data.error) set_alert({type: "error", text: data.error});
        });
    };

    // return to the component data
    return (
        <div className="-m-5 min-h-full h-screen flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <Alerter />

            {/* the forgot form */}
            <div className="max-w-md w-full space-y-8 p-4">
                <Header
                    heading="Forgot your password"
                    paragraph={stage === 1 ? "remember's your password?" : stage === 2 && "code sent to"}
                    link_name={stage === 1 && "Login"}
                    paragraph_extra={stage === 2 && forgot.mail}
                    link_url="/login"
                />

                <form className="space-y-4">
                    <div>
                        {fields.map(field => 
                            <Input
                                key={field.id}
                                input_data={field}
                                handle_change={handle_change}
                                value={forgot[field.id]}
                            />
                        )}
                    </div>

                    {stage === 2 && <div className="flex justify-start items-center text-sm gap-x-2 ml-2">
                        <p className="text-gray-700 dark:text-gray-200">did't recieve code?</p>
                        <button type="button" disabled={resend} onClick={handle_resend} className="text-primary disabled:text-gray-400 dark:disabled:text-gray-500 font-bold">resend code</button>
                    </div>}

                    <FormAction text={stage !== 3 ? "Send" : "Change"} handle_submit={handle_submit} />
                </form>
            </div>

            {/* the footer */}
            <Footer copy={false} />
        </div>
    );
};
export default Forgot;