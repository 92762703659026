// import the react components
import React from "react";

// import the images
import facebook_image from "./../../assets/images/facebook.png";
import instagram_image from "./../../assets/images/instagram.png";
import twitter_image from "./../../assets/images/twitter.png";
import youtube_image from "./../../assets/images/youtube.png";
import whatsapp_image from "./../../assets/images/whatsapp.png";

// the social us functional based component
const SocialUs = () => {

    // the social data
    const social = [
        {
            icon: facebook_image,
            link: "https://www.facebook.com/mehe.sherzad",
        },
        {
            icon: instagram_image,
            link: "https://www.instagram.com/mohammad_sherzad_aziz",
        },
        {
            icon: twitter_image,
            link: "https://twitter.com/meheZebari",
        },
        {
            icon: youtube_image,
            link: "https://www.youtube.com/channel/UCmxgLQBzODENCiQWNj_bpdQ",
        },
        {
            icon: whatsapp_image,
            link: "https://wa.me/07504809079",
        },
    ];

    // return to the component data
    return (
        <div className="flex flex-wrap w-1/2 mx-auto justify-center items-center gap-4">
            {social.map(item => <a href={item.link} key={item.link} target="_blank">
                <img src={item.icon} className="w-16" />
            </a>)}
        </div>
    );
};
export default SocialUs;