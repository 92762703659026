// import the react components
import React from "react";
import {useNavigate} from "react-router-dom";

// import the api components
import {CASE_IMAGES_API} from "./../../assets/data/api";

// import the context
import {useStateContext} from "./../../context/";

// the case item functional based component
const CaseItem = ({item}) => {
    const {is_loged, set_case_modal, set_selected_case} = useStateContext();

    // the navigate
    const navigate = useNavigate();

    // the click handler
    const click_handler = item => {

        // if the user is owner
        if (item.username === is_loged.username){
            set_case_modal(true);
            set_selected_case(item);
        }

        // navigate the user to view the case
        else navigate(`/view/case/${item.id}`);
    };

    // return to the component data
    return (
        <div className="relative">
            <div className="hover:opacity-80 cursor-pointer" onClick={() => click_handler(item)}>
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none dark:bg-main-dark-bg">
                    <img
                        src={CASE_IMAGES_API + item.cases[0]}
                        alt="case"
                        className="h-64 w-full object-contain object-center dark:bg-main-dark-bg"
                    />
                </div>
                <div className="mt-3 flex justify-between">
                    <div>
                        <h3 className="text-xl text-gray-900 dark:text-gray-200">{item.title}</h3>
                        <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">Dr. {item.username}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default CaseItem;