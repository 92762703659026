// import the react components
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

// import the icons
import {RiMenuFill} from "react-icons/ri";
import {RiShoppingCartLine} from "react-icons/ri";
import {RiChat3Line} from "react-icons/ri";
import {RiNotification4Line} from "react-icons/ri";
import {RiFullscreenFill} from "react-icons/ri";
import {RiFullscreenExitLine} from "react-icons/ri";
import {MdOutlineKeyboardArrowDown} from "react-icons/md";

// import the custome components
import Alerter from "./alerter";
import {ProfileDropdown} from "./dropdown";
import {Tipper} from "./material";

// import the api components
import {PROFILE_API} from "./../assets/data/api";

// import the colors
import {primary} from "./../assets/data/colors";

// import the context
import {useStateContext} from "./../context/";

// the navigation buttons
const NavigationButton = ({title, custome_function, icon}) => (
    <Link to={custome_function ? "#" : `/${title}`}>
        <Tipper title={title}>
            <button type="button" onClick={custome_function && custome_function} className="text-gray-800 dark:text-gray-200 dark:hover:text-black relative text-xl rounded-full p-3 hover:bg-light-gray">
                {/* <span style={{background: dot_color}} className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2" /> */}
                {icon}
            </button>
        </Tipper>
    </Link>
);

// the navigation functional based component
const Navigation = () => {
    const {set_active_menu, profile, set_profile, set_screen_size, is_loged} = useStateContext();
    const [fullscreen, set_fullscreen] = useState(false);

    // check the size of the screen
    useEffect(() => {
        const handle_resize = () => set_screen_size(window.innerWidth);
        window.addEventListener("resize", handle_resize);
        handle_resize();

        return () => window.removeEventListener("resize", handle_resize);
    }, []);

    // handle the fullscreen size toggler
    const handle_toggle = () => {
        if (!document.fullscreenElement){
            document.documentElement.requestFullscreen();
            set_fullscreen(true);
        }
        else {
            document.exitFullscreen();
            set_fullscreen(false);
        };
    };

    // return to the component data
    return (
        <div className="fixed md:static bg-main-bg dark:bg-secondary-dark-bg w-full">
            <Alerter />

            <div className="flex justify-between p-2 md:mx-4 relative">

                {/* the menu toggler */}
                <NavigationButton
                    custome_function={() => set_active_menu(preview => !preview)}
                    icon={<RiMenuFill />}
                />

                {/* the left side of navigation */}
                <div className="flex relative lg:gap-x-1">
                    <div className="flex items-center gap-2 cursor-pointer group p-1 hover:bg-light-gray rounded-lg" onClick={() => set_profile(!profile)}>
                        <img src={PROFILE_API + is_loged.logo} alt="user" className="rounded-full w-8 h-8 border-gray-800 border-1 dark:border-gray-200" />
                        <span className="text-gray-800 dark:text-gray-200 dark:group-hover:text-gray-800 font-bold text-14">Dr. {is_loged.username}</span>
                        <MdOutlineKeyboardArrowDown className={`text-gray-500 dark:group-hover:text-gray-800 dark:text-gray-200 text-16 transition-transform delay-150 ${profile ? "rotate-180" : "rotate-0"}`} />
                    </div>

                    <NavigationButton
                        title="cart"
                        icon={<RiShoppingCartLine />}
                    />

                    <NavigationButton
                        title="chat"
                        icon={<RiChat3Line />}
                        dot_color={primary}
                    />

                    <NavigationButton
                        title="notification"
                        icon={<RiNotification4Line />}
                        dot_color={primary}
                    />

                    <NavigationButton
                        title={fullscreen ? "exit full screen" : "full screen"}
                        custome_function={handle_toggle}
                        icon={fullscreen ? <RiFullscreenExitLine/> : <RiFullscreenFill />}
                    />

                    <ProfileDropdown />
                </div>
            </div>
        </div>
    );
};
export default Navigation;