// import the react components
import React from "react";
import {useNavigate} from "react-router-dom";

// import the context
import {useStateContext} from "./../../context/";

// the laboratory item functional based components
const LaboratoryItem = ({laboratory}) => {
    const {is_loged, set_laboratory_modal, set_selected_laboratory} = useStateContext();
    const navigate = useNavigate();

    // handle the click
    const handle_click = () => {

        // show the update and delete buttons
        if (is_loged.username === laboratory.username){
            set_laboratory_modal(true);
            set_selected_laboratory(laboratory);
        }

        // view the data
        else {
            navigate(`/view/laboratory/${laboratory.id}`);
        };
    };

    // return to the component data
    return (
        <div className="dark:bg-secondary-dark-bg flex flex-col gap-2 shadow-md p-4 cursor-pointer hover:opacity-80 rounded-sm" onClick={handle_click}>
            <h1 className="text-3xl font-semibold dark:text-gray-200">{laboratory.title}</h1>
            <p dangerouslySetInnerHTML={{__html: laboratory.introduction}} id="blog" className="dark:text-gray-200" />
        </div>
    );
};
export default LaboratoryItem;