// import the react components
import React from "react";

// import the cutome components
import Button from "./../button";

// import the styles
import {tr_style, td_style} from "./../../assets/data/style";

// the transaction item functional based component
const TransactionItem = ({item}) => {

    // return to the component data
    return (
        <tr className={tr_style}>
            <td className={td_style}>{item.id}</td>
            <td className={td_style}>{item.from_username}</td>
            <td className={td_style}>{item.to_username}</td>
            <td className={td_style}>
                <Button title="view" action="link" link={`/view/transaction/${item.id}`} />
            </td>
        </tr>
    );
};
export default TransactionItem;