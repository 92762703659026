// import the react components
import React from "react";

// the table body functional based component
const TableBody = ({page, data, Component}) => {

    // the table pagination
    const pagination = 10;
    const from_number = page === 1 ? page : ((page - 1) * pagination) + 1;
    const to_number = page * pagination;

    // return to the component data
    return (
        <tbody>
            {data.length > 0 && data.map((item, index) => item !== null && (
                (index >= from_number - 1 && index < to_number) && <Component key={item.id} item={item} />
            ))}
        </tbody>
    );
};
export default TableBody;