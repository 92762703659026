// import the react components
import React, {useRef} from "react";

// import the custome components
import Table from "./../../components/table";
import Print from "./../../components/print";

// import the styles
import {tr_style, td_style} from "./../../assets/data/style";

// the patient data teeth functional based component
const PatientDataTeeth = ({heading, data}) => {
    const reference = useRef(null);

    // the headers of the table
    const headers = ["tooth", "condition", "surface", "periodontium", "root", "periapical"];

    // return to the component data
    return (
        <div className="mb-10">
            <div ref={reference}>
                <h1 className="text-4xl mb-6 pb-4 border-b-2 border-gray-900 dark:text-gray-200 dark:border-b-gray-700">{heading}</h1>
                <Table header={headers} body={[]} is_search={false}>
                    <tbody>
                        {data.map((item, index) => <tr className={tr_style} key={index}>
                            <td className={td_style}>{item.tooth}</td>
                            <td className={td_style}>{item.condition}</td>
                            <td className={td_style}>{item.surface}</td>
                            <td className={td_style}>{item.periodontium}</td>
                            <td className={td_style}>{item.root}</td>
                            <td className={td_style}>{item.periapical}</td>
                        </tr>)}
                    </tbody>
                </Table>
            </div>
            <Print reference={reference} />
        </div>
    );
};
export default PatientDataTeeth;