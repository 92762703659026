// import the react components
import React from "react";
import {Link} from "react-router-dom";
import useNavigation from "./../context/navigation";

// import the custome components
import Alerter from "./../components/alerter";
import OuterHeader from "./../ui/outer-header";

// import the colors
import {gradient_primary} from "./../assets/data/colors";

// the home functional based component
const Home = () => {
    useNavigation();

    // return to the component data
    return (
        <div>
            <Alerter />

            {/* the header of the homepage */}
            <OuterHeader />

            {/* the body of the homepage */}
            <div>

                {/* the top section */}
                <section className="bg-white dark:bg-main-dark-bg mt-5 h-[calc(100vh-100px)] flex justify-center items-center">
                    <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                        <div className="mx-auto max-w-screen-md text-center">
                            <h1 className="mb-4 text-5xl tracking-tight font-extrabold leading-tight text-gray-900 dark:text-white capitalize">manage your clinic wisely</h1>
                            <p className="mb-6 font-light text-gray-600 dark:text-gray-400 md:text-lg"><strong className="dark:text-gray-200">All-in-one:</strong> Manage your schedule and patient records. Display the clinic statistics. Share and get knowledge from other dentists. Order products online.</p>
                            <Link to="/logup" className="text-white hover:opacity-90 focus:ring-0 rounded-md text-xl px-7 py-3 mr-2 mb-2 focus:outline-none dark:focus:ring-0" style={{background: gradient_primary}}>Get started</Link>
                            <Link to="/about" className="focus:ring-0 rounded-md text-lg ml-2 mb-2 focus:outline-none dark:focus:ring-0 dark:text-gray-200 hover:opacity-80">Learn more</Link>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};
export default Home;