// import the react components
import React from "react";

// import the custome components
import Button from "./../button";

// import the styles
import {tr_style, td_style} from "./../../assets/data/style";

// import the context
import {useStateContext} from "./../../context/";

// the order item functional based component
const OrderItem = ({item}) => {
    const {set_selected_order, set_order_modal} = useStateContext();

    // the edit handler
    const edit_handler = () => {
        set_order_modal(true);
        set_selected_order(item);
    };

    // the progression style
    const progression_style = () => {
        if (item === "pending") return "bg-yellow-500";
        else if (item === "rejected") return  "bg-red-500";
        else return "bg-green-500";
    };

    // return to the component data
    return (
        <tr className={tr_style}>
            <td className={td_style}>{item.id}</td>
            <td className={td_style}>{item.dentist}</td>
            <td className={td_style}>{item.phone}</td>
            <td className={td_style}>
                <button className={`py-2 px-4 text-gray-900 rounded-md text-lg ${progression_style()}`}>{item.status}</button>
            </td>
            <td className={td_style}>
                <Button title="edit" action="button" handler={edit_handler} />
            </td>
        </tr>
    );
};
export default OrderItem;