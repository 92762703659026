// import the react components
import React, {useState} from "react";

// import the custome components
import Modal from "./../modal";

// import the styles
import {input_style} from "./../../assets/data/style";

// import the api components
import axios from "axios";
import {TIP_API} from "./../../assets/data/api";

// import the context
import {useStateContext} from "./../../context/";

// the tip modal functional based component
const TipModal = () => {
    const {set_tip_modal, selected_tip, set_selected_tip, is_loged, dispatch_tip, set_alert} = useStateContext();
    const [state, set_state] = useState({
        tip: selected_tip ? selected_tip.tip : "",
    });

    // close the modal
    const handle_close = () => {
        set_tip_modal(false);
        set_selected_tip(null);
    };

    // handle the create and update
    const handle_submit = () => {

        // prepare the query
        const endpoint = TIP_API + `${selected_tip ? "update.php" : "create.php"}`;
        const data = {
            ...state,
            id: selected_tip ? selected_tip.id : "",
            uniq: selected_tip ? selected_tip.uniq : "",
            username: is_loged.username,
        };

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success){
                selected_tip ? dispatch_tip({type: "update", payload: data.tip}) : dispatch_tip({type: "push", payload: data.tip});
                set_alert({type: "success", text: "the tip saved successfuly"});
                handle_close();
            }

            // if the error occured
            else if (data.error) set_alert({type: "error", text: data.error});
        })
    };

    // handle the delete
    const handle_delete = () => {

        // prepare the query
        const endpoint = TIP_API + "delete.php";
        const data = {id: selected_tip.id};

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success){
                dispatch_tip({type: "delete", payload: selected_tip});
                set_alert({type: "success", text: "the tip deleted successfuly"});
                handle_close();
            }

            // if the error occured
            else if (data.error) set_alert({type: "error", text: data.error});
        });
    };

    // the footer buttons
    const buttons = [
        {
            title: "save",
            handler: handle_submit,
        },
    ];
    selected_tip && buttons.unshift({
        title: "delete",
        handler: handle_delete,
    });

    // extract the data
    const {tip} = state;

    // return to the component data
    return (
        <Modal handle_close={handle_close} buttons={buttons} z_index={10}>
            <div className="grid grid-cols-1 gap-y-4">
                <input
                    type="text"
                    name="tip"
                    placeholder="set the tip"
                    value={tip}
                    onChange={event => set_state({...state, tip: event.target.value})} 
                    className={`${input_style}`}
                    required
                />
            </div>
        </Modal>
    );
};
export default TipModal;