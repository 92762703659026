// import the react components
import React, {useState, useRef, Fragment} from "react";

// import the custome components
import Card from "./../ui/card";
import Button from "./../ui/button";
import SearchBar from "./../ui/search-bar";
import Print from "./print";

// the table functional based component
const Table = ({page, set_page, paragraph, button_title, button_link, button_handler, header, body, footer, children, button_disable = false, is_search = true, has_print = false}) => {
    const [search, set_search] = useState("");
    const reference = useRef(null);

    // prepare the pagination
    const pagination = 10;
    const from_number = page === 1 ? page : (page - 1) * pagination;
    const to_number = page * pagination;
    const showing = ` ${from_number}-${to_number} `;

    // the pagination handler
    const page_handler = (action) => {

        // add the page
        if (action === "add" && page !== Math.ceil(body.length / 10)) set_page(page + 1);

        // subtract the page
        else if (action === "subtract" && page !== 1) set_page(page - 1);
    };

    // return to the component data
    return (
        <Fragment>
            <Card>
                <div className="flex justify-between items-center">

                    {/* the description and search bar for the table */}
                    <div>
                        <p className="mb-2 dark:text-gray-200">{paragraph}</p>
                        {is_search && <SearchBar placeholder="search items" value={search} handler={value => set_search(value)} />}
                    </div>

                    {/* the button for specific action */}
                    {button_title && (button_handler ? <Button title={button_title} action="button" handler={button_handler} disable={button_disable} /> : <Button title={button_title} action="link" link={`/${button_link}`} custom_class="py-3" />)}
                </div>

                {/* the data of the table */}
                <table className="w-full text-sm text-left text-black dark:text-gray-400 mt-4" ref={reference}>

                    {/* the header of the table */}
                    <thead className="text-sm text-left text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-300">
                        <tr>
                            {header.map((item, index) => <th key={item + index} scope="col" className="px-6 py-3">{item}</th>)}
                        </tr>
                    </thead>

                    {/* the body of the table */}
                    {children}

                    {/* the footer of the table */}
                    {body.length > 0 && <tfoot>
                        <tr className="font-semibold text-gray-900 dark:text-gray-200 uppercase">
                            {footer && footer.map(item => <td className="px-6 py-3" key={item}>{item}</td>)}
                        </tr>
                    </tfoot>}
                </table>

                {/* the table pagination */}
                {(is_search && body.length > 0) && <nav className="flex items-center justify-between pt-4" aria-label="table navigation">
                    <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                        showing
                        <span className="font-semibold text-gray-900 dark:text-white">{showing}</span>
                        of
                        <span className="font-semibold text-gray-900 dark:text-white"> {body.length}</span>
                    </span>
                    <ul className="inline-flex items-center -space-x-px">
                        <li>
                            <button className="block px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" onClick={() => page_handler("subtract")}>
                                <span className="sr-only">Previous</span>
                                <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd"></path></svg>
                            </button>
                        </li>
                        <li>
                            <button className="block px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" onClick={() => page_handler("add")}>
                                <span className="sr-only">Next</span>
                                <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                            </button>
                        </li>
                    </ul>
                </nav>}
            </Card>
            {(has_print && body.length > 0) && <Print reference={reference} />}
        </Fragment>
    );
};
export default Table;