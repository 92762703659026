// import the react components
import React from "react";

// import the custom components
import Title from "./../ui/title";

// import the icons
import {IoMdSettings} from "react-icons/io";

// import the context
import {useStateContext} from "./../context/";
import useNavigation from "./../context/navigation";

// the settings functional based component
const Settings = () => {
    const {is_loged} = useStateContext();
    useNavigation();

    // return to the component data
    return (
        <div>

            {/* the title of the webpage */}
            <Title text="settings" icon={<IoMdSettings />} />

            {/* display the settings */}
            {is_loged.site ? <div></div>
            :
            <div className="flex justify-center items-center mt-10">
                <h1 className="text-2xl text-primary">You must purchase a dental site to activate this section.</h1>
            </div>}
        </div>
    );
};
export default Settings;