// import the react components
import React from "react";
import {Link} from "react-router-dom";

// import the api components
import {WORKER_IMAGE_API, RESUME_API} from "./../../assets/data/api";

// the worker item functional based component
const WorkerItem = ({item}) => {

    // return to the component data
    return (
        <div className="flex flex-col gap-y-2">
            <div>
                <img src={WORKER_IMAGE_API + item.image} alt="product" className="w-full h-52 object-contain" />
            </div>

            <div>
                <h2 className="text-lg dark:text-gray-200">{item.name}</h2>
                <h2 className="mt-0.5 text-md text-gray-600 dark:text-gray-400">{item.category}</h2>
            </div>

            <Link to={RESUME_API + item.resume} target="_blank" className="block w-full py-2 bg-primary text-center text-gray-50 rounded text-lg hover:opacity-80" download>resume</Link>
        </div>
    );
};
export default WorkerItem;