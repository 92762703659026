// import the react components
import React, {useState} from "react";

// import the custome components
import Table from "./../../components/table";
import Button from "./../button";

// import the styles
import {tr_style, td_style, input_style} from "./../../assets/data/style";

// import the inputs
import {teeth_inputs, conditions} from "./../../assets/data/inputs";

// the teeth functional based component
const Teeth = ({state, state_handler, delete_handler}) => {
    const init_state = {
        tooth: "",
        condition: conditions[0],
        surface: "",
        periodontium: "",
        root: "",
        periapical: "",
    };
    const [teeth, set_teeth] = useState(init_state);

    // the headers of the table
    const headers = ["tooth", "condition", "surface", "periodontium", "root", "periapical", ""];

    // the add handler
    const add_handler = () => {
        state_handler("teeth_evaluation", {id: teeth.tooth + "", ...teeth});
        set_teeth(init_state);
    };

    // return to the component data
    return (
        <div className="mt-5">

            {/* the treatment options */}
            <Table header={headers} body={[]} is_search={false}>
                <tbody>
                    {state.map((item, index) => <tr className={tr_style} key={index}>
                        <td className={td_style}>{item.tooth}</td>
                        <td className={td_style}>{item.condition}</td>
                        <td className={td_style}>{item.surface}</td>
                        <td className={td_style}>{item.periodontium}</td>
                        <td className={td_style}>{item.root}</td>
                        <td className={td_style}>{item.periapical}</td>
                        <td className={td_style}>
                            <Button title="remove" action="button" handler={() => delete_handler("teeth_evaluation", index)} />
                        </td>
                    </tr>)}
                </tbody>
            </Table>

            {/* add the treatment option */}
            <div className="grid grid-cols-2 p-2 gap-4 mt-10">
                {teeth_inputs.map(item => item.options ?
                    <select key={item.name} className={`${input_style} max-w-full`} name={item.name} value={teeth[item.name]} onChange={event => set_teeth({...teeth, [event.target.name]: event.target.value})}>
                        {item.options.map(option => <option key={option} value={option}>{option}</option>)}
                    </select>
                    :
                    <input
                        key={item.name}
                        className={`${input_style} max-w-full px-2`}
                        name={item.name}
                        value={teeth[item.name]}
                        placeholder={item.label}
                        onChange={event => set_teeth({...teeth, [event.target.name]: event.target.value})}
                    />
                )}
            </div>

            {/* the add button */}
            <div className="my-4 flex justify-center items-center">
                <Button title="add" action="button" handler={add_handler} custom_class="w-40" />
            </div>
        </div>
    );
};
export default Teeth;