// import the react components
import React from "react";

// the dashboard card functional based component
const DashboardCard = ({image, h4, h2, h6, icon, bg_color}) => {

    // return to the component data
    return (
        <div className="flex-1 max-w-md min-w-fit p-6 relative rounded-lg text-gray-50" style={{background: bg_color}}>
            <img src={image} className="absolute top-0 right-0 h-full" alt="" />
            <h4 className="mb-3 flex items-center gap-2 text-2xl">
                <span>{h4}</span>
                <span>{icon}</span>
            </h4>
            <h2 className="mb-5 text-3xl font-extrabold">{h2}</h2>
            <h6 className="text-sm text-gray-50 font-bold">{h6}</h6>
        </div>
    );
};
export default DashboardCard;