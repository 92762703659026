// import the react components
import {useEffect} from "react";

// the outside alerter hook
function useOutsideAlerter (reference, handler){
    useEffect(() => {
        function handle_click_outside (event){
            if (reference.current && !reference.current.contains(event.target)){
                handler();
            }
        }
        document.addEventListener("mousedown", handle_click_outside);

        // delete the event listener
        return () =>  document.removeEventListener("mousedown", handle_click_outside);
    }, [reference]);
}
export default useOutsideAlerter;