// import the react components
import React, {useEffect, useState} from "react";

// import the custom components
import Title from "./../ui/title";
import SearchBar from "./../ui/search-bar";
import Button from "./../ui/button";
import FilterHeader from "./../ui/filter-header";
import NoteItem from "./../ui/note/note-item";
import NoData from "./../ui/no-data";
import NoteModal from "./../ui/note/note-modal";

// import the icons
import {FaStickyNote} from "react-icons/fa";

// import the api components
import axios from "axios";
import {NOTE_API} from "./../assets/data/api";

// import the context
import {useStateContext} from "./../context/";
import useNavigation from "./../context/navigation";

// the notes functional based component
const Notes = () => {
    const {is_loged, saved_notes, dispatch_note, note_modal, set_note_modal, note_filter, set_note_filter, set_is_loading} = useStateContext();
    const [search, set_search] = useState("");
    useNavigation();

    // read the notes from the server
    useEffect(() => {
        saved_notes.length === 0 && set_is_loading(true);

        // prepare the query
        const endpoint = NOTE_API + "read.php";
        const data = {username: is_loged.username};

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success) dispatch_note({type: "add", payload: data.notes});
            set_is_loading(false);
        });
    }, []);

    // search handler
    const search_handler = value => set_search(value);

    // the note header options
    const options = ["all", "note", "instruction", "prescription"];

    // return to the component data
    return (
        <div>

            {/* display the modals */}
            {note_modal && <NoteModal />}

            {/* the title of the page */}
            <Title text="notes" icon={<FaStickyNote />} />

            {/* the header of the page */}
            <div className="flex items-center justify-between">
                <SearchBar placeholder="search notes" value={search} handler={search_handler} />
                <Button title="add" action="button" handler={() => set_note_modal(true)} />
            </div>

            {/* the options */}
            <FilterHeader data={options} active={note_filter} handler={item => set_note_filter(item)} />

            {/* the body of the page */}
            {saved_notes.length > 0 ? <div className="flex flex-col justify-center items-center flex-wrap gap-5 mt-5">
                {saved_notes.map(item => (note_filter === "all" || note_filter === item.type) && <NoteItem key={item.id} item={item} />)}
            </div>
            :
            <NoData message="Your note library is empty, add notes for you and your patients." />}
        </div>
    );
};
export default Notes;