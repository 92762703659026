// import the react components
import React, {useState} from "react";

// import the custome components
import Alerter from "./../components/alerter";
import OuterHeader from "./../ui/outer-header";
import EmailUs from "./../ui/contact/email-us";
import SocialUs from "./../ui/contact/social-us";
import VisitUs from "./../ui/contact/visit-us";

// import the context
import useAuth from "./../context/auth";

// import the icons
import {MdAlternateEmail} from "react-icons/md";
import {MdSocialDistance} from "react-icons/md";
import {BsCupHotFill} from "react-icons/bs";

// the contact functional based component
const Contact = () => {
    const {auth} = useAuth(); 
    const [name, set_name] = useState("");

    // the cards of contact
    const cards = [
        {
            name: "email",
            icon: <MdAlternateEmail />,
            title: "send mail",
            paragraph: "send us a message via email, we will responde shortly.",
        },
        {
            name: "social",
            icon: <MdSocialDistance />,
            title: "contact social",
            paragraph: "message us via social media accounts or chats.",
        },
        {
            name: "visit",
            icon: <BsCupHotFill />,
            title: "make visite",
            paragraph: "come and visit us, let's drink a cup of coffee.",
        },
    ];

    // return to the component data
    return (
        <div>
            <Alerter />

            {/* the outer header */}
            {!auth && <OuterHeader />}

            {/* the header */}
            <div className="mt-10">
                <h2 className="mb-3 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">contact us</h2>
                <p className="font-light text-center text-gray-700 dark:text-gray-400 sm:text-xl">day or night, rain or shine, anytime we are here for you.</p>
            </div>

            {/* the contact cards */}
            <div className="flex flex-wrap justify-center items-center gap-6 my-10">
                {cards.map(item => <a href="#contact" key={item.name} className={`dark:bg-secondary-dark-bg w-72 h-52 rounded-md shadow-md flex flex-col justify-center p-5 cursor-pointer hover:scale-105 transition-transform duration-150 ${name === item.name && "bg-primary dark:bg-half-transparent text-gray-300"}`} onClick={() => set_name(item.name)}>
                    <span className="text-2xl mb-2 ml-2 dark:text-gray-200">{item.icon}</span>
                    <h2 className="text-2xl mb-2 font-bold dark:text-gray-200">{item.title}</h2>
                    <p className="leading-relaxed dark:text-gray-200">{item.paragraph}</p>
                </a>)}
            </div>

            {/* the body of the webpage */}
            <div id="contact">
                {name === "email" && <EmailUs />}
                {name === "social" && <SocialUs />}
                {name === "visit" && <VisitUs />}
            </div>
        </div>
    );
};
export default Contact;