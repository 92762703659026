// import the react components
import React, {useEffect, useState} from "react";

// import the api components
import axios from "axios";
import {BLOG_API} from "./../../assets/data/api";

// import the custome components
import Card from "./../card";
import NoViewData from "./../no-view-data";

// import the context
import {useStateContext} from "./../../context/";

// the blog view functional based component
const BlogView = ({id}) => {
    const {set_is_loading} = useStateContext();
    const [blog, set_blog] = useState({});

    // get the data
    useEffect(() => {
        set_is_loading(true);

        // prepare the query
        const endpoint = BLOG_API + "read-single.php";
        const data = {id};

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success) set_blog(data.blog);
            set_is_loading(false);
        });
    }, []);

    // the styles
    const h2_style = "text-2xl font-bold ml-2 capitalize mt-5";

    // return to the component data
    return (
        <div>
            {blog.id ? <div>

                {/* the top of the blog */}
                <div className="dark:text-gray-200">
                    <h1 className="text-5xl font-bold mt-10 text-center">{blog.title}</h1>
                    <div className="flex flex-col gap-3 mt-5 ml-4">
                        <h5 className="text-gray-800 text-md dark:text-gray-300">published date: {blog.created_date.replaceAll("-", "/")}</h5>
                        <h5 className="text-gray-800 text-md dark:text-gray-300">publisher: Dr. {blog.username}</h5>
                    </div>
                </div>

                {/* the body of the blog */}
                <div className="flex flex-col gap-y-2 mt-4 blog-wrapper dark:text-gray-200">
                    <h2 className={h2_style}>introduction</h2>
                    <Card>
                        <p dangerouslySetInnerHTML={{__html: blog.introduction}} id="blog" />
                    </Card>
                    <h2 className={h2_style}>description</h2>
                    <Card>
                        <p dangerouslySetInnerHTML={{__html: blog.description}} id="blog" />
                    </Card>
                    <h2 className={h2_style}>summary</h2>
                    <Card>
                        <p dangerouslySetInnerHTML={{__html: blog.summary}} id="blog" />
                    </Card>
                    <h2 className={h2_style}>references</h2>
                    <Card>
                        <p dangerouslySetInnerHTML={{__html: blog.reference}} id="blog" />
                    </Card>
                </div>
            </div>
            :
            <NoViewData />}
        </div>
    );
};
export default BlogView;