// import the react components
import React from "react"
import dayjs from "dayjs";

// import the custome components
import Button from "./../button";

// import the styles
import {tr_style, td_style} from "./../../assets/data/style";

// the patient item functional based component
const PatientItem = ({item}) => {

    // prepare the session
    const session = () => {
        const day = item.day;
        const from_time = item.from_time
        if (!day || !from_time) return "no next session";

        // prepare the day
        const clear_timezone = day.replace("Z", "");

        // no session available
        if (dayjs().diff(dayjs(clear_timezone), "day") > 0) return "no next session";

        // there is next session
        else return dayjs(day).format("D/M/YY, dddd") + " " + dayjs(from_time).format("h:m a");
    };

    // return to the component data
    return (
        <tr className={tr_style}>
            <td className={td_style}>{item.id}</td>
            <td className={td_style}>{item.name}</td>
            <td className={td_style}>{item.code}</td>
            <td className={td_style}>
                <span className="bg-gray-200 text-gray-900 p-2 rounded-md w-full">{session()}</span>
            </td>
            <td className={td_style}>
                <Button title="edit" action="link" link={`/book/${item.id}`} />
            </td>
            <td className={td_style}>
                <Button title="view" action="link" link={`/view/patient/${item.id}`} />
            </td>
        </tr>
    );
};
export default PatientItem;