// the general date of the website
export const company_name = "Peyvin Group";
export const short_name = "peyvin";
export const contact_mail = "contact@peyvin.com";
export const update_date = "june 14, 2023";

// the payment of the application
export const dashboard_cost = 99;
export const dental_cost = 69;
export const all_cost = dashboard_cost + dental_cost;

// the token
export const token = "$/pary/$";