// import the react components
import React, {useEffect, useState} from "react";

// import the custome components
import OrderModal from "./../ui/order/order-modal";
import Title from "./../ui/title";
import Table from "./../components/table";
import TableBody from "./../components/table-body";
import OrderItem from "./../ui/order/order-item";

// import the icons
import {BsCartFill} from "react-icons/bs";

// import the api components
import axios from "axios";
import {ORDER_API} from "./../assets/data/api";

// import the context
import {useStateContext} from "./../context/";
import useNavigation from "./../context/navigation";

// the orders functional based component
const Orders = () => {
    const {is_loged, saved_orders, dispatch_order, order_modal, set_is_loading} = useStateContext();
    const [page, set_page] = useState(1);
    useNavigation();

    // get the orders
    useEffect(() => {
        saved_orders.length === 0 && set_is_loading(true)

        // prepare the endpoint
        const endpoint = ORDER_API + "read.php";
        const data = {username: is_loged.username};

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success) dispatch_order({type: "add", payload: data.orders});
            set_is_loading(false);
        });
    }, []);

     // the header of the table
     const header = ["id", "dentist", "phone", "status", "",];

     // return to the component data
     return (
         <div>

            {/* the modals */}
            {order_modal && <OrderModal />}

            {/* the title of the webpage */}
             <Title text="orders" icon={<BsCartFill />} />
 
            {/* the data */}
             <Table paragraph="This table shows the list of dentists orders to your shared products." header={header} body={saved_orders} page={page} set_page={set_page} has_print={true}>
                <TableBody data={saved_orders} page={page} Component={OrderItem} />
            </Table>
         </div>
     );
};
export default Orders;