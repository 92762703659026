// import the react components
import React from "react";

// the no data functional based component
const NoData = ({message}) => {

    // return to the component data
    return (
        <div className="flex justify-center items-center my-10">
            <p className="text-xl dark:text-gray-200">{message}</p>
        </div>
    );
};
export default NoData;