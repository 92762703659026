// import the react components
import React from "react";

// import the cutome components
import Button from "./../button";

// import the api components
import axios from "axios"
import {PRODUCT_IMAGE_API, CART_API} from "./../../assets/data/api";

// import the context
import {useStateContext} from "./../../context/";

// import the styles
import {tr_style, td_style} from "./../../assets/data/style";

// the cart item functional based components
const CartItem = ({item}) => {
    const {dispatch_cart, set_alert, set_cart_modal, set_selected_cart} = useStateContext();

    // prepare the price
    let type_price = 0;
    if (item.type === "pack") type_price = item.pack_discount ? item.pack_discount : item.pack_price;
    else type_price = item.unit_discount ? item.unit_discount : item.unit_price;
    const price = Number(item.count) * Number(type_price);

    // the remove handler
    const delete_handler = () => {

        // prepare the query
        const endpoint = CART_API + "delete.php";
        const data = {id: item.id};

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success){
                dispatch_cart({type: "delete", payload: item});
                set_alert({type: "success", text: "the item removed successfully"});
            }

            // if error occured
            else if (data.error) set_alert({type: "error", text: data.error})
        });
    };

    // the edit handler
    const edit_handler = () => {
        set_selected_cart(item);
        set_cart_modal(true);
    };

    // return to the component data
    return (
        <tr className={tr_style}>
            <td className={td_style}>{item.id}</td>
            <td className={`${td_style} flex justify-start`}>
                <img className="w-16 h-16 object-contain rounded-full" src={PRODUCT_IMAGE_API + item.image} />
            </td>
            <td className={td_style}>{item.type}</td>
            <td className={td_style}>{item.color}</td>
            <td className={td_style}>{item.count}</td>
            <td className={td_style}>{price.toLocaleString()}$</td>
            <td className={td_style}>
                <Button title="edit" action="button" handler={edit_handler} />
            </td>
            <td className={td_style}>
                <Button title="remove" action="button" handler={delete_handler} />
            </td>
            <td className={td_style}>
                <Button title="view" action="link" link={`/view/product/${item.product}`} />
            </td>
        </tr>
    );
};
export default CartItem;