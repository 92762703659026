// import the react components
import React, {useState} from "react";

// import the custom components
import Title from "./../ui/title";
import ProfileSection from "./../ui/profile/profile-section";

// import the icons
import {CgProfile} from "react-icons/cg";

// import the api components
import axios from "axios";
import {USER_API} from "./../assets/data/api";

// import the inputs
import {profile_sections} from "./../assets/data/inputs";

// import the context
import {useStateContext} from "./../context/";
import useNavigation from "./../context/navigation";

// the profile functional based component
const Profile = () => {
    const password_init = {password: "", repassword: "", postpassword: ""};
    const {is_loged, set_is_loged, set_alert, set_is_disable} = useStateContext();
    const [state, set_state] = useState({...is_loged, ...password_init});
    useNavigation();

    // the submit handler
    const handle_submit = (section) => {
        set_is_disable(true);

        // if the action change profile
        if (section === "change profile"){
            handle_profile();
            return;
        };

        // prepare the query
        const endpoint = USER_API + "update.php";
        const data = {
            ...state,
            id: is_loged.id,
            username: is_loged.username,
            mail: is_loged.mail,
            section,
        };

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success){
                set_alert({type: "success", text: "info changed successfully"});
                set_is_loged(preview => ({...preview, ...data.user}));

                // reset the password inputs
                if (section === "change password") set_state({...state, ...password_init});
            }

            // if error occured
            else if (data.error) set_alert({type: "error", text: data.error});

            // remove the disable
            set_is_disable(false);
        });
    };

    // the profile change handler
    const handle_profile = () => {

        // prepare the query
        const form_data = new FormData();
        const endpoint = USER_API + "update.php";
        const headers = {"content-type": "multipart/form-data"};
        form_data.append("id", is_loged.id);
        form_data.append("file", state.logo);
        form_data.append("section", "change profile");

        // send the query to the server
        axios.post(endpoint, form_data, {headers}).then(response => {
            const {data} = response;
            if (data.success){
                set_alert({type: "success", text: "profile changed successfully"});
                set_is_loged(preview => ({...preview, ...data.user}));
            }

            // if error occured
            else if (data.error) set_alert({type: "error", text: data.error});

            // remove the disable
            set_is_disable(false);
        });
    };

    // handle the input change
    const handle_change = (target, section) => set_state({...state, [target.name]: section == "change profile" ? target.files[0] : target.value});

    // return to the component data
    return (
        <div>

            {/* the title of the webpage */}
            <Title text="profile" icon={<CgProfile />} />

            {/* the inputs */}
            <div className="flex flex-col mt-5">
                {profile_sections.map(section => <ProfileSection
                    key={section.title}
                    section={section}
                    handle_update={handle_submit}
                    handle_state={handle_change}
                    state={state}
                />)}
            </div>
        </div>
    );
};
export default Profile;