// import the react components
import React from "react";

// import the custome components
import Card from "./../card";

// the statistic wrapper functional based component
const StatisticWrapper = ({children, is_grown_first, has_second_card, first_card_title, second_card_title}) => {

    // return to the component data
    return (
        <div className="mt-5">
            <div className="flex lg:flex-nowrap flex-wrap gap-4 overflow-hidden">
                <Card custome_style="flex-grow" title={first_card_title}>
                    {Array.isArray(children) ? children[0] : children}
                </Card>

                {has_second_card && 
                <Card style={`${is_grown_first ? "flex-shrink" : "flex-grow"}`} title={second_card_title}>
                    {children[1]}
                </Card>}
            </div>
        </div>
    );
};
export default StatisticWrapper;