// import the react components
import React, {Fragment} from "react";

// import the cutome components
import Button from "./../button";

// import the styles
import {input_style} from "./../../assets/data/style";

// import the context
import {useStateContext} from "./../../context/";

// the profile section functional based component
const ProfileSection = ({section, handle_update, handle_state, state}) => {
    const {is_disable} = useStateContext();

    // return to the component data
    return (
        <div className="flex flex-col gap-y-4">
            <h1 className="capitalize text-3xl font-extrabold border-b-2 pb-3 mb-3 dark:text-gray-200 dark:border-gray-700">{section.title}</h1>
            <div className="grid grid-cols-3 gap-6">
                {section.data.map(item => <div key={item.title}>
                    {item.type === "file" ?
                    <Fragment>
                        <h2 className="text-lg mb-1 dark:text-gray-200">{item.title}</h2>
                        <input type="file" name={item.name} className={input_style} onChange={event => handle_state(event.target, section.title)} />
                    </Fragment>
                    :
                    item.type !== "input" && item.type !== "password" ? <Fragment>
                        <h2 className="text-lg bg-primary rounded-md p-2 text-gray-100">{item.title}</h2>
                        <h2 className="mt-2 ml-2 text-md dark:text-gray-200">{state[item.value] || `no ${item.title}`}</h2>
                    </Fragment>
                    :
                    <Fragment>
                        <h2 className="text-lg mb-1 dark:text-gray-200">{item.title}</h2>
                        <input type={item.type === "input" ? "text" : "password"} value={state[item.value]} name={item.name} className={input_style} onChange={event => handle_state(event.target)} />
                    </Fragment>}
                </div>)}
            </div>

            {/* the submit button */}
            <div className="mb-6">
                {section.title !== "user details" && <Button title="update" action="button" handler={() => handle_update(section.title)} disable={is_disable} />}
            </div>
        </div>
    );
};
export default ProfileSection;