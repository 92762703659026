// import the react components
import React from "react";

// import the colors
import {gradient_primary} from "../assets/data/colors"

// the title functional based component
const Title = ({icon, text}) => {

    // return to the component data
    return (
        <div className="flex items-center gap-3 mb-4">
            <span className="p-3 rounded-md text-14 text-gray-50" style={{background: gradient_primary}}>{icon}</span>
            <span className="capitalize tracking-wider dark:text-gray-200">{text}</span>
        </div>
    );
};
export default Title;