// import the react components
import React, {useEffect, useState} from "react";

// import the custome components
import Title from "./../ui/title";
import Table from "./../components/table";
import TableBody from "./../components/table-body";
import ReferredItem from "./../ui/refer/referred-item";

// import the icons
import {BsFillSendPlusFill} from "react-icons/bs";

// import the api components
import axios from "axios";
import {REFER_API} from "./../assets/data/api";

// import the context
import {useStateContext} from "./../context/";
import useNavigation from "./../context/navigation";

// the referred functional based component
const Referred = () => {
    const {is_loged, saved_refered, dispatch_refered, set_is_loading} = useStateContext();
    const [page, set_page] = useState(1);
    useNavigation();

    // get the patient data from the server
    useEffect(() => {
        saved_refered.length === 0 && set_is_loading(true);

        // prepare the query
        const endpoint = REFER_API + "read.php";
        const data = {
            username: is_loged.username,
            type: "referred",
        };

        // send the query
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success) dispatch_refered({type: "add", payload: data.patients});
            set_is_loading(false);
        });
    }, []);

    // the header of the table
    const header = ["id", "to", ""];

    // return to the component data
    return (
        <div>

            {/* the title of the webpage */}
            <Title text="referred patients" icon={<BsFillSendPlusFill />} />

            {/* the data of the webpage */}
            <Table paragraph="The list of the patients that referred to other dentists from your side." header={header} body={saved_refered} page={page} set_page={set_page} has_print={true}>
                <TableBody data={saved_refered} page={page} Component={ReferredItem} />
            </Table>
        </div>
    );
};
export default Referred;