// import the react components
import React from "react";
import {useNavigate} from "react-router-dom";

// import the custome components
import ConfirmationDialog from "./../../components/confirm";

// import the api components
import axios from "axios";
import {PATIENT_API, REFER_API} from "./../../assets/data/api";

// import the context
import {useStateContext} from "./../../context/";

// the book actions functional based component
const BookActions = ({patient, id}) => {
    const {is_loged, set_alert} = useStateContext();
    const navigate = useNavigate();

    // handle the delete
    const handle_delete = () => {

        // prepare the query
        const endpoint = PATIENT_API + "delete.php";
        const data = {id};

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success){
                set_alert({type: "success", text: "the book deleted successfully"});

                // check the id
                const first_id = patient.books[0].id;
                if (id === first_id) navigate("/clinic");
                else navigate(`/book/${first_id}`);
            }

            // if the error occured
            else if (data.error) set_alert({type: "error", text: data.error});
        });
    };

    // handle the refer delete
    const refer_delete = () => {

        // prepare the query
        const endpoint = REFER_API + "delete.php";
        const data = {
            username: is_loged.username,
            patient: patient.books[0].id,
        };

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success){
                set_alert({type: "success", text: "the refer deleted successfully"});
                navigate("/received");
            }

            // if the error occured
            else if (data.error) set_alert({type: "error", text: data.error});
        });
    };

    // return to the component data
    return (
        <div>
            {!patient.is_refer ?
            <div className="flex gap-3">
                <ConfirmationDialog button_title="delete book" dialog_title="delete book" dialog_content="the book will be completely deleted from the server, please confirm your action." handle_action={handle_delete} />
            </div>
            :
            <div className="flex gap-3">
                <ConfirmationDialog button_title="remove refer" dialog_title="remove refer" dialog_content="the refer will be rejected, are you sure?" handle_action={refer_delete} />
            </div>}
        </div>
    );
};
export default BookActions;