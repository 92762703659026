// import the react components
import React from "react";

// import the context
import {useStateContext} from "./../../context/";

// the theme options functional based component
const ThemeOptions = () => {
    const {theme_mode, set_theme_mode} = useStateContext();

    // the theme handler
    const theme_handler = event => {
        set_theme_mode(event.target.value);
        localStorage.setItem("theme_mode", event.target.value);
    };

    // the options
    const options = ["light", "dark"];

    // return to the component data
    return (
        <div>
            {options.map(item => <div key={item} className="mt-4">
                <input
                    type="radio"
                    id={item}
                    name="theme"
                    value={item}
                    className="cursor-pointer focus:ring-0 focus:outline-none text-primary"
                    onChange={theme_handler}
                    checked={item == theme_mode && true}
                />
                <label htmlFor={item} className="ml-2 text-md cursor-pointer">{item}</label>
            </div>)}
        </div>
    );
};
export default ThemeOptions;