// the book categories
export const book_categories = [
    "all",
    "anatomy",
    "conservative",
    "endodontics",
    "esthetic",
    "implantology",
    "oral and maxillofacial surgery",
    "orthodontics",
    "pediatric",
    "periodontics",
    "prosthodontics",
    "radiology",
    "photography",
    "restorative",
    "science and research",
];

// the category without all
export const clear_book_categories = book_categories.filter(item => item !== "all");