// import the react components
import React, {useEffect, useState} from "react";

// import the cutome components
import BookModal from "./book-modal";
import Button from "./../button";
import NoViewData from "./../no-view-data";

// import the api components
import axios from "axios"
import {BOOK_IMAGE_API, BOOK_API} from "./../../assets/data/api";

// import the icons
import {AiOutlineCheckCircle} from "react-icons/ai";

// import the context
import {useStateContext} from "./../../context/";

// import the styles
import {tr_style, td_style} from "./../../assets/data/style";

// the book view functional based component
const BookView = ({id}) => {
    const {book_modal, set_book_modal, set_is_loading} = useStateContext();
    const [book, set_book] = useState({});

    // get the book
    useEffect(() => {
        set_is_loading(true);

        // prepare the query
        const endpoint = BOOK_API + "read-single.php";
        const data = {id};

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success) set_book(data.book);
            set_is_loading(false);
        });
    }, []);

    // the details about the book
    const details = ["publisher", "published_date", "pages", "languages", "format", "in_stock"];

    // return to the component data
    return (
        <div>

            {/* the modals */}
            {book_modal && <BookModal book={book} />}

            {/* the book data */}
            {book.id ? <div className="text-gray-700 body-font overflow-hidden dark:text-gray-300">
                <div className="container px-5 py-8 mx-auto">
                    <div className="lg:w-4/5 mx-auto flex flex-wrap justify-center items-center">

                        {/* the image */}
                        <img className="h-96 object-contain" src={BOOK_IMAGE_API + book.cover} />

                        {/* the book details */}
                        <div className="lg:w-2/3 w-full lg:pl-7 lg:py-6 mt-6 lg:mt-0 flex flex-col gap-y-1.5">
                            <h1 className="text-5xl mb-1 capitalize text-gray-900 dark:text-gray-200">{book.title}</h1>
                            <h5>{book.author}</h5>
                            <h2>
                                <span className={book.discount && "line-through text-red-700 mr-2"}>{book.price}$</span>
                                {book.discount && <span>{book.discount}$</span>}
                            </h2>
                            {Number(book.in_stock) > 0 && <h5 className="text-green-500 flex gap-x-1 items-center"><AiOutlineCheckCircle /> available in stock</h5>}
                            <p className="my-1 text-gray-800 dark:text-gray-300 leading-relaxed">{book.introduction}</p>
                            <Button title="request" action="button" handler={() => set_book_modal(true)} custom_class="w-32" />
                        </div>
                    </div>

                    {/* the description */}
                    <div className="mt-10 border-t-2 pt-5 dark:border-gray-700">
                        <p className="leading-loose">{book.description}</p>
                    </div>

                    {/* the product details */}
                    <table className="w-full mt-5">
                        <thead className="text-sm text-left text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                {details.map(item => <th key={item} className={td_style}>{item.replace("_", " ")}</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={tr_style}>
                                {details.map(item => <td key={item} className={td_style}>{book[item]}</td>)}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            :
            <NoViewData />}
        </div>
    );
};
export default BookView;