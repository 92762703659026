// import the react components
import React, {useEffect, useState} from "react";

// import the api components
import axios from "axios";
import {COURSE_API, ACTION_API, COURSE_IMAGE_API, COURSE_VIDEO_API, PROFILE_API} from "./../../assets/data/api";

// import the custome components
import ConfirmationDialog from "./../../components/confirm";
import NoViewData from "./../no-view-data";

// import the icons
import {AiOutlineEye} from "react-icons/ai";
import {MdDriveFileRenameOutline} from "react-icons/md";
import {FaLightbulb} from "react-icons/fa";
import {VscSymbolMethod} from "react-icons/vsc";
import {BsFillPhoneFill} from "react-icons/bs";

// import the context
import {useStateContext} from "./../../context/";

// the course view functional based component
const CourseView = ({id}) => {
    const {is_loged, set_alert, set_is_loading} = useStateContext();
    const [course, set_course] = useState({});

    // get the course
    useEffect(() => {
        set_is_loading(true);

        // prepare the query
        const endpoint = COURSE_API + "read-single.php";
        const data = {id};

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success) set_course(data.course);
            set_is_loading(false);
        });
    }, []);

    // the enroll handler
    const enroll_handler = () => {

        // prepare the query
        const endpoint = ACTION_API + "create.php";
        const data = {
            username: is_loged.username,
            type: "enroll",
            action: course.id,
        };

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success){
                set_alert({type: "success", text: "you enrolled in the course"});
            }

            // if error oocured
            else if (data.error) set_alert({type: "error", text: data.error});
        });
    };

    // the details
    const details = [
        {title: "what will you learn", data: "learn"},
        {title: "what are the contents of course", data: "content"},
        {title: "what are requirements before enrolling", data: "requirement"},
        {title: "description", data: "description"}
    ];

    // the instructor's data
    const instructor_data = [
        {icon: <MdDriveFileRenameOutline />, data: "name"},
        {icon: <FaLightbulb />, data: "degree"},
        {icon: <VscSymbolMethod />, data: "total_courses"},
        {icon: <BsFillPhoneFill />, data: "phone"},
    ];

    // return to the component data
    return (
        <div>
            {course.id ? <section className="text-gray-700 body-font overflow-hidden dark:text-gray-300">
                <div className="container px-5 py-8 mx-auto">
                    <div className="lg:w-4/5 mx-auto flex flex-wrap">

                        {/* the intro video */}
                        <div className="lg:w-1/2 w-full max-h-screen relative">
                            {course.intro ?
                            <video src={COURSE_VIDEO_API + course.intro} controls autoPlay></video>
                            :
                            <img src={COURSE_IMAGE_API + course.cover} />}
                        </div>

                        {/* the body of course */}
                        <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0 flex flex-col justify-center">

                            {/* the course header */}
                            <h2 className="text-sm title-font text-gray-500 tracking-widest uppercase">Dr. {course.username}</h2>
                            <h1 className="text-gray-900 text-3xl title-font font-semibold mb-1 dark:text-gray-200">{course.title}</h1>
                            <h1 className="text-gray-900 text-md title-font mb-1 dark:text-gray-300">{course.subtitle}</h1>

                            {/* the course review section */}
                            <div className="flex mb-4">
                                <span className="flex items-center">
                                    <span className="text-gray-600 ml-3 flex gap-x-1 items-center">{course.seen} <AiOutlineEye /></span>
                                </span>

                                {/* the social contacts */}
                                <span className="flex ml-3 pl-3 py-2 border-l-2 border-gray-200 dark:border-gray-700">
                                    <a className="text-gray-500" href={course.facebook ? course.facebook : "#"}>
                                        <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                                            <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                                        </svg>
                                    </a>
                                    <a className="ml-2 text-gray-500" href={course.instagram ? course.instagram : "#"}>
                                        <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                                            <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                                        </svg>
                                    </a>
                                    <a className="ml-2 text-gray-500 " href={course.whatsapp ? course.whatsapp : "#"}>
                                        <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                                            <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
                                        </svg>
                                    </a>
                                </span>
                            </div>

                            {/* the description */}
                            <p className="leading-relaxed" dangerouslySetInnerHTML={{__html: course.introduction}} />

                            <div className="flex justify-between items-center mt-4">
                                <div className="flex gap-2 text-xl">
                                    <p className={course.discount && "line-through text-red-700"}>{Number(course.price).toLocaleString()}$</p>
                                    {course.discount && <p>{Number(course.discount).toLocaleString()}$</p>}
                                </div>

                                <div className="flex gap-x-3 items-center">
                                    <ConfirmationDialog button_title="enroll" dialog_title="confirm enrolling" dialog_content="once you enroll the course, you can't take your action back." handle_action={enroll_handler} />
                                </div>
                            </div>
                        </div>

                        {/* the description */}
                        <div className="border-t-2 border-gray-300 mt-8 pt-6 dark:border-gray-700">
                            {details.map(item => <div key={item.title} className="mb-4">
                                <h1 className="text-3xl font-bold mb-1 text-gray-900 dark:text-gray-200">{item.title}</h1>
                                <p className="leading-relaxed" dangerouslySetInnerHTML={{__html: course[item.data]}} id="blog" />
                            </div>)}
                        </div>

                        {/* the instructor's profile */}
                        <div>
                            <h1 className="text-3xl font-bold mb-4 text-gray-900 dark:text-gray-200">instructor</h1>

                            <div className="flex w-full items-center gap-5">
                                <img src={PROFILE_API + course.logo} className="w-36 h-36 object-contain rounded-full border-1 border-gray-800" />
                                <div className="leading-loose">
                                    {instructor_data.map(item => <div key={item.data} className="flex gap-2 items-center">
                                        <h2>{item.icon}</h2>
                                        <h2>{course[item.data]} {item.data === "total_courses" && <span>shared courses</span>}</h2>
                                    </div>)}
                                </div>
                            </div>
                            <p className="mt-4 leading-relaxed">{course.about}</p>
                        </div>
                    </div>
                </div>
            </section>
            :
            <NoViewData />}
        </div>
    );
};
export default CourseView;