// import the react components
import React, {useEffect, useState} from "react";

// import the custome components
import Title from "./../ui/title";
import Table from "./../components/table";
import TableBody from "./../components/table-body";
import ReceivedItem from "./../ui/refer/received-item";

// import the icons
import {RiUserReceived2Fill} from "react-icons/ri";

// import the api components
import axios from "axios";
import {REFER_API} from "./../assets/data/api";

// import the context
import {useStateContext} from "./../context/";
import useNavigation from "./../context/navigation";

// the received functional based component
const Received = () => {
    const {is_loged, saved_received, dispatch_received, set_is_loading} = useStateContext();
    const [page, set_page] = useState(1);
    useNavigation();

    // get the patient data from the server
    useEffect(() => {
        saved_received.length === 0 && set_is_loading(true);

        // prepare the query
        const endpoint = REFER_API + "read.php";
        const data = {
            username: is_loged.username,
            type: "received",
        };

        // send the query
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success) dispatch_received({type: "add", payload: data.patients});
            set_is_loading(false);
        });
    }, []);

    // the header of the table
    const header = ["id", "from", "treatments", "", ""];

    // return to the component data
    return (
        <div>

            {/* the title of the webpage */}
            <Title text="received patients" icon={<RiUserReceived2Fill />} />

            {/* the data */}
            <Table paragraph="This table shows the list of the patients that being referred from other dentists." header={header} body={saved_received} page={page} set_page={set_page} has_print={true}>
                <TableBody data={saved_received} page={page} Component={ReceivedItem} />
            </Table>
        </div>
    );
};
export default Received;