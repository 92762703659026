// import the react components
import React, {useEffect, useState} from "react";

// import the custome components
import NoViewData from "./../no-view-data";

// import the api components
import axios from "axios";
import {LABORATORY_API} from "./../../assets/data/api";

// import the context
import {useStateContext} from "./../../context/";

// the laboratory view functional based component
const LaboratoryView = ({id}) => {
    const {set_is_loading} = useStateContext();
    const [laboratory, set_laboratory] = useState({});

    // get the laboratory
    useEffect(() => {
        set_is_loading(true);

        // prepare the query
        const endpoint = LABORATORY_API + "read-single.php";
        const data = {id};

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success) set_laboratory(data.laboratory);
            set_is_loading(false);
        });
    }, []);

    // return to the component data
    return (
        <div>
            {laboratory.id ? <div className="mt-5">
                <h1 className="text-4xl font-bold mb-2 dark:text-gray-200">{laboratory.title}</h1>
                <p dangerouslySetInnerHTML={{__html: laboratory.description}} id="blog" className="dark:text-gray-200" />
            </div>
            :
            <NoViewData />}
        </div>
    );
};
export default LaboratoryView;