// import the react components
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import useNavigation from "./../context/navigation";

// import the custome components
import Alerter from "./../components/alerter";
import Header from "./../ui/registration/header";
import Input from "./../ui/registration/input";
import FormExtra from "./../ui/registration/form-extra";
import FormAction from "./../ui/registration/form-action";
import Footer from "./../components/footer";

// import the api data
import axios from "axios";
import {USER_API} from "./../assets/data/api";

// import the context
import {useStateContext} from "./../context/";

// initiate the login data
import {login_fields} from "./../assets/data/registration";
const fields = login_fields;
let field_state = {};
fields.forEach(field => field_state[field.id] = "");

// the login functional based component
const LogIn = () => {
    const {set_is_loged, set_alert, set_is_disable} = useStateContext();
    const [login, set_login] = useState(field_state);
    const [is_remember, set_is_remember] = useState(false);
    useNavigation();

    // the navigation
    const navigate = useNavigate();

    // handle the input change
    const handle_change = event => set_login({...login, [event.target.id]: event.target.value});
    const handle_checkbox = () => set_is_remember(!is_remember);

    // handle the form submit
    const handle_submit = event => {
        event.preventDefault();
        set_is_disable(true);

        // prepare the query
        const endpoint = USER_API + "read-single.php";
        const data = {
            username: login.username,
            password: login.password,
        };

        // handle the query
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success){
                set_is_loged(data.user);
                navigate("/auth/authentication");
            }

            // if the dashboard is active but the email is inactive
            else if (data.error && data.auth){
                set_is_loged(data.user);
                set_alert({type: "error", text: data.error});
                navigate("/auth/reverification");
            }

            // if the error occured
            else if (data.error) set_alert({type: "error", text: data.error});

            // remove the disable of the button
            set_is_disable(false);
        });
    };

    // return to the component data
    return (
        <div className="-m-5 min-h-full h-screen flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <Alerter />

            {/* the login form */}
            <div className="max-w-md w-full space-y-8 p-4">
                <Header
                    heading="Login to your dashboard"
                    paragraph="don't have a dashboard yet?"
                    link_name="Logup"
                    link_url="/logup"
                />

                <form className="space-y-6" action="#">
                    <div className="-space-y-px">
                        {fields.map(field=>
                            <Input
                                key={field.id}
                                input_data={field}
                                handle_change={handle_change}
                                value={login[field.id]}
                            />
                        )}
                    </div>

                    <FormExtra value={is_remember} handle_checkbox={handle_checkbox} />
                    <FormAction text="login" handle_submit={handle_submit} />
                </form>
            </div>

            {/* the footer */}
            <Footer copy={false} />
        </div>
    );
};
export default LogIn;