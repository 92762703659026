// import the react components
import React from "react";
import {useNavigate} from "react-router-dom";

// import the custome components
import CartModal from "./../cart/cart-modal";

// import the colors
import {gradient_info} from "./../../assets/data/colors";

// import the api components
import {PRODUCT_IMAGE_API} from "./../../assets/data/api";

// import the context
import {useStateContext} from "./../../context/";

// import the general data
import {token} from "./../../assets/data/general";

// the product functional based component
const Product = ({product}) => {
    const {is_loged, set_product_modal, cart_modal, set_cart_modal, set_selected_product} = useStateContext();
    const navigate = useNavigate();

    // prepare the images and colors
    const images = product.images.split(token);
    const colors = product.colors.replace(" ", "").split(",");

    // the click handler
    const click_handler = () => {

        // if the user owner of product
        if (is_loged.username === product.username){
            set_selected_product(product);
            set_product_modal(true);
        }

        // view the product
        else navigate(`/view/product/${product.id}`);
    };

    // add to the cart handler
    const add_handler = () => set_cart_modal(true);

    // return to the component data
    return (
        <div className="relative">
            {cart_modal && <CartModal product={product} colors={colors} />}
            <div className="hover:opacity-80 cursor-pointer dark:bg-secondary-dark-bg rounded-sm" onClick={click_handler}>
                <div className="w-full overflow-hidden rounded-md">
                    <img
                        src={PRODUCT_IMAGE_API + images[0]}
                        alt="product"
                        className="w-full h-60 object-center object-contain bg-white"
                    />
                </div>

                <div className="mt-4 flex justify-between items-center p-2 pb-4">
                    <div>
                        <h3 className="text-md text-gray-900 dark:text-gray-200 font-bold traking-tight">{product.title}</h3>
                        <div className="mt-2 flex gap-1">
                            {colors && colors.map(item => <div style={{background: item}} key={item} className="w-4 h-4 rounded-full" />)}
                        </div>
                    </div>
                    <p className="text-sm font-normal text-gray-900 dark:text-gray-200">{product.pack_price ? product.pack_price.toLocaleString() : product.unit_price.toLocaleString()}$</p>
                </div>
            </div>

            <div className="w-full flex justify-center items-center">
                <button className="w-full text-center py-2 rounded-sm hover:opacity-90" style={{background: gradient_info}} onClick={add_handler}>
                    add to cart
                </button>
            </div>
        </div>
    );
};
export default Product;