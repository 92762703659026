// import the react components
import React, {useState} from "react";

// import the dialog components
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";

// import the custom components
import Button from "./../ui/button";

// import the colors
import {gradient_danger} from "./../assets/data/colors";

// import the context
import {useStateContext} from "./../context/";

// the confirmation dialog functional based component
const ConfirmationDialog = ({button_title, dialog_title, dialog_content, handle_action, title_text = false}) => {
    const {theme_mode} = useStateContext();
    const [open, set_open] = useState(false);

    // the styles
    const theme = useTheme();
    const full_screen = useMediaQuery(theme.breakpoints.down("md"));

    // handle the open
    const handle_open = () => set_open(true);

    // handle the close
    const handle_close = () => set_open(false);

    // handle action
    const action_handler = () => {
        handle_action();
        handle_close();
    };

    // the styles
    const dark_style = "bg-secondary-dark-bg text-gray-200";

    // return to the component data
    return (
        <div>
            {title_text ? <button onClick={handle_open} className="dark:text-gray-200">{button_title}</button> : <Button action="button" title={button_title} handler={handle_open} custom_class="w-auto px-6 py-2" />}

            {/* the dialog */}
            <Dialog fullScreen={full_screen} open={open} onClose={handle_close} area-aria-labelledby="dialog">
                <div className={`${theme_mode === "dark" && dark_style}`}>
                    <DialogTitle id="dialog">
                        {dialog_title}
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText>
                            <span className={`${theme_mode === "dark" && "text-gray-200"}`}>{dialog_content}</span>
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button action="button" title="close" handler={handle_close} custom_class={`${theme_mode === "dark" ? "text-gray-200" : "text-gray-900"}`} bg_color="transparent" />
                        <Button action="button" title="confirm" handler={action_handler} bg_color={gradient_danger} />
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
};
export default ConfirmationDialog;