// import the react components
import React, {useState} from "react";

// import the custome components
import Modal from "./../modal";

// import the styles
import {input_style} from "./../../assets/data/style";

// import the api components
import axios from "axios";
import {CART_API} from "./../../assets/data/api";

// import the context
import {useStateContext} from "./../../context/";

// the cart modal functional based component
const CartModal = ({product, colors}) => {
    const {set_cart_modal, selected_cart, set_selected_cart, is_loged, dispatch_cart, set_alert} = useStateContext();
    const [state, set_state] = useState({
        type: selected_cart ? selected_cart.type : "pack",
        color: selected_cart ? selected_cart.color : colors[0],
        count: selected_cart ? selected_cart.count : 0,
        note: selected_cart ? selected_cart.note : "",
    });

    // close the modal
    const handle_close = () => {
        set_cart_modal(false);
        set_selected_cart(null);
    };

    // handle the create and update actions
    const handle_submit = () => {

        // prepare the query
        const endpoint = CART_API + `${selected_cart ? "update.php" : "create.php"}`;
        const data = {
            ...state,
            id: selected_cart ? selected_cart.id : "",
            uniq: selected_cart ? selected_cart.uniq : "",
            product: selected_cart ? selected_cart.product : product.id,
            username: selected_cart ? selected_cart.username : product.username,
            dentist: is_loged.username,
        };

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success){
                selected_cart ? dispatch_cart({type: "update", payload: data.cart}) : dispatch_cart({type: "push", payload: data.cart});
                set_alert({type: "success", text: "the cart saved successfully"});
                handle_close();
            }

            // if the error occured
            else if (data.error) set_alert({type: "error", text: data.error});
        })
    };

    // the state handler
    const handle_state = event => set_state({...state, [event.target.name]: event.target.value});

    // the footer buttons
    const buttons = [
        {
            title: "save",
            handler: handle_submit,
        },
    ];

    // the colors
    const final_colors = selected_cart ? selected_cart.colors.split(",") : colors;

    // return to the component data
    return (
        <Modal handle_close={handle_close} buttons={buttons} z_index={10}>
            <div className="grid grid-cols-1 gap-y-8">
                <select className={input_style} value={state.type} name="type" onChange={handle_state}>
                    <option value="pack">pack</option>
                    <option value="single">single</option>
                </select>

                <input
                    type="text"
                    name="count"
                    placeholder="set the number"
                    value={state.count}
                    onChange={handle_state} 
                    className={`${input_style}`}
                    required
                />

                <select className={input_style} value={state.color} name="color" onChange={handle_state}>
                    {final_colors.map(item => <option key={item} value={item}>{item}</option>)}
                </select>

                <input
                    type="text"
                    name="note"
                    placeholder="set the note"
                    value={state.note}
                    onChange={handle_state} 
                    className={`${input_style}`}
                    required
                />
            </div>
        </Modal>
    );
};
export default CartModal;