// import the react components
import React from "react";

// import the printer
import ReactToPrint from "react-to-print";

// import the colors
import {gradient_primary} from "./../assets/data/colors";

// the print functional based component
const Print = ({reference}) => {

    // return to the component data
    return (
        <div className="mt-2">
            <ReactToPrint
                content={() => reference.current}
                trigger={() => <button className="py-2.5 px-7 rounded-md text-xl text-gray-100 hover:opacity-90" style={{background: gradient_primary}}>print</button>}
            />
        </div>
    );
};
export default Print;