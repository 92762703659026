// import the react components
import React, {useEffect, useState} from "react";

// import the custome components
import ReferModal from "./../refer/refer-modal";
import CaseModal from "./../case/case-modal";
import PatientHeader from "./patient-header";
import PatientData from "./patient-data";
import PatientDataImages from "./patient-data-images";
import PatientDataTeeth from "./patient-data-teeth";
import PatientDataTreatment from "./patient-data-treatment";
import Card from "./../card";
import Button from "./../button";
import NoViewData from "./../no-view-data";

// import the api components
import axios from "axios";
import {PATIENT_API} from "./../../assets/data/api";

// import the context
import {useStateContext} from "./../../context/";

// the patient view functional based component
const PatientView = ({id}) => {
    const {is_loged, refer_modal, set_refer_modal, case_modal, set_case_modal, set_is_loading, set_alert} = useStateContext();
    const [patient, set_patient] = useState({});

    // get the patient's data
    useEffect(() => {
        set_is_loading(true);

        // prepare the query
        const endpoint = PATIENT_API + "read-single.php";
        const data = {id, username: is_loged.username};

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success){
                set_patient(data.patient);
            }
            set_is_loading(false);
        });
    }, []);

    // the data of sections
    const sections = [
        {
            heading: "patient's information",
            data: patient.information,
            type: "columns",
        },
        {
            heading: "anamnesis",
            data: patient.anamnesis,
            type: "columns",
            columns: "grid-cols-1",
        },
        {
            heading: "medical history",
            data: patient.medical_history,
            type: "columns",
        },
        {
            heading: "compromised patient",
            data: patient.compromised_patient,
            type: "columns",
            columns: "grid-cols-2 sm:gird-cols-1",
        },
        {
            heading: "dental history",
            data: patient.dental_history,
            type: "columns",
        },
        {
            heading: "general evaluation",
            data: patient.general_evaluation,
            type: "columns",
        },
        {
            heading: "extraoral evaluation",
            data: patient.extraoral_evaluation,
            type: "columns",
            columns: "grid-cols-2 sm:gird-cols-1",
        },
        {
            heading: "occlusion evaluation",
            data: patient.occlusion_evaluation,
            type: "columns",
        },
        {
            heading: "intraoral tissue evaluation",
            data: patient.intraoral_tissue_evaluation,
            type: "columns",
            columns: "grid-cols-2 sm:gird-cols-1",
        },
        {
            heading: "teeth evaluation",
            data: patient.teeth_evaluation,
            type: "teeth",
        },
        {
            heading: "xray images",
            data: patient.xrays,
            type: "images",
        },
        {
            heading: "case images",
            data: patient.cases,
            type: "images",
        },
        {
            heading: "treatment plan",
            data: patient.treatment_plan,
            type: "treatment",
        },
    ];

    // return to the component data
    return (
        <div>
            {refer_modal && <ReferModal patient={patient.books[0].id} />}
            {case_modal && <CaseModal patient={id} />}

            {/* the body of the patient data */}
            {patient.id ? <div>

                {/* the header of the patient data */}
                <PatientHeader name={patient.name} code={patient.code} title="edit" link={`/book/${id}`} />

                {/* the patient data */}
                {sections.map(item => {
                    if (item.data !== null){
                        if (item.type === "columns") return <PatientData key={item.heading} heading={item.heading} data={item.data} cols={item.columns} />
                        else if (item.type === "images" && item.data.length > 0) return <PatientDataImages key={item.heading} heading={item.heading} data={item.data} />
                        else if (item.type === "teeth" && item.data.length > 0) return <PatientDataTeeth key={item.heading} heading={item.heading} data={item.data} />
                        else if (item.type === "treatment" && item.data.length > 0) return <PatientDataTreatment key={item.heading} heading={item.heading} data={item.data} />
                    }
                })}

                {/* the actions */}
                {!patient.is_refer && <div className="border-t-2 border-gray-700 pt-10 flex flex-col gap-6">
                    <Card>
                        <h1 className="text-3xl font-bold dark:text-gray-200">refer the patient</h1>
                        <p className="mb-4 mt-2 dark:text-gray-200">to be on the safe side refer your patient to another dentist either for complete or specific treatment option.</p>
                        <Button action="button" title="refer" handler={() => set_refer_modal(true)} />
                    </Card>

                    <Card>
                        <h1 className="text-3xl font-bold dark:text-gray-200">share your case</h1>
                        <p className="mb-4 mt-2 dark:text-gray-200">share your case to other dentists, so they get benefit from the case and share your case in your dental site for your patients. the patient's sensitive information won't be shared, the anamnesis, compromised patient's data, xray images and case images will be shared.</p>
                        <Button action="button" title="share case" handler={() => set_case_modal(true)} custom_class="w-auto px-4" />
                    </Card>
                </div>}
            </div>
            :
            <NoViewData />}
        </div>
    );
};
export default PatientView;