// import the react components
import React from "react";

// import the custome components
import Card from "./../card";
import Button from "./../button";

// import the context
import {useStateContext} from "./../../context/";

// the note item functional based component
const NoteItem = ({item}) => {
    const {set_selected_note, set_note_modal} = useStateContext();

    // the click handler
    const handle_click = item => {
        set_selected_note(item);
        set_note_modal(true);
    };

    // return to the component data
    return (
        <div className="w-full">
            <div className="bg-primary text-lg p-2 text-gray-50 w-40 rounded-t-md text-center">{item.type}</div>
            <Card>
                <div>
                    <h1 className="text-4xl font-bold mb-2 dark:text-gray-200">{item.title}</h1>
                    <p dangerouslySetInnerHTML={{__html: item.description}} id="blog" className="line-clamp-5 dark:text-gray-200" />
                </div>
                <div className="mt-2.5 flex justify-end gap-x-3">
                    <Button title="view" action="link" link={`/view/note/${item.id}`} />
                    <Button title="edit" action="button" handler={() => handle_click(item)} />
                </div>
            </Card>
        </div>
    );
};
export default NoteItem;