// import the react components
import React, {Fragment} from "react";

// import the custome components
import NoData from "./../ui/no-data";

// import the chart components
import {
    ResponsiveContainer,
    BarChart,
    Bar,
    LineChart,
    Line,
    PieChart,
    Pie,
    XAxis,
    YAxis,
    Tooltip,
    CartesianGrid,
} from "recharts";

// import the colors
import {primary} from "./../assets/data/colors";

// the chart functional based component
const Chart = ({type, data}) => {
    let new_data = data ? data : [];
    const height = 300;

    // return to the component data
    return (
        <ResponsiveContainer width="100%" height={height} className="flex justify-center mt-7">
            {new_data.length > 0 ? <Fragment>
                {type === "bar" && <BarChart data={new_data} width={1100} height={height}>
                    <CartesianGrid strokeDasharray="2" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="revenue" fill={primary} />
                </BarChart>}

                {type === "line" && <LineChart data={new_data} width={600} height={height}>
                    <XAxis dataKey="name" />
                    <Tooltip />
                    <Line type="monotone" dataKey="revenue" stroke={primary} />
                </LineChart>}

                {type === "pie" && <PieChart>
                    <Pie />
                </PieChart>}
            </Fragment>
            :
            <div className="flex justify-center items-center">
                <NoData message="You shall use the dahsboard for at least one week." />
            </div>}
        </ResponsiveContainer>
    );
};

// export the component as default

export default Chart;