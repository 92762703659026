// import the react components
import React from "react";

// import the styles
import {tr_style, td_style} from "./../../assets/data/style";

// the notification item functional based component
const NotificationItem = ({item}) => {

    // return to the component data
    return (
        <tr className={tr_style}>
            <td className={td_style}>{item.id}</td>
            <td className={td_style}>{item.created_date}</td>
            <td className={td_style}>{item.username_from}</td>
            <td className={td_style}>{item.notification}</td>
        </tr>
    );
};
export default NotificationItem;