// import the react components
import React, {Fragment} from "react";
import {Link} from "react-router-dom";

// the footer functional based component
const Footer = ({copy = true}) => {
    const footer_items = [
        "about",
        "privacy",
        "guides",
    ];

    // return to the component data
    return (
        <Fragment>
            <br />
            <div className={`flex justify-between items-center ${copy ? "" : "flex-col"}`}>
                {copy && <p className="dark:text-gray-200">copyright &copy; <span className="font-extrabold">mohammad sherzad</span></p>}
                {!copy && <hr className="w-64 h-1 my-8 bg-gray-200 border-0 rounded dark:bg-gray-700" />}
                <div className="flex gap-7">
                    {footer_items.map(item => <Link to={`/${item}`} key={item} className="hover:opacity-80 dark:text-gray-200">{item}</Link>)}
                </div>
            </div>
        </Fragment>
    );
};
export default Footer;