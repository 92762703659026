// import the react components
import React, {useEffect, useState} from "react";

// import the custome components
import Title from "./../ui/title";
import Table from "./../components/table";
import TableBody from "./../components/table-body";
import TransactionItem from "./../ui/transaction/transaction-item";

// import the icons
import {IoIosPaper} from "react-icons/io";

// import the api components
import axios from "axios";
import {TRANSACTION_API} from "./../assets/data/api";

// import the context
import {useStateContext} from "./../context/";
import useNavigation from "./../context/navigation";

// the transactions functional based component
const Transactions = () => {
    const {is_loged, saved_transactions, dispatch_transaction, set_is_loading} = useStateContext();
    const [page, set_page] = useState(1);
    useNavigation();

    // get the transactions
    useEffect(() => {
        saved_transactions.length === 0 && set_is_loading(true);

        // prepare the query
        const endpoint = TRANSACTION_API + "read.php";
        const data = {username: is_loged.username};

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success) dispatch_transaction({type: "add", payload: data.transactions});
            set_is_loading(false);
        });
    }, []);

    // the header of the table
    const headers = ["id", "from", "to", ""];

    // return to the component data
    return (
        <div>

            {/* the title of the webpage */}
            <Title text="transactions" icon={<IoIosPaper />} />

            {/* the header of the webpage */}
            <Table paragraph="This table shows all your transactions in the platform." header={headers} body={saved_transactions} page={page} set_page={set_page} has_print={true}>
                <TableBody data={saved_transactions} page={page} Component={TransactionItem} />
            </Table>
        </div>
    );
};
export default Transactions;