// import the react components
import React from "react";
import {Routes, Route} from "react-router-dom";

// import the registration pages
import Home from "./../pages/home";
import LogIn from "./../pages/login";
import LogUp from "./../pages/logup";
import Forgot from "./../pages/forgot";
import Auth from "./../pages/auth";

// import the sidebar pages
import Schedule from "./../pages/schedule";
import Statistics from "./../pages/statistics";
import Notes from "./../pages/notes";
import Clinic from "./../pages/clinic";
import Received from "./../pages/received";
import Referred from "./../pages/referred";
import Orders from "./../pages/orders";
import Products from "./../pages/products";
import Workers from "./../pages/workers";
import Laboratories from "./../pages/laboratories";
import Courses from "./../pages/courses";
import Books from "./../pages/books";
import Transactions from "./../pages/transactions";
import Cases from "./../pages/cases";
import Tips from "./../pages/tips";
import Blogs from "./../pages/blogs";
import Settings from "./../pages/settings";
import Contact from "./../pages/contact";

// import the footer pages
import Privacy from "./../pages/privacy";
import Guides from "./../pages/guides";
import About from "./../pages/about";

// import the sub-pages
import Notification from "./../subpages/notification";
import Chat from "./../subpages/chat";
import Cart from "./../subpages/cart";
import Profile from "./../subpages/profile";
import Book from "./../subpages/book";
import View from "./../subpages/view";

// import the header pages
import Developer from "./../pages/developer";

// the routers functional based component
export const Routers = () => {

    // the application routes
    const routes = [
        {path: "/", component: <Home />},
        {path: "/login", component: <LogIn />},
        {path: "/logup", component: <LogUp />},
        {path: "/forgot", component: <Forgot />},
        {path: "/auth/:data", component: <Auth />},
        {path: "/schedule", component: <Schedule />},
        {path: "/statistics", component: <Statistics />},
        {path: "/notes", component: <Notes />},
        {path: "/clinic", component: <Clinic />},
        {path: "/received", component: <Received />},
        {path: "/referred", component: <Referred />},
        {path: "/orders", component: <Orders />},
        {path: "/products", component: <Products />},
        {path: "/workers", component: <Workers />},
        {path: "/laboratories", component: <Laboratories />},
        {path: "/courses", component: <Courses />},
        {path: "/books", component: <Books />},
        {path: "/transactions", component: <Transactions />},
        {path: "/cases", component: <Cases />},
        {path: "/tips", component: <Tips />},
        {path: "/blogs", component: <Blogs />},
        {path: "/settings", component: <Settings />},
        {path: "/contact", component: <Contact />},
        {path: "/privacy", component: <Privacy />},
        {path: "/guides", component: <Guides />},
        {path: "/about", component: <About />},
        {path: "/developer", component: <Developer />},
        {path: "/notification", component: <Notification />},
        {path: "/chat", component: <Chat />},
        {path: "/cart", component: <Cart />},
        {path: "/profile", component: <Profile />},
        {path: "/book/:data", component: <Book />},
        {path: "/view/:data/:data", component: <View />},
    ];

    // return to the component data
    return (
        <Routes>
            {routes.map(item => {
                const {path, component} = item;
                return <Route path={path} element={component} key={path} />
            })}
        </Routes>
    );
};
export default Routers;