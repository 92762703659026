// import the react components
import React, {useEffect, useState} from "react";

// import the custome components
import TipModal from "./../ui/tip/tip-modal";
import Title from "./../ui/title";
import SearchBar from "./../ui/search-bar";
import Button from "./../ui/button";
import TipItem from "./../ui/tip/tip-item";
import NoData from "./../ui/no-data";

// import the api components
import axios from "axios";
import {TIP_API} from "./../assets/data/api";

// import the icons
import {TiPin} from "react-icons/ti";

// import the context
import {useStateContext} from "./../context/";
import useNavigation from "./../context/navigation";

// the tips functional based component
const Tips = () => {
    const {saved_tips, dispatch_tip, tip_modal, set_tip_modal, set_is_loading} = useStateContext();
    const [search, set_search] = useState();
    useNavigation();

    // get the tips
    useEffect(() => {
        saved_tips.length === 0 && set_is_loading(true);

        // prepare the query
        const endpoint = TIP_API + "read.php";

        // send the query to the server
        axios.get(endpoint).then(response => {
            const {data} = response;
            if (data.success) dispatch_tip({type: "add", payload: data.tips});
            set_is_loading(false);
        });
    }, []);

    // return to the component data
    return (
        <div>

            {/* the modals */}
            {tip_modal && <TipModal />}

            {/* the title of the webpage */}
            <Title text="tips" icon={<TiPin />} />

            {/* the header of the webpage */}
            <div className="flex mb-6 mt-2 justify-between">
                <SearchBar placeholder="search tips" value={search} handler={value => set_search(value)} />
                <Button title="add" action="button" handler={() => set_tip_modal(true)} />
            </div>

            {/* the tips */}
            {saved_tips.length > 0 ? <div className="flex flex-col gap-y-6">
                {saved_tips.map(item => <TipItem key={item.id} item={item} />)}
            </div>
            :
            <NoData message="No tips being shared, share tip for dentists." />}
        </div>
    );
};
export default Tips;