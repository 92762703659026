// import the context
import {useStateContext} from "./";

// import the general data
import {token} from "./../assets/data/general";

// the user hook
const useAuth = () => {
    const {is_loged} = useStateContext();

    // the data
    const outside_paths = ["/", "/home", "/login", "/logup", "/forgot", "/auth"];
    const inside_paths = [
        "/schedule", "/statistics", "/notes", "/clinic", "/referred", "/received", 
        "/orders", "/products", "/workers", "/laboratories", "/courses", "/books",
        "/transactions", "/cases", "/tips", "/blogs", "/notifications", "/chat",
        "/cart", "/view", "/book",
    ];

    // check the login status
    const show = (is_loged.id && is_loged.mail_status === "active" && is_loged.auth === token) ? true : false;

    // return to the component data
    return (
        {
            outsider: outside_paths,
            insider: inside_paths,
            auth: show,
        }
    );
};
export default useAuth;