// import the react components
import React, {useState} from "react";

// import the custome components
import Modal from "./../modal";

// import the styles
import {input_style} from "./../../assets/data/style";

// import the api components
import axios from "axios";
import {REFER_API} from "./../../assets/data/api";

// import the context
import {useStateContext} from "./../../context/";

// the refer modal functional based component
const ReferModal = ({patient}) => {
    const {set_refer_modal, selected_refer, set_selected_refer, is_loged, dispatch_refered, set_alert} = useStateContext();
    const [state, set_state] = useState({
        to_username: selected_refer ? selected_refer.to_username : "",
        treatments: selected_refer ? selected_refer.treatments : "",
    });

    // close the modal
    const handle_close = () => {
        set_refer_modal(false);
        set_selected_refer(null);
    };

    // handle the create and update
    const handle_submit = () => {

        // prepare the query
        const endpoint = REFER_API + `${selected_refer ? "update.php" : "create.php"}`;
        const data = {
            ...state,
            id: selected_refer ? selected_refer.id : "",
            uniq: selected_refer ? selected_refer.uniq : "",
            from_username: is_loged.username,
            patient,
        };

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success){
                selected_refer ? dispatch_refered({type: "update", payload: data.refer}) : dispatch_refered({type: "push", payload: data.refer});
                set_alert({type: "success", text: "the refer saved successfully"});
                handle_close();
            }

            // if the error occured
            else if (data.error) set_alert({type: "error", text: data.error});
        })
    };

    // handle the delete
    const handle_delete = () => {

        // prepare the query
        const endpoint = REFER_API + "delete.php";
        const data = {id: selected_refer.id};

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success){
                dispatch_refered({type: "delete", payload: selected_refer});
                set_alert({type: "success", text: "the refer deleted successfully"});
                handle_close();
            }

            // if the error occured
            else if (data.error) set_alert({type: "error", text: data.error});
        });
    };

    // the footer buttons
    const buttons = [
        {
            title: "save",
            handler: handle_submit,
        },
    ];
    selected_refer && buttons.unshift({
        title: "delete",
        handler: handle_delete,
    });

    // extract the data
    const {to_username, treatments} = state;

    // return to the component data
    return (
        <Modal handle_close={handle_close} buttons={buttons} z_index={10}>
            <div className="grid grid-cols-1 gap-y-4">
                <input
                    type="text"
                    name="username"
                    placeholder="set the username to refer"
                    value={to_username}
                    onChange={event => set_state({...state, to_username: event.target.value})} 
                    required
                    className={`${input_style}`}
                />

                <input
                    type="text"
                    name="treatments"
                    placeholder="set the treatments"
                    value={treatments}
                    onChange={event => set_state({...state, treatments: event.target.value})} 
                    required
                    className={`${input_style}`}
                />
            </div>
        </Modal>
    );
};
export default ReferModal;