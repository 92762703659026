// import the react components
import React, {useEffect, useState} from "react";

// import the custome components
import BlogModal from "./../ui/blog/blog-modal";
import Title from "./../ui/title";
import SearchBar from "./../ui/search-bar";
import Button from "./../ui/button";
import BlogItem from "./../ui/blog/blog-item";
import NoData from "./../ui/no-data";
import ListWrapper from "./../ui/list-wrapper";

// import the api components
import axios from "axios";
import {BLOG_API} from "./../assets/data/api"

// import the icons
import {BiWorld} from "react-icons/bi";

// import the context
import {useStateContext} from "./../context/";
import useNavigation from "./../context/navigation";

// the blogs functional based component
const Blogs = () => {
    const {saved_blogs, dispatch_blog, blog_modal, set_blog_modal, set_is_loading} = useStateContext();
    const [search, set_search] = useState("");
    useNavigation();

    // get the blogs
    useEffect(() => {
        saved_blogs.length === 0 && set_is_loading(true);

        // prepare the query
        const endpoint = BLOG_API + "read.php";

        // send the query
        axios.get(endpoint).then(response => {
            const {data} = response;
            if (data.success) dispatch_blog({type: "add", payload: data.blogs});
            set_is_loading(false);
        });
    }, []);

    // return to the component data
    return (
        <div>

            {/* the modals */}
            {blog_modal && <BlogModal />}

            {/* the title of the webpage */}
            <Title text="blogs" icon={<BiWorld />} />

            {/* the header of the webpage */}
            <div className="flex justify-between items-center">
                <SearchBar placeholder="search blogs" value={search} handler={value => set_search(value)} />
                <Button title="add" action="button" handler={() => set_blog_modal(true)} />
            </div>

            {/* the blogs */}
            {saved_blogs.length > 0 ? <ListWrapper>
                {saved_blogs.map(blog => <BlogItem key={blog.id} item={blog} />)}
            </ListWrapper>
            :
            <NoData message="No blogs being shared, share a blog for dentists." />}
        </div>
    );
};
export default Blogs;