// import the react components
import React from "react";

// import the custome components
import OuterHeader from "./../ui/outer-header";
import Footer from "./../components/footer";

// import the general data
import {dashboard_cost, dental_cost} from "./../assets/data/general";

// import the context
import useAuth from "./../context/auth";

// the about functional based component
const About = () => {
    const {auth} = useAuth();

    // the about sections
    const sections = [
        {
            id: 1,
            heading: "About the Dashboard",
            paragraph: "The principle of the dashboard is simplicity. The dashboard have simple user interface design, instructions and group of features. The dashboard is a web application which is lightweight written in a modern languages. The dashboard is built for dentists for simpler and better work in the clinic, you as a dentist can record your patient's data into the database (all the data are encrypted between you and the server) which can be observed, modified and deleted anytime or anywhere from your side. The dashboard contains the following additional features:",
            list: [
                {
                    head: "schedule:",
                    text: "you can observe the calendar statistics and you can manipulate patient appointments or your events in daily, monthly, and yearly schedule. from the schedule you can add, modify and remove your appointments or events.",
                },
                {
                    head: "statistics:",
                    text: "the complete statistics of the clinic will be shown by charts which can be observed in year, month or week (example: total revenue, number of patients, number of referred patients, number of orders).",
                },
                {
                    head: "notes:",
                    text: "you can write notes, instructions and prescriptions for the patients before or following the operation or for yourself which can be stored, modified, removed and printed anytime.",
                },
                {
                    head: "clinic patients:",
                    text: "the list of the recorded patients that visited the clinic. the new patients can be recorded. the patient record includes the patient's personal information, anamnesis, medical history of the patient, compromised patient, dental history of the patient, general evaluation, extraoral evaluation, intraoral evaluation which contains hard and soft tissue, occulsion and teeth evaluation, x-ray images, case images, and treatment plan.",
                },
                {
                    head: "received patients:",
                    text: "the list of the patients that being referred from other dentists to your clinic.",
                },
                {
                    head: "referred patients:",
                    text: "the list of the patients that being referred to other dentists from your side. you can refer the patient to another dentist for complete treatment or for specific treatment option. the recorded data of the patient will be shared between you and the refered dentist.",
                },
                {
                    head: "orders:",
                    text: "the list of dentists orders to your shared products.",
                },
                {
                    head: "products:",
                    text: "the list of shared products that can be ordered online from companies or dentists. you have ability to sell your products.",
                },
                {
                    head: "workers:",
                    text: "you can find any type of worker for your clinic (example dental assisstant), if no workers are available you can request a worker, we will find the best worker for you in a short period of time.",
                },
                {
                    head: "laboratories:",
                    text: "you can find, contact, and send your work to a laboratories close or remote to your area.",
                },
                {
                    head: "courses:",
                    text: "you can join or share courses either online or offline. if the course is online you will be able to watch the videos inside the dashboard, otherwise you shall join the course by going to the selected location.",
                },
                {
                    head: "books:",
                    text: "you can purchase the books and either download it or request a hardcopy.",
                },
                {
                    head: "transactions:",
                    text: "this section shows all your transactions.",
                },
                {
                    head: "cases:",
                    text: "the list of shared cases from dentists. you can share your own cases.",
                },
                {
                    head: "tips:",
                    text: "the list of shared tips from dentists. you can share any tip to other dentists.",
                },
                {
                    head: "blogs:",
                    text: "the list of shared blogs from dentists. any dentist have ability to share a piece of information either for dentists or patients.",
                },
                {
                    head: "notifications:",
                    text: "the list of notifications from platform and events.",
                },
                {
                    head: "chat:",
                    text: "you can chat with other dentists (under development).",
                },
                {
                    head: "cart:",
                    text: "showing the list of your orders.",
                },
                {
                    head: "contact:",
                    text: "for any technical problem or question you can contact us anytime.",
                },
                {
                    head: "print:",
                    text: "you can print any chart, table and patient's data.",
                },
                {
                    head: "export:",
                    text: "you can export the table data as PDF file.",
                },
                {
                    head: "full screen size:",
                    text: "the dashboard can fit the entire screen which will hide the browser.",
                },
                {
                    head: "multiple seasions:",
                    text: "you and your assistant can use the dashboard at the same time.",
                },
            ],
        },
        {
            id: 2,
            heading: "About the Dental Site",
            paragraph: "The dental site means a separate site for you to share it among your patients or to gain new patients by advertisement. The dental site contains your cases, clinic pictures, clinic location, social media accounts, blogs, book an appointment online and any other feature as you desire. You have ability to modify your dental site from your dashboard.",
        },
        {
            id: 3,
            heading: "About the Fees",
            paragraph: "the platform is not free but it will cost you nothing.",
            have_fees: true,
        },
    ];

    // the styles
    const ul_style = "list-disc mt-3 ml-8 leading-8 text-lg flex flex-col gap-y-5 dark:text-gray-200";
    const odd_style = "text-primary font-extrabold";

    // return to the component data
    return (
        <div>

            {/* the header */}
            {!auth && <OuterHeader />}

            {/* the body of the about */}
            <div className="mt-10">
                {sections.map(section => (
                    <section key={section.id} className="flex flex-col mb-6">
                        <h1 className="text-4xl font-bold border-b-2 border-gray-300 mb-2 pb-2 dark:text-gray-200">{section.heading}</h1>
                        <p className="leading-8 text-lg dark:text-gray-200">{section.paragraph}</p>
                        <ul className={`${section.list && ul_style}`}>
                            {section.list && section.list.map(item => <li key={item.head}><span className="text-primary font-extrabold">{item.head}</span> <span className="dark:text-gray-200">{item.text}</span></li>)}
                        </ul>
                        {section.post_list && <p className="leading-8 font-extrabold mt-5 text-lg">{section.post_list}</p>}
                        {section.have_fees && <ul className={ul_style}>
                            <li>the total fees of the dashboard is <span className={odd_style}>{dashboard_cost}$</span>.</li>
                            <li>once you buy the dahsboard it will be yours for the <span className={odd_style}>lifetime</span>.</li>
                            <li>all the upcoming updates and features are going to be available for <span className={odd_style}>free</span></li>
                            <li>a <span className={odd_style}>dental site</span> can be built, hosted and supported for you for additional <span className={odd_style}>{dental_cost}$</span>.</li>
                            <li>the dental site is <span className={odd_style}>optional</span> but the dashboard is <span className={odd_style}>necessary</span>.</li>
                            <li>you have 30 days <span className={odd_style}>money guarantee back</span>.</li>
                        </ul>}
                    </section>
                ))}

                <Footer />
            </div>
        </div>
    );
};
export default About;