// import the react components
import React, {useRef} from "react";

// import the custome components
import ThemeOptions from "./../ui/side/theme-options";

// import the context
import {useStateContext} from "./../context/";
import useOutsideAlerter from "./../context/outside-alerter";

// the side setting functional based component
const SideSetting = () => {
    const {side_setting, set_side_setting} = useStateContext();
    const reference = useRef(null);
    useOutsideAlerter(reference, () => set_side_setting(false));

    // return to the component data
    return (
        <div ref={reference} className={`shadow-md h-screen dark:text-gray-200 bg-white dark:[#484B52] dark:bg-secondary-dark-bg w-400 fixed top-0 nav-item transition-all ease-linear ${side_setting ? "right-0" : "-right-full"}`}>
            <div className="flex justify-between items-center p-4 ml-4">
                <h2 className="text-xl font-medium capitalize">settings</h2>
            </div>

            <div className="flex-col border-t-2 border-color dark:border-gray-700 p-4 ml-4">
                <h3 className="text-lg font-medium capitalize">theme options</h3>
                <ThemeOptions />
            </div>
        </div>
    );
};
export default SideSetting;