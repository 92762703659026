// import the react components
import React from "react";
import ReactDOM from "react-dom/client";

// import the app component
import App from "./app";

// import the styles
import "./assets/styles/index.css";

// import the fonts
import "./assets/fonts/ubuntu-regular.ttf";

// import the context provider
import {ContextProvider} from "./context/";

// create the root of the application
const root = ReactDOM.createRoot(document.querySelector("#root"));

// render the root to the screen
root.render(
    <ContextProvider>
        <App />
    </ContextProvider>
);