// the general api data
const URL = "https://peyvin.com/";
export const USER_API = URL + "api/user/";
export const CALENDAR_API = URL + "api/calendar/";
export const NOTE_API = URL + "api/note/";
export const PATIENT_API = URL + "api/patient/";
export const REFER_API = URL + "api/refer/";
export const ORDER_API = URL + "api/order/";
export const PRODUCT_API = URL + "api/product/";
export const WORKER_API = URL + "api/worker/";
export const COURSE_API = URL + "api/course/";
export const LABORATORY_API = URL + "api/laboratory/";
export const BOOK_API = URL + "api/book/";
export const TRANSACTION_API = URL + "api/transaction/";
export const CASE_API = URL + "api/case/";
export const TIP_API = URL + "api/tip/";
export const BLOG_API = URL + "api/blog/";
export const CONTACT_API = URL + "api/contact/";
export const NOTIFICATION_API = URL + "api/notification/";
export const CHAT_API = URL + "api/chat/";
export const CART_API = URL + "api/cart/";
export const REQUEST_API = URL + "api/request/";
export const ACTION_API = URL + "api/action/";

// the images api
const IMAGE_URL = URL + "pictures/";
export const PROFILE_API = IMAGE_URL + "profiles/";
export const PRODUCT_IMAGE_API = IMAGE_URL + "products/";
export const WORKER_IMAGE_API = IMAGE_URL + "workers/";
export const COURSE_IMAGE_API = IMAGE_URL + "courses/";
export const BOOK_IMAGE_API = IMAGE_URL + "books/";
export const CASE_IMAGES_API = IMAGE_URL + "cases/";
export const XRAYS_API = IMAGE_URL + "xrays/";

// the files api
export const RESUME_API = IMAGE_URL + "resumes/";

// the videos api
const VIDEO_URL = URL + "videos/";
export const COURSE_VIDEO_API = VIDEO_URL + "courses/";