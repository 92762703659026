// import the react components
import React from "react";

// the visit us functional based component
const VisitUs = () => {

    // the visit data
    const visits = [
        {
            title: "where are we",
            data: "zakho - duhok",
        },
        {
            title: "phone number",
            data: "07508078879",
        },
    ];

    // return to the component data
    return (
        <div className="flex flex-col justify-center items-center">
            <h2 className="text-3xl font-bold mb-5 dark:text-gray-200">call us to make an appointment</h2>
            <div className="flex flex-wrap gap-4">
                {visits.map(item => <div key={item.title}>
                    <h2 className="bg-primary py-2 px-3 text-center text-gray-100 rounded-md w-80 text-lg">{item.title}</h2>
                    <p className="mb-2 ml-2 mt-2 text-md font-bold dark:text-gray-200">{item.data}</p>
                </div>)}
            </div>
        </div>
    );
};
export default VisitUs;