// import the react components
import React from "react";
import {Link} from "react-router-dom";

// import the custome components
import ConfirmationDialog from "./../../components/confirm";

// import the api components
import axios from "axios";
import {PATIENT_API} from "./../../assets/data/api";

// import the context
import {useStateContext} from "./../../context/";

// the all books funtional based component
const AllBooks = ({state, set_state, id}) => {
    const {set_alert} = useStateContext();

    // handle the click
    const handle_click = () => {

        // prepare the query
        const endpoint = PATIENT_API + "create.php";
        const data = {
            username: state.username,
            name: state.name,
            code: state.code,
            status: "sub",
        };

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success){
                set_alert({type: "success", text: "new book added"});
                set_state(preview => ({...preview, books: data.books}));
            }

            // the error occured
            else if (data.error) set_alert({type: "error", text: data.error});
        });
    };

    // return to the component data
    return (
        <div className="flex overflow-x-auto gap-1 items-center mb-6">
            {state.books.map(item => <Link to={`/book/${item.id}`} key={item.id} className={`border-primary border-2 p-2 px-2 rounded hover:opacity-80 dark:text-gray-200 ${item.id != id && "bg-primary text-gray-100"}`}>
                {item.created_date}
            </Link>)}

            <div className="ml-1 hover:opacity-80">
                <ConfirmationDialog button_title="new book" dialog_title="new book" dialog_content="the new book will be added, please confirm your action." handle_action={handle_click} title_text={true} />
            </div>
        </div>
    );
};
export default AllBooks;