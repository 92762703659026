// import the react components
import React, {useState} from "react";

// import the custome components
import Table from "./../../components/table";
import Button from "./../button";

// import the styles
import {tr_style, td_style, input_style} from "./../../assets/data/style";

// import the inputs
import {treatment_inputs} from "./../../assets/data/inputs";

// the treatment plan functional based component
const TreatmentPlan = ({state, state_handler, delete_handler}) => {
    const init_state = {
        problem: "",
        treatment_option: "",
        fees: "",
        date: "",
    };
    const [treatment, set_treatment] = useState(init_state);

    // the headers
    const headers = ["id", "problem", "treatment option", "fees", "date", ""];

    // the add handler
    const add_handler = () => {
        state_handler("treatment_plan", treatment);
        set_treatment(init_state);
    };

    // return to the component data
    return (
        <div className="mt-5">

            {/* the treatment options */}
            <Table header={headers} body={[]} is_search={false}>
                <tbody>
                    {state.map((item, index) => <tr className={tr_style} key={index}>
                        <td className={td_style}>{index + 1}</td>
                        <td className={td_style}>{item.problem}</td>
                        <td className={td_style}>{item.treatment_option}</td>
                        <td className={td_style}>{item.fees}</td>
                        <td className={td_style}>{item.date}</td>
                        <td className={td_style}>
                            <Button title="remove" action="button" handler={() => delete_handler("treatment_plan", index)} />
                        </td>
                    </tr>)}
                </tbody>
            </Table>

            {/* add the treatment option */}
            <div className="flex justify-center flex-wrap gap-4 mt-8">
                {treatment_inputs.map(item => <input
                    key={item.name}
                    className={`${input_style} max-w-max px-2`}
                    name={item.name}
                    value={treatment[item.name]}
                    placeholder={item.label}
                    onChange={event => set_treatment({...treatment, [event.target.name]: event.target.value})}
                />)}
                <Button title="add" action="button" handler={add_handler} />
            </div>
        </div>
    );
};
export default TreatmentPlan;