// import the react components
import React from "react";

// import the custome components
import Teeth from "./teeth";
import Indexes from "./indexes";
import PatientImages from "./patient-images";
import TreatmentPlan from "./treatment-plan";
import Button from "./../button";

// import the styles
import {input_style} from "./../../assets/data/style";

// import the api components
import {XRAYS_API, CASE_IMAGES_API} from "./../../assets/data/api";

// import the context
import {useStateContext} from "./../../context/";

// the patient form functional based component
const PatientForm = ({title, paragraph, data, index, name, state, set_state, set_array, delete_array, component, styles = "grid-cols-3", handle_update, open, set_open}) => {
    const {is_disable} = useStateContext();

    // return to the component data
    return (
        <div className="mb-14">

            {/* the header of the form */}
            <div className="flex flex-col justify-center cursor-pointer" onClick={() => set_open(open === index ? -1 : index)}>
                <h1 className="text-3xl font-extrabold mb-3 dark:text-gray-200">{title}</h1>
                <p className="font-normal dark:text-gray-200">{paragraph}</p>
                <hr className="mt-4 border-1 border-gray-900 opacity-100 dark:border-gray-700" />
            </div>

            {/* all forms */}
            <div className={`overflow-hidden ${open === index ? "block" : "hidden"}`}>

                {/* the teeth evaluation form */}
                {name === "teeth_evaluation" && <Teeth state={state} state_handler={set_array} delete_handler={delete_array} />}

                {/* the xray and case images */}
                {name === "xrays" && <PatientImages name={name} state={state} state_handler={set_array} delete_handler={delete_array} folder={XRAYS_API} />}
                {name === "cases" && <PatientImages name={name} state={state} state_handler={set_array} delete_handler={delete_array} folder={CASE_IMAGES_API} />}

                {/* the treatment plan form */}
                {name === "treatment_plan" && <TreatmentPlan state={state} state_handler={set_array} delete_handler={delete_array} />}

                {/* the body of the form */}
                {!component && <div className={`mt-5 grid ${styles} gap-x-10 gap-y-7 justify-items-center items-center`}>
                    {data && data.map(item => <div key={item.label} className={`w-full ${item.wrapper && item.wrapper}`}>
                        {item.type === "input" &&
                            <input
                                name={item.label}
                                value={state[item.label]}
                                className={`max-w-lg min-w-min px-2 ${item.style && item.style} ${input_style}`}
                                onChange={event => set_state(event, name)}
                                placeholder={item.label}
                            />
                        }

                        {item.type === "select" &&
                            <select
                                name={item.label}
                                className={`${item.style && item.style} ${input_style}`}
                                value={state[item.label]}
                                onChange={event => set_state(event, name)}
                            >
                                {item.options.map(option => <option value={option} key={option}>{option}</option>)}
                            </select>
                        }

                        {item.type === "textarea" &&
                            <textarea
                                name={item.label}
                                className={`focus:ring-0 ${item.style && item.style} ${input_style}`}
                                value={state[item.label]}
                                onChange={event => set_state(event, name)}
                                placeholder={item.label}
                            />
                        }
                    </div>)}
                </div>}

                {/* the buttons */}
                <div className="flex gap-4 mt-5">
                    <Button title="save" action="button" handler={() => handle_update(name)} disable={is_disable} />
                </div>
            </div>
        </div>
    );
};
export default PatientForm;