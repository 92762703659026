// import the react components
import React from "react";

// import the custome components
import OuterHeader from "./../ui/outer-header";

// import the context
import useAuth from "./../context/auth";

// import the image
import mohammad_image from "./../assets/images/mohammad.jpg";

// import the api components
import {RESUME_API} from "./../assets/data/api";

// import the colors
import {gradient_primary} from "./../assets/data/colors";

// the developer functional based component
const Developer = () => {
    const {auth} = useAuth();

    // return to the component data
    return (
        <div>

            {/* the header */}
            {!auth && <OuterHeader />}

            <div className="mt-20 mx-10 p-6 rounded-md relative" style={{background: gradient_primary}}>
                <img src={mohammad_image} alt="developer of peyvin dashboard mohammad sherzad aziz" className="h-80 rounded-full border-4 border-gray-50 absolute -top-9 -left-9" />

                {/* the details about the developer */}
                <div className="ml-5 flex flex-col justify-center text-gray-50">
                    <h1 className="text-4xl font-extrabold mb-3">dental student and developer</h1>
                    <p className="leading-loose text-lg font-medium mx-1">dental student, full-stack web developer, web designer, mobile app developer and designer, embedded microdevices developer, and ethical hacker student. I'm mohammad sherzad abdulaziz student in college of dentistry at university of duhok in fifth stage. for more information view my resume.</p>
                    <a href={RESUME_API + "mohammad.pdf"} className="block max-w-max bg-gray-50 py-2.5 px-5 mt-4 text-lg rounded-md text-gray-900 hover:opacity-80" target="_blanck" download>view resume</a>
                </div>
            </div>
        </div>
    );
};
export default Developer;