// import the react components
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

// import the custome components
import Modal from "./../modal";
import TextEditor from "./../../components/text-editor";

// import the styles
import {input_style} from "./../../assets/data/style";

// import the api components
import axios from "axios";
import {LABORATORY_API} from "./../../assets/data/api";

// import the context
import {useStateContext} from "./../../context/";

// the laboratory modal functional based component
const LaboratoryModal = () => {
    const {set_laboratory_modal, selected_laboratory, set_selected_laboratory, is_loged, dispatch_laboratory, set_alert} = useStateContext();
    const [state, set_state] = useState({
        title: selected_laboratory ? selected_laboratory.title : "",
        introduction: selected_laboratory ? selected_laboratory.introduction : "",
        description: selected_laboratory ? selected_laboratory.description : "",
    });
    const navigate = useNavigate();

    // close the modal
    const handle_close = () => {
        set_laboratory_modal(false);
        set_selected_laboratory(null);
    };

    // handle the create and update
    const handle_submit = () => {

        // prepare the query
        const endpoint = LABORATORY_API + `${selected_laboratory ? "update.php" : "create.php"}`;
        const data = {
            username: is_loged.username,
            id: selected_laboratory ? selected_laboratory.id : "",
            uniq: selected_laboratory ? selected_laboratory.uniq : "",
            ...state,
        };

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success){
                selected_laboratory ? dispatch_laboratory({type: "update", payload: data.laboratory}) : dispatch_laboratory({type: "push", payload: data.laboratory});
                set_alert({type: "success", text: "the laboratory saved successfully"});
                handle_close();
            }

            // if the error occured
            else if (data.error) set_alert({type: "error", text: data.error});
        });
    };

    // handle the delete
    const handle_delete = () => {

        // prepare the query
        const endpoint = LABORATORY_API + "delete.php";
        const data = {id: selected_laboratory.id};

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success){
                dispatch_laboratory({type: "delete", payload: selected_laboratory});
                set_alert({type: "success", text: "the laboratory deleted successfully"});
                handle_close();
            }

            // if the error occured
            else if (data.error) set_alert({type: "error", text: data.error});
        });
    };

    // handle the view
    const handle_view = () => navigate(`/view/laboratory/${selected_laboratory.id}`);

    // the state handler
    const state_handler = event => set_state({...state, [event.target.name]: event.target.value});

    // the footer buttons
    const buttons = [
        {
            title: "save",
            handler: handle_submit,
        },
    ];
    if (selected_laboratory){
        buttons.unshift(
            {
                title: "delete",
                handler: handle_delete,
            },
            {
                title: "view",
                handler: handle_view,
            }
        );
    };

    // extract the data
    const {title, introduction, description} = state;

    // return to the component data
    return (
        <Modal handle_close={handle_close} buttons={buttons} z_index={10}>
            <div className="grid grid-cols-1 gap-y-4">
                <input
                    type="text"
                    name="title"
                    placeholder="set the title"
                    value={title}
                    onChange={event => state_handler(event)} 
                    required
                    className={`${input_style}`}
                />

                <TextEditor name="introduction" html={introduction} placeholder="set the brief" handler={event => state_handler(event)} />
                <TextEditor name="description" html={description} placeholder="set the description" handler={event => state_handler(event)} />
            </div>
        </Modal>
    );
};
export default LaboratoryModal;