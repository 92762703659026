// import the react components
import React, {useEffect, useState} from "react";

// import the custome components
import CartModal from "./../cart/cart-modal";
import Button from "./../button";
import NoViewData from "./../no-view-data";

// import the api components
import axios from "axios";
import {PRODUCT_API, PRODUCT_IMAGE_API} from "./../../assets/data/api";

// import the icons
import {BsFillCartCheckFill} from "react-icons/bs";
import {AiOutlineEye} from "react-icons/ai";
import {FaArrowAltCircleLeft} from "react-icons/fa";
import {FaArrowAltCircleRight} from "react-icons/fa";

// import the context
import {useStateContext} from "./../../context/";

// import the general data
import {token} from "./../../assets/data/general";

// the product view functional based component
const ProductView = ({id}) => {
    const {set_cart_modal, cart_modal, set_is_loading} = useStateContext();
    const [product, set_product] = useState({});
    const [image, set_image] = useState(0);

    // get the product
    useEffect(() => {
        set_is_loading(true);

        // prepare the query
        const endpoint = PRODUCT_API + "read-single.php";
        const data = {id};

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success) set_product(data.product);
            set_is_loading(false);
        });
    }, []);

    // prepare the image and colors
    const images = product.images && product.images.split(token);
    const colors = product.colors && product.colors.replace(" ", "").split(",");

    // preview image
    const preview_handler = () => {
        if (image === 0) return;
        set_image(preview => preview - 1);
    };

    // next image
    const next_handler = () => {
        if (image === images.length -1) return;
        set_image(preview => preview + 1);
    };

    // the add handler
    const add_handler = () => {
        set_cart_modal(true);
    };

    // return to the component data
    return (
        <div>

            {/* the modals */}
            {cart_modal && <CartModal product={product} colors={colors} />}

            {/* the body of product */}
            {product.id ? <section className="text-gray-700 body-font overflow-hidden">
                <div className="container px-5 py-8 mx-auto">
                    <div className="lg:w-4/5 mx-auto flex flex-wrap">

                        {/* the images gallery */}
                        <div className="lg:w-1/2 w-full h-screen relative">
                            <img className="object-contain object-center rounded h-full" alt="product" src={PRODUCT_IMAGE_API + images[image]} />
                            {images.length > 1 && <div className="absolute top-0 flex justify-between items-center w-full h-20" style={{zIndex: 5}}>
                                <span className="text-5xl cursor-pointer" onClick={preview_handler}>
                                    {image !== 0 && <FaArrowAltCircleLeft />}
                                </span>
                                <span className="text-5xl cursor-pointer" onClick={next_handler}>
                                    {image !== images.length - 1 && <FaArrowAltCircleRight />}
                                </span>
                            </div>}
                        </div>

                        {/* the body of product */}
                        <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0 flex flex-col justify-center">

                            {/* the product header */}
                            <h2 className="text-sm title-font text-gray-500 tracking-widest uppercase">{product.brand}</h2>
                            <h1 className="text-gray-900 text-3xl title-font font-semibold mb-1 capitalize dark:text-gray-200">{product.title}</h1>

                            {/* the product review section */}
                            <div className="flex mb-4">
                                <span className="flex items-center">
                                    <span className="text-gray-600 ml-3 flex gap-x-1 items-center">{product.orders} <BsFillCartCheckFill /></span>
                                    <span className="text-gray-600 ml-3 flex gap-x-1 items-center">{product.seen} <AiOutlineEye /></span>
                                </span>

                                {/* the social contacts */}
                                <span className="flex ml-3 pl-3 py-2 border-l-2 border-gray-200 dark:border-gray-700">
                                    <a className="text-gray-500" href={product.facebook ? product.facebook : "#"}>
                                        <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                                            <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                                        </svg>
                                    </a>
                                    <a className="ml-2 text-gray-500" href={product.instagram ? product.instagram : "#"}>
                                        <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                                            <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                                        </svg>
                                    </a>
                                    <a className="ml-2 text-gray-500 " href={product.whatsapp ? product.whatsapp : "#"}>
                                        <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                                            <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
                                        </svg>
                                    </a>
                                </span>
                            </div>

                            {/* the description */}
                            <p className="leading-relaxed dark:text-gray-400" dangerouslySetInnerHTML={{__html: product.brief}} />
                            <div className="flex mt-6 items-center pb-5 border-b-2 border-gray-200 mb-5 dark:border-b-gray-700">
                                <div className="mt-2 flex gap-1">
                                    {colors && colors.map(item => <span style={{background: item}} key={item} className="w-5 h-5 rounded-full" />)}
                                </div>
                            </div>

                            <div className="flex justify-between items-center">
                                <div className="flex flex-col gap-y-2">
                                    <span className="font-medium text-2xl text-gray-900 dark:text-gray-200">
                                        <span className={`${product.pack_discount && "line-through text-red-500 mr-2"}`}>{product.pack_price.toLocaleString()}$</span>
                                        {product.pack_discount && <span>{product.pack_discount.toLocaleString()}$</span>}
                                    </span>
                                    <span className="font-medium text-2xl text-gray-900 dark:text-gray-200">
                                        <span className={`${product.unit_discount && "line-through text-red-500 mr-2"}`}>{product.unit_price.toLocaleString()}$</span>
                                        {product.unit_discount && <span>{product.unit_discount.toLocaleString()}$</span>}
                                    </span>
                                </div>

                                <div className="flex gap-x-3 items-center">
                                    <Button title="add" action="button" handler={add_handler} />
                                </div>
                            </div>
                        </div>

                        {/* the description */}
                        <div className="border-t-2 border-gray-500 mt-8 pt-4 dark:border-gray-700">
                            <p className="leading-relaxed dark:text-gray-200" dangerouslySetInnerHTML={{__html: product.description}} id="blog" />
                        </div>
                    </div>
                </div>
            </section>
            :
            <NoViewData />}
        </div>
    );
};
export default ProductView;