// import the react components
import React, {useEffect} from "react";
import {BrowserRouter} from "react-router-dom";

// import the custome components
import Navigation from "./components/navigation";
import SideBar from "./components/sidebar";
import Routers from "./components/routers";
import Loading from "./components/loading";
import SettingButton from "./ui/setting-button";
import SideSetting from "./components/side-setting";

// import the context
import {useStateContext} from "./context/";
import useAuth from "./context/auth";
import ScrollToTop from "./context/scroll-to-top";

// import the styles
import "./assets/styles/app.css";

// the app functional based component
const App = () => {
    const {auth} = useAuth();
    const {active_menu, is_loading, theme_mode} = useStateContext();

    // remove the init
    useEffect(() => {
        const init = document.querySelector("#init") || null;
        init !== null && init.remove();
    }, []);

    // return to the component data
    return (
        <div className={`${theme_mode === "dark" ? "dark" : ""}`}>
            <BrowserRouter>

                {/* scroll to the top and hide boxes every page change */}
                <ScrollToTop />

                {/* the body of the application */}
                <div className="flex relative dark:bg-main-dark-bg">

                    {/* display the loading animation */}
                    {is_loading && <Loading />}

                    {/* the sidebar */}
                    {auth && <div className={`dark:bg-secondary-dark-bg transition-all delay-75 fixed bg-white shadow-lg ${active_menu ? "w-72" : "w-0"}`}>
                        <SideBar />
                    </div>}

                    {/* the body of the application */}
                    <div className={`dark:bg-main-dark-bg bg-main-bg min-h-screen w-full transition-all delay-75 ${active_menu ? "md:ml-72" : "flex-2"}`}>

                        {/* the inner navigation */}
                        {auth && <Navigation />}

                        <div className="m-5">

                            {/* the side setting */}
                            <SettingButton />
                            <SideSetting />

                            {/* the body of the route */}
                            <Routers />
                        </div>
                    </div>
                </div>
            </BrowserRouter>
        </div>
    );
};
export default App;