// import the react components
import React from "react";
import {Tooltip, Divider} from "@mui/material/";

// the tooltip functional based component
export const Tipper = ({title, children, is_arrow = true}) => {
    return (
        <Tooltip title={title} arrow={is_arrow}>
            {children}
        </Tooltip>
    );
};

// the divider functional based component
export const Divide = () => {
    return <Divider />;
};