// import the react components
import React from "react";
import {FiSettings} from "react-icons/fi";

// import the colors
import {gradient_primary} from "./../assets/data/colors";

// import the context
import {useStateContext} from "./../context/";

// the setting button functional based component
const SettingButton = () => {
    const {set_side_setting} = useStateContext();

    // return to the component data
    return (
        <div className="fixed right-4 bottom-4">
            <button
                type="button"
                className="text-3xl p-3 hover:drop-shadow-x1 hover:bg-light-gray text-white hover:opacity-80"
                style={{background: gradient_primary, borderRadius: "50%"}}
                onClick={() => set_side_setting(true)}
            >
                <FiSettings />
            </button>
        </div>
    );
};
export default SettingButton;