// import the react componetns
import React, {createContext, useContext, useState, useReducer, useEffect} from "react";

// import the dayjs components
import dayjs from "dayjs";

// initiate the context
const StateContext = createContext();

// dispatch the calendar events
const save_events_reducer = (state, {type, payload}) => {
    switch (type){
        case "add":
            return payload;

        case "push":
            return [payload, ...state];

        case "update":
            return state.map(data => data.id == payload.id ? payload : data);

        case "delete":
            return state.filter(data => data.id !== payload.id);
        
        default: throw new Error("error");
    };
};
const init_event_data = () => {
    const storage = localStorage.getItem("saved_events");
    const parse = storage ? JSON.parse(storage) : [];
    return parse;
};

// dispatch the notes
const save_notes_reducer = (state, {type, payload}) => {
    switch (type){
        case "add":
            return payload;

        case "push":
            return [payload, ...state];

        case "update":
            return state.map(data => data.id == payload.id ? payload : data);

        case "delete":
            return state.filter(data => data.id !== payload.id);
        
        default: throw new Error("error");
    };
};
const init_note_data = () => {
    const storage = localStorage.getItem("saved_notes");
    const parse = storage ? JSON.parse(storage) : [];
    return parse;
};

// dispatch the patietns
const save_patients_reducer = (state, {type, payload}) => {
    switch (type){
        case "add":
            return payload;

        case "push":
            return [payload, ...state];

        case "update":
            return state.map(data => data.id === payload.id ? payload : data);

        case "delete":
            return state.filter(data => data.id !== payload.id);
        
        default: throw new Error("error");
    };
};
const init_patient_data = () => {
    const storage = localStorage.getItem("saved_patients");
    const parse = storage ? JSON.parse(storage) : [];
    return parse;
};

// dispatch the received
const save_received_reducer = (state, {type, payload}) => {
    switch (type){
        case "add":
            return payload;

        case "push":
            return [payload, ...state];

        case "update":
            return state.map(data => data.id === payload.id ? payload : data);

        case "delete":
            return state.filter(data => data.id !== payload.id);
        
        default: throw new Error("error");
    };
};
const init_received_data = () => {
    const storage = localStorage.getItem("saved_received");
    const parse = storage ? JSON.parse(storage) : [];
    return parse;
};

// dispatch the refered
const save_refered_reducer = (state, {type, payload}) => {
    switch (type){
        case "add":
            return payload;

        case "push":
            return [payload, ...state];

        case "update":
            return state.map(data => data.id === payload.id ? payload : data);

        case "delete":
            return state.filter(data => data.id !== payload.id);
        
        default: throw new Error("error");
    };
};
const init_refered_data = () => {
    const storage = localStorage.getItem("saved_refered");
    const parse = storage ? JSON.parse(storage) : [];
    return parse;
};

// dispatch the products
const save_products_reducer = (state = [], {type, payload}) => {
    switch (type){
        case "add":
            return payload;

        case "push":
            return [payload, ...state];

        case "update":
            return state.map(data => data.id == payload.id ? payload : data);

        case "delete":
            return state.filter(data => data.id !== payload.id);
        
        default: throw new Error("error");
    };
};
const init_product_data = () => {
    const storage = localStorage.getItem("saved_products");
    const parse = storage ? JSON.parse(storage) : [];
    return parse;
};

// dispatch the worker
const save_workers_reducer = (state = [], {type, payload}) => {
    switch (type){
        case "add":
            return payload;

        case "push":
            return [payload, ...state];

        case "update":
            return state.map(data => data.id == payload.id ? payload : data);

        case "delete":
            return state.filter(data => data.id !== payload.id);
        
        default: throw new Error("error");
    };
};
const init_worker_data = () => {
    const storage = localStorage.getItem("saved_workers");
    const parse = storage ? JSON.parse(storage) : [];
    return parse;
};

// dispatch the course
const save_courses_reducer = (state = [], {type, payload}) => {
    switch (type){
        case "add":
            return payload;

        case "push":
            return [payload, ...state];

        case "update":
            return state.map(data => data.id == payload.id ? payload : data);

        case "delete":
            return state.filter(data => data.id !== payload.id);
        
        default: throw new Error("error");
    };
};
const init_course_data = () => {
    const storage = localStorage.getItem("saved_courses");
    const parse = storage ? JSON.parse(storage) : [];
    return parse;
};

// dispatch the laboratory
const save_laboratories_reducer = (state = [], {type, payload}) => {
    switch (type){
        case "add":
            return payload;

        case "push":
            return [payload, ...state];

        case "update":
            return state.map(data => data.id == payload.id ? payload : data);

        case "delete":
            return state.filter(data => data.id !== payload.id);
        
        default: throw new Error("error");
    };
};
const init_laboratory_data = () => {
    const storage = localStorage.getItem("saved_laboratories");
    const parse = storage ? JSON.parse(storage) : [];
    return parse;
};

// dispatch the book
const save_books_reducer = (state = [], {type, payload}) => {
    switch (type){
        case "add":
            return payload;

        case "push":
            return [payload, ...state];

        case "update":
            return state.map(data => data.id == payload.id ? payload : data);

        case "delete":
            return state.filter(data => data.id !== payload.id);
        
        default: throw new Error("error");
    };
};
const init_book_data = () => {
    const storage = localStorage.getItem("saved_books");
    const parse = storage ? JSON.parse(storage) : [];
    return parse;
};

// dispatch the transaction
const save_transactions_reducer = (state = [], {type, payload}) => {
    switch (type){
        case "add":
            return payload;

        case "push":
            return [payload, ...state];

        case "update":
            return state.map(data => data.id == payload.id ? payload : data);

        case "delete":
            return state.filter(data => data.id !== payload.id);
        
        default: throw new Error("error");
    };
};
const init_transaction_data = () => {
    const storage = localStorage.getItem("saved_transactions");
    const parse = storage ? JSON.parse(storage) : [];
    return parse;
};

// dispatch the notification
const save_notifications_reducer = (state = [], {type, payload}) => {
    switch (type){
        case "add":
            return payload;

        case "push":
            return [payload, ...state];

        case "update":
            return state.map(data => data.id == payload.id ? payload : data);

        case "delete":
            return state.filter(data => data.id !== payload.id);
        
        default: throw new Error("error");
    };
};
const init_notification_data = () => {
    const storage = localStorage.getItem("saved_notifications");
    const parse = storage ? JSON.parse(storage) : [];
    return parse;
};

// dispatch the order
const save_orders_reducer = (state = [], {type, payload}) => {
    switch (type){
        case "add":
            return payload;

        case "push":
            return [payload, ...state];

        case "update":
            return state.map(data => data.id == payload.id ? {...data, ...payload} : data);

        case "delete":
            return state.filter(data => data.id !== payload.id);
        
        default: throw new Error("error");
    };
};
const init_order_data = () => {
    const storage = localStorage.getItem("saved_orders");
    const parse = storage ? JSON.parse(storage) : [];
    return parse;
};

// dispatch the cart
const save_carts_reducer = (state = [], {type, payload}) => {
    switch (type){
        case "add":
            return payload;

        case "push":
            return [payload, ...state];

        case "update":
            return state.map(data => data.id == payload.id ? payload : data);

        case "delete":
            return state.filter(data => data.id !== payload.id);
        
        default: throw new Error("error");
    };
};
const init_cart_data = () => {
    const storage = localStorage.getItem("saved_carts");
    const parse = storage ? JSON.parse(storage) : [];
    return parse;
};

// dispatch the cases
const save_cases_reducer = (state = [], {type, payload}) => {
    switch (type){
        case "add":
            return payload;

        case "push":
            return [...state, payload];

        case "update":
            return state.map(data => data.id === payload.id ? payload : data);

        case "delete":
            return state.filter(data => data.id !== payload.id);
        
        default: throw new Error("error");
    };
};
const init_case_data = () => {
    const storage = localStorage.getItem("saved_cases");
    const parse = storage ? JSON.parse(storage) : [];
    return parse;
};

// dispatch the tips
const save_tips_reducer = (state = [], {type, payload}) => {
    switch (type){
        case "add":
            return payload;

        case "push":
            return [payload, ...state];

        case "update":
            return state.map(data => data.id == payload.id ? payload : data);

        case "delete":
            return state.filter(data => data.id !== payload.id);
        
        default: throw new Error("error");
    };
};
const init_tip_data = () => {
    const storage = localStorage.getItem("saved_tips");
    const parse = storage ? JSON.parse(storage) : [];
    return parse;
};

// dispatch the blogs
const save_blogs_reducer = (state, {type, payload}) => {
    switch (type){
        case "add":
            return payload;

        case "push":
            return [payload, ...state];

        case "update":
            return state.map(data => data.id == payload.id ? payload : data);

        case "delete":
            return state.filter(data => data.id !== payload.id);
        
        default: throw new Error("error");
    };
};
const init_blog_data = () => {
    const storage = localStorage.getItem("saved_blogs");
    const parse = storage ? JSON.parse(storage) : [];
    return parse;
};

// the user init data
const init_user_data = () => {
    const storage = localStorage.getItem("saved_user");
    const parse = storage ? JSON.parse(storage) : {username: "demo"};
    return parse;
};

// the theme mode init data
const init_theme_mode_data = () => {
    const storage = localStorage.getItem("theme_mode");
    const parse = storage ? storage : "light";
    return parse;
};

// the context provider
export const ContextProvider = ({children}) => {

    // the state of the menu
    const [active_menu, set_active_menu] = useState(false);

    // the state of the navigation
    const [profile, set_profile] = useState(false);

    // the state of the screen size
    const [screen_size, set_screen_size] = useState(undefined);

    // the state of components
    const [alert, set_alert] = useState(false);
    const [is_disable, set_is_disable] = useState(false);
    const [is_loading, set_is_loading] = useState(false);

    // the state of the side setting
    const [side_setting, set_side_setting] = useState(false);
    const [theme_mode, set_theme_mode] = useState(init_theme_mode_data);

    // the state of the calendar
    const [month_index, set_month_index] = useState(dayjs().month());
    const [show_event_modal, set_show_event_modal] = useState(false);
    const [show_day_modal, set_show_day_modal] = useState(false);
    const [selected_event, set_selected_event] = useState();
    const [selected_day, set_selected_day] = useState(dayjs().day());
    const [saved_events, dispatch_event] = useReducer(save_events_reducer, [], init_event_data);
    const [filter, set_filter] = useState("all");
    useEffect(() => {
        localStorage.setItem("saved_events", JSON.stringify(saved_events));
    }, [saved_events]);

    // the login state
    const [is_loged, set_is_loged] = useState(init_user_data);
    useEffect(() => {
        localStorage.setItem("saved_user", JSON.stringify(is_loged));
    }, [is_loged]);

    // the notes state
    const [saved_notes, dispatch_note] = useReducer(save_notes_reducer, [], init_note_data);
    const [note_modal, set_note_modal] = useState(false);
    const [note_filter, set_note_filter] = useState("all");
    const [selected_note, set_selected_note] = useState(null);
    useEffect(() => {
        localStorage.setItem("saved_notes", JSON.stringify(saved_notes));
    }, [saved_notes]);

    // the patients state
    const [saved_patients, dispatch_patient] = useReducer(save_patients_reducer, [], init_patient_data);
    const [patient_modal, set_patient_modal] = useState(false);
    const [selected_patient, set_selected_patient] = useState(null);
    useEffect(() => {
        localStorage.setItem("saved_patients", JSON.stringify(saved_patients));
    }, [saved_patients]);

    // the received patients
    const [saved_received, dispatch_received] = useReducer(save_received_reducer, [], init_received_data);
    useEffect(() => {
        localStorage.setItem("saved_received", JSON.stringify(saved_received));
    }, [saved_received]);

    // the refered patients
    const [saved_refered, dispatch_refered] = useReducer(save_refered_reducer, [], init_refered_data);
    const [refer_modal, set_refer_modal] = useState(false);
    const [selected_refer, set_selected_refer] = useState(null);
    useEffect(() => {
        localStorage.setItem("saved_refered", JSON.stringify(saved_refered));
    }, [saved_refered]);

    // the notifications state
    const [saved_notifications, dispatch_notification] = useReducer(save_notifications_reducer, [], init_notification_data);
    useEffect(() => {
        localStorage.setItem("saved_notifications", JSON.stringify(saved_notifications));
    }, [saved_notifications]);

    // the orders state
    const [saved_orders, dispatch_order] = useReducer(save_orders_reducer, [], init_order_data);
    const [order_modal, set_order_modal] = useState(false);
    const [selected_order, set_selected_order] = useState(null);
    useEffect(() => {
        localStorage.setItem("saved_orders", JSON.stringify(saved_orders));
    }, [saved_orders]);

    // the cart state
    const [saved_carts, dispatch_cart] = useReducer(save_carts_reducer, [], init_cart_data);
    const [cart_modal, set_cart_modal] = useState(false);
    const [selected_cart, set_selected_cart] = useState(null);
    useEffect(() => {
        localStorage.setItem("saved_carts", JSON.stringify(saved_carts));
    }, [saved_carts]);

    // the products state
    const [saved_products, dispatch_product] = useReducer(save_products_reducer, [], init_product_data);
    const [product_modal, set_product_modal] = useState(false);
    const [selected_product, set_selected_product] = useState(null);
    const [product_category, set_product_category] = useState("all");
    useEffect(() => {
        localStorage.setItem("saved_products", JSON.stringify(saved_products));
    }, [saved_products]);

    // the workers state
    const [saved_workers, dispatch_worker] = useReducer(save_workers_reducer, [], init_worker_data);
    const [worker_modal, set_worker_modal] = useState(false);
    const [worker_filter, set_worker_filter] = useState("all");
    useEffect(() => {
        localStorage.setItem("saved_workers", JSON.stringify(saved_workers));
    }, [saved_workers]);

    // the courses state
    const [saved_courses, dispatch_course] = useReducer(save_courses_reducer, [], init_course_data);
    const [course_modal, set_course_modal] = useState(false);
    const [selected_course, set_selected_course] = useState(false);
    const [course_filter, set_course_filter] = useState("all");
    useEffect(() => {
        localStorage.setItem("saved_courses", JSON.stringify(saved_courses));
    }, [saved_courses]);

    // the laboratories state
    const [saved_laboratories, dispatch_laboratory] = useReducer(save_laboratories_reducer, [], init_laboratory_data);
    const [laboratory_modal, set_laboratory_modal] = useState(false);
    const [selected_laboratory, set_selected_laboratory] = useState(false);
    useEffect(() => {
        localStorage.setItem("saved_laboratories", JSON.stringify(saved_laboratories));
    }, [saved_laboratories]);

    // the books state
    const [saved_books, dispatch_book] = useReducer(save_books_reducer, [], init_book_data);
    const [book_filter, set_book_filter] = useState("all");
    const [book_modal, set_book_modal] = useState(false);
    useEffect(() => {
        localStorage.setItem("saved_books", JSON.stringify(saved_books));
    }, [saved_books]);

    // the transactions state
    const [saved_transactions, dispatch_transaction] = useReducer(save_transactions_reducer, [], init_transaction_data);
    useEffect(() => {
        localStorage.setItem("saved_transactions", JSON.stringify(saved_transactions));
    }, [saved_transactions]);

    // the cases state
    const [saved_cases, dispatch_case] = useReducer(save_cases_reducer, [], init_case_data);
    const [case_modal, set_case_modal] = useState(false);
    const [selected_case, set_selected_case] = useState(false);
    useEffect(() => {
        localStorage.setItem("saved_cases", JSON.stringify(saved_cases));
    }, [saved_cases]);

    // the tips state
    const [saved_tips, dispatch_tip] = useReducer(save_tips_reducer, [], init_tip_data);
    const [tip_modal, set_tip_modal] = useState(false);
    const [selected_tip, set_selected_tip] = useState(null);
    useEffect(() => {
        localStorage.setItem("saved_tips", JSON.stringify(saved_tips));
    }, [saved_tips]);

    // the blogs state
    const [saved_blogs, dispatch_blog] = useReducer(save_blogs_reducer, [], init_blog_data);
    const [blog_modal, set_blog_modal] = useState(false);
    const [selected_blog, set_selected_blog] = useState(null);
    useEffect(() => {
        localStorage.setItem("saved_blogs", JSON.stringify(saved_blogs));
    }, [saved_blogs]);

    // return to the context data
    return (
        <StateContext.Provider
            value={{
                active_menu, set_active_menu,
                screen_size, set_screen_size,
                alert, set_alert,
                is_disable, set_is_disable,
                is_loading, set_is_loading,
                side_setting, set_side_setting,
                theme_mode, set_theme_mode,
                profile, set_profile,
                month_index, set_month_index,
                show_event_modal, set_show_event_modal,
                show_day_modal, set_show_day_modal,
                selected_day, set_selected_day,
                saved_events, dispatch_event,
                selected_event, set_selected_event,
                filter, set_filter,
                is_loged, set_is_loged,
                saved_notes, dispatch_note,
                note_modal, set_note_modal,
                selected_note, set_selected_note,
                note_filter, set_note_filter,
                saved_patients, dispatch_patient,
                patient_modal, set_patient_modal,
                selected_patient, set_selected_patient,
                saved_received, dispatch_received,
                saved_refered, dispatch_refered,
                refer_modal, set_refer_modal,
                selected_refer, set_selected_refer,
                saved_notifications, dispatch_notification,
                saved_orders, dispatch_order,
                order_modal, set_order_modal,
                selected_order, set_selected_order,
                saved_carts, dispatch_cart,
                saved_products, dispatch_product,
                product_modal, set_product_modal,
                saved_workers, dispatch_worker,
                worker_modal, set_worker_modal,
                worker_filter, set_worker_filter,
                saved_courses, dispatch_course,
                course_modal, set_course_modal,
                selected_course, set_selected_course,
                course_filter, set_course_filter,
                saved_laboratories, dispatch_laboratory,
                laboratory_modal, set_laboratory_modal,
                selected_laboratory, set_selected_laboratory,
                saved_books, dispatch_book,
                book_filter, set_book_filter,
                book_modal, set_book_modal,
                saved_transactions, dispatch_transaction,
                cart_modal, set_cart_modal,
                selected_product, set_selected_product,
                selected_cart, set_selected_cart,
                product_category, set_product_category,
                saved_cases, dispatch_case,
                case_modal, set_case_modal,
                selected_case, set_selected_case,
                saved_tips, dispatch_tip,
                tip_modal, set_tip_modal,
                selected_tip, set_selected_tip,
                saved_blogs, dispatch_blog,
                blog_modal, set_blog_modal,
                selected_blog, set_selected_blog,
            }}
        >
            {children}
        </StateContext.Provider>
    );
};

export const useStateContext = () => useContext(StateContext);