// import the react components
import React, {useEffect, useState} from "react";

// import the custome components
import PatientModal from "./../ui/patient/patient-modal";
import Title from "./../ui/title";
import Table from "./../components/table";
import TableBody from "./../components/table-body";
import PatientItem from "./../ui/patient/patient-item";

// import the icons
import {GoPerson} from "react-icons/go";

// import the api components
import axios from "axios";
import {PATIENT_API} from "./../assets/data/api";

// import the context
import {useStateContext} from "./../context/";
import useNavigation from "./../context/navigation";

// the clinical functional based component
const Clinic = () => {
    const {is_loged, saved_patients, dispatch_patient, patient_modal, set_patient_modal, set_is_loading} = useStateContext();
    const [page, set_page] = useState(1);
    useNavigation();

    // get the patient data from the server
    useEffect(() => {
        saved_patients.length === 0 && set_is_loading(true);

        // prepare the query
        const endpoint = PATIENT_API + "read.php";
        const data = {username: is_loged.username};

        // send the query
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success) dispatch_patient({type: "add", payload: data.patients});
            set_is_loading(false);
        });
    }, []);

    // the header of the table
    const header = ["id", "name", "code", "next session", "", ""];

    // return to the component data
    return (
        <div>

            {/* the modal */}
            {patient_modal && <PatientModal />}

            {/* the title of the webpage */}
            <Title text="clinic patients" icon={<GoPerson />} />

            {/* the data */}
            <Table paragraph="This table shows the list of the patients that visisted your clinic." button_title="add" button_handler={() => set_patient_modal(true)} header={header} body={saved_patients} page={page} set_page={set_page} has_print={true}>
                <TableBody data={saved_patients} page={page} Component={PatientItem} />
            </Table>
        </div>
    );
};
export default Clinic;