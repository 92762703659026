// import the react components
import React, {useEffect, useState} from "react";

// import the custome components
import LaboratoryModal from "./../ui/laboratory/laboratory-modal";
import Title from "./../ui/title";
import SearchBar from "./../ui/search-bar";
import Button from "./../ui/button";
import ListWrapper from "./../ui/list-wrapper";
import LaboratoryItem from "./../ui/laboratory/laboratory-item";
import NoData from "./../ui/no-data";

// import the icons
import {ImLab} from "react-icons/im";

// import the api components
import axios from "axios";
import {LABORATORY_API} from "./../assets/data/api";

// import the context
import {useStateContext} from "./../context/";
import useNavigation from "./../context/navigation";

// the laboratories functional based component
const Laboratories = () => {
    const {laboratory_modal, set_laboratory_modal, saved_laboratories, dispatch_laboratory, set_is_loading} = useStateContext();
    const [search, set_search] = useState("");
    useNavigation();

    // get the laboratories
    useEffect(() => {
        saved_laboratories.length === 0 && set_is_loading(true);

        // prepare the query
        const endpoint = LABORATORY_API + "read.php";

        // send the query
        axios.post(endpoint).then(response => {
            const {data} = response;
            if (data.success) dispatch_laboratory({type: "add", payload: data.laboratories});
            set_is_loading(false);
        });
    }, []);

    // return to the component data
    return (
        <div>

            {/* the modals */}
            {laboratory_modal && <LaboratoryModal />}

            {/* the title of the webpage */}
            <Title text="laboratories" icon={<ImLab />} />

            {/* the header of the webpage */}
            <div className="flex justify-between items-center">
                <SearchBar placeholder="search laboratories" value={search} handler={value => set_search(value)} />
                <Button action="button" title="add" handler={() => set_laboratory_modal(true)} />
            </div>

            {/* the laboratories */}
            {saved_laboratories.length > 0 ? <ListWrapper grid="lg:grid-cols-2">
                {saved_laboratories.map(laboratory => <LaboratoryItem key={laboratory.id} laboratory={laboratory} />)}
            </ListWrapper>
            :
            <NoData message="No laboratories are currently available." />}
        </div>
    );
};
export default Laboratories;