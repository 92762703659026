// import the react components
import React from "react";

// import the loading components
import ReactLoading from "react-loading";

// the loading functional based component
const Loading = () => {

    // return to the component data
    return (
        <div className="fixed w-full bg-gray-100 bg-opacity-60 h-screen">
            <div className="mx-auto h-full flex justify-center items-center">
                <ReactLoading type="cylon" width={200} height={200} color="#b66dff" delay={300} />
            </div>
        </div>
    );
};
export default Loading;