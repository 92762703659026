// import the react components
import React from "react";

// import the cutome components
import OuterHeader from "./../ui/outer-header";
import Footer from "./../components/footer";

// import the context
import useAuth from "./../context/auth";

// import the general data
import {update_date, company_name, contact_mail} from "./../assets/data/general";

// the privacy functional based component
const Privacy = () => {
    const {auth} = useAuth();

    // the styles
    const p_style = "leading-9 dark:text-gray-200";
    const h2_style = "text-2xl font-bold mt-4 dark:text-gray-200";
    const ul_style = "list-disc ml-8 leading-8 dark:text-gray-200";
    const strong_style = "dark:text-gray-200";

    // return to the component data
    return (
        <div>

            {/* the header */}
            {!auth && <OuterHeader />}

            {/* the body of the privacy */}
            <div className="mt-10">
                <div className="flex flex-col gap-y-3">
                    <h1 className="text-5xl capitalize dark:text-gray-200">privacy policy</h1>
                    <h3 className="text-sm text-gray-600 font-bold capitalize">last update: {update_date}</h3>
                    <p className={p_style}>This privacy notice for {company_name} describes how and why we might collect, store, use, and share your information when you use our services. <br /> <strong className={strong_style}>Questions or concerns?</strong> Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our services. If you still have any questions or concerns, please contact us at <strong className={strong_style}>{contact_mail}</strong>.</p>
                    <h3 className="text-xl font-extrabold dark:text-gray-200">table of contents:</h3>
                    <ul className={`${ul_style} list-decimal underline underline-offset-4 text-blue-gray-700`}>
                        <li>what information do we collect</li>
                        <li>how do we process your information</li>
                        <li>when and with whom we share your information</li>
                        <li>how long do we keep your information</li>
                        <li>how do we keep your information safe</li>
                        <li>do we collect information from minors</li>
                        <li>what are your privacy rights</li>
                        <li>do we make updates to this notice</li>
                    </ul>
                </div>

                <div className="flex flex-col gap-y-2">
                    <h2 className={h2_style}>1. what information do we collect</h2>
                    <p className={p_style}>We collect personal information that you provide to us. We collect personal information that you voluntarily provide to us when you register on the services, express an interest in obtaining information about us or our products and services, when you participate in activities on the services, or otherwise when you contact us. <br /> The personal information that we collect depends on the context of your interactions with us and the services, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>
                    <ul className={ul_style}>
                        <li>Username</li>
                        <li>Email address</li>
                        <li>Phone number</li>
                        <li>Password</li>
                    </ul>
                    <p className={p_style}><strong className={strong_style}>Sensitive information:</strong> We do not process sensitive information. <br /> All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>

                    <h2 className={h2_style}>2. how do we process your information</h2>
                    <p className={p_style}>We process your information to provide, improve, and administer our services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. <br /> We process your personal information for a variety of reasons, depending on how you interact with our services, including:</p>
                    <ul className={ul_style}>
                        <li>To facilitate account creation and authentication and otherwise manage user accounts. We may process your information so you can create and log in to your account, as well as keep your account in working order.</li>
                    </ul>

                    <h2 className={h2_style}>3. when and with whom we share your information</h2>
                    <p className={p_style}>We will never share any piece of your information to any third parties or business companies. All your data are <strong className={strong_style}>end-to-end encrypted</strong> unless you share it to other dentists. The information that are completely encrypted (even you can't share it):</p>
                    <ul className={ul_style}>
                        <li>Schedule.</li>
                        <li>Statistics.</li>
                        <li>Notes.</li>
                        <li>Notifications</li>
                        <li>Carts</li>
                        <li>Contacts</li>
                    </ul>
                    <p className={p_style}>The information that you are capable to share:</p>
                    <ul className={ul_style}>
                        <li>Patient's information: if you refer to another dentist (only both of you will see the information).</li>
                        <li>Products.</li>
                        <li>Laboratories.</li>
                        <li>Courses</li>
                        <li>Cases: if you allow to be shared.</li>
                        <li>Tips.</li>
                        <li>Blogs.</li>
                    </ul>
                    <p className={p_style}><strong className={strong_style}>the end-to-end encryption means even we can't view your data from our end.</strong></p>

                    <h2 className={h2_style}>4. how long do we keep your information</h2>
                    <p className={p_style}>We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law. <br /> We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as accounting or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us. <br /> When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>

                    <h2 className={h2_style}>5. how do we keep your information safe</h2>
                    <p className={p_style}>We aim to protect your personal information through a system of organizational and technical security measures. <br /> We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</p>

                    <h2 className={h2_style}>6. do we collect information from minors</h2>
                    <p className={p_style}>We do not knowingly collect data from or market to children under 18 years of age. <br /> We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us.</p>
                
                    <h2 className={h2_style}>7. what are your privacy rights</h2>
                    <p className={p_style}>You may review, change, or terminate your account at any time. <br /> Withdrawing your consent: If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section. <br /> However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent. If you would at any time like to review or change the information in your account or terminate your account, you can:</p>
                    <ul className={ul_style}>
                        <li>Log in to your account settings and update your user account.</li>
                        <li>Contact us using the contact information provided.</li>
                    </ul>
                    <p className={p_style}>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</p>

                    <h2 className={h2_style}>8. do we make updates to this notice</h2>
                    <p className={p_style}>We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</p>
                </div>
            </div>

            {/* the footer */}
            <Footer />
        </div>
    );
};
export default Privacy;