// import the react components
import React from "react";
import {Link} from "react-router-dom";

// import the api components
import {BOOK_IMAGE_API} from "./../../assets/data/api";

// the book item functional based component
const BookItem = ({item}) => {

    // return to the component data
    return (
        <div className="max-w-screen-md mx-auto">
            <Link to={`/view/book/${item.id}`} className="flex gap-3 justify-center items-center cursor-pointer hover:opacity-80">
                <div>
                    <img className="h-64 object-contain" src={BOOK_IMAGE_API + item.cover} />
                </div>

                <div className="w-1/2 grow">
                    <h1 className="text-3xl font-bold text-gray-900 dark:text-gray-200 capitalize">{item.title}</h1>
                    <h2 className="text-md text-gray-700 dark:text-gray-400 my-1">{item.author}</h2>
                    <h2 className="font-bold text-md dark:text-gray-200">
                        <span className={item.discount && "line-through text-red-500 mr-2"}>{item.price}$</span>
                        {item.discount && <span>{item.discount}$</span>}
                    </h2>
                    <p className="mt-3 text-gray-900 dark:text-gray-200 leading-relaxed">{item.introduction}</p>
                </div>
            </Link>

            <hr className="my-5 dark:border-gray-700" />
        </div>
    );
};
export default BookItem;