// import the react components
import React from "react";

// import the image
import image from "./../assets/images/no-data.svg";

// the no view data functional based component
const NoViewData = () => {

    // return to the component data
    return (
        <div className="flex flex-col justify-center items-center mt-10">
            <img src={image} alt="no data available" className="w-80" />
            <h1 className="mt-8 text-2xl font-medium dark:text-gray-200">There is no data available.</h1>
        </div>
    );
};
export default NoViewData;