// the general colors of the application
export const blue = "#5E50F9";
export const indigo = "#6610f2";
export const purple = "#6a008a";
export const pink = "#E91E63";
export const red = "#f96868";
export const orange = "#f2a654";
export const yellow = "#f6e84e";
export const green = "#46c35f";
export const teal = "#58d8a3";
export const cyan = "#57c7d4";
export const white_smoke = "#f2f7f8";
export const violet = "#41478a";
export const darkslategray = "#2e383e";
export const dodger_blue = "#3498db";

// the important colors of the application
export const primary = "#b66dff";
export const secondary = "#c3bdbd";
export const success = "#1bcfb4";
export const info = "#198ae3";
export const warning = "#fed713";
export const danger = "#fe7c96";
export const light = "#f8f9fa";
export const dark = "#3e4b5b";

// the gradient colors of the application
export const gradient_primary = "linear-gradient(to right, #da8cff, #9a55ff)";
export const gradient_secondary = "linear-gradient(to right, #e7ebf0, #868e96)";
export const gradient_success = "linear-gradient(to right, #84d9d2, #07cdae)";
export const gradient_info = "linear-gradient(to right, #90caf9, #047edf 99%)";
export const gradient_warning = "linear-gradient(to right, #f6e384, #ffd500)";
export const gradient_danger = "linear-gradient(to right, #ffbf96, #fe7096)";
export const gradient_light = "linear-gradient(to bottom, #f4f4f4, #e4e4e9)";
export const gradient_dark = "linear-gradient(89deg, #5e7188, #3e4b5b)";

// the label colors
export const labels_colors = ["#7986CB", "#E0E0E0", "#81C784", "#4DD0E1", "#E57373", "#BA68C8"];