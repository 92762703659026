// import the react components
import React from "react";
import {useNavigate} from "react-router-dom";

// import the custome components
import Modal from "./../modal";

// import the styles
import {tr_style, td_style} from "./../../assets/data/style";

// import the api components
import axios from "axios";
import {ORDER_API} from "./../../assets/data/api";

// import the context
import {useStateContext} from "./../../context/";

// the order modal functional based component
const OrderModal = () => {
    const {set_order_modal, selected_order, set_selected_order, dispatch_order, set_alert} = useStateContext();

    // the navigate
    const navigate = useNavigate();

    // close the modal
    const handle_close = () => {
        set_order_modal(false);
        set_selected_order(null);
    };

    // handle the update
    const handle_submit = type => {

        // prepare the query
        const endpoint = ORDER_API + "update.php";
        const data = {
            id: selected_order.id,
            product: selected_order.product,
            username: selected_order.username,
            dentist: selected_order.dentist,
            status: type,
            price: get_price(),
        };

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success){
                dispatch_order({type: "update", payload: data.order});
                set_alert({type: "success", text: "the order updated successfully"});
                handle_close();
            }

            // if the error occured
            else if (data.error) set_alert({type: "error", text: data.error});
        })
    };

    // get the price
    const get_price = () => {
        const {type, count, pack_price, unit_price, pack_discount, unit_discount} = selected_order;
        let type_price = 0;
        if (type === "pack") type_price = pack_discount ? pack_discount : pack_price;
        else type_price = unit_discount ? unit_discount : unit_price;
        return Number(count) * Number(type_price);
    };

    // the footer buttons
    const buttons = [
        {
            title: "view",
            handler: () => navigate(`/view/product/${selected_order.product}`),
        },
    ];
    if (selected_order.status === "progressing"){
        buttons.unshift(
            {
                title: "approve",
                handler: () => handle_submit("approve"),
            },
            {
                title: "reject",
                handler: () => handle_submit("reject"),
            },
        );
    }

    // extract the data
    const {type, color, count, note} = selected_order;
    const order_rows = [
        ["type", type],
        ["color", color],
        ["number", count],
        ["note", note],
        ["total price", get_price() + "$"],
    ];

    // return to the component data
    return (
        <Modal handle_close={handle_close} buttons={buttons} z_index={10}>
            <div className="flex justify-center items-center w-full">
                <table className="w-3/4 mx-auto">
                    <tbody>
                        {order_rows.map(item => <tr className={tr_style} key={item[0] + item[1]}>
                            {item.map(data => <td className={td_style + " dark:text-gray-200"} key={data}>{data}</td>)}
                        </tr>)}
                    </tbody>
                </table>
            </div>
        </Modal>
    );
};
export default OrderModal;