// import the react components
import React from "react";

// import the custome components
import OuterHeader from "./../ui/outer-header";
import Footer from "./../components/footer";

// import the context
import useAuth from "./../context/auth";

// the guided functional based component
const Guides = () => {
    const {auth} = useAuth();

    // the guides data
    let guides = [];

    // the outside guides
    const outside_guides = [
        {
            title: "login",
            description: "type your username and password to login into your dashboard. once the typed data are correct, you will be redirected to the autentication page to verify that you are trying to login not someone else.",
            images: [require("./../assets/images/guides/login.png")],
        },
        {
            title: "logup",
            description: "the following fields must be filled to create a dashboard; username must be between 3-30 characters, email adress will be used for authentication purpose (please enter the right email address), phone number will be used for communication (the dashboard will be activated by phone number), chose platform based on your purpose either dashaboard or dashboard and dental site, set a password longer than 7 characters. once the data are correct you will be redirected to the verification page to verify the authority of entered email address. following the email verification you must wait untill we activate your dashboard. once the dashboard activated we will inform you via phone number.",
            images: [require("./../assets/images/guides/logup.png")],
        },
        {
            title: "forgot",
            description: "to reset your forgotten password you must go through three steps; first enter your account email address, a code will be sent to you via email. then you will enter the code, if you didn't receive the code you can resend the code once a minute. finally set the new password which must be longer than 7 characters.",
            images: [require("./../assets/images/guides/forgot-one.png"), require("./../assets/images/guides/forgot-two.png"), require("./../assets/images/guides/forgot-three.png")],
        },
        {
            title: "authentication",
            description: "if you are on authentication page then a code must be sent to you via email, enter the code and click verify.",
            images: [require("./../assets/images/guides/auth.png")],
        },
    ];

    // the inside guides
    const inside_guides = [];

    // the outside guides
    guides = auth ? inside_guides : outside_guides;

    // return to the component data
    return (
        <div>

            {/* the header */}
            {!auth && <OuterHeader />}

            {/* the body of guides */}
            <div className="mt-10">
                <h1 className="text-4xl capitalize dark:text-gray-200">guides</h1>
                <ul className="list-disc mt-2 ml-8 capitalize text-blue-gray-600 dark:text-gray-500">
                    {guides.map(item => <li key={item.title}><a href={`#${item.title}`}>{item.title}</a></li>)}
                </ul>

                {guides.map(item => <div key={item.title} className="mt-5 flex flex-col">
                    <h2 className="text-3xl font-bold capitalize dark:text-gray-200">{item.title}</h2>
                    <p className="leading-loose mt-1 dark:text-gray-200">{item.description}</p>
                    {item.images.map((img, index) => <img className="my-4 w-full" alt="guide" src={img} key={index} />)}
                    <hr className="border-gray-500 dark:border-gray-700" />
                </div>)}
            </div>

            {/* the footer */}
            <Footer />
        </div>
    );
};
export default Guides;