// import the react components
import React from "react";
import {useNavigate} from "react-router-dom";

// import the context
import {useStateContext} from "./../../context/";

// the blog item functional based component
const BlogItem = ({item}) => {
    const {is_loged, set_blog_modal, set_selected_blog} = useStateContext();
    const navigate = useNavigate();

    // handle the click
    const handle_click = () => {

        // show the update and delete buttons
        if (is_loged.username === item.username){
            set_blog_modal(true);
            set_selected_blog(item);
        }

        // view the data
        else {
            navigate(`/view/blog/${item.id}`);
        };
    };

    // return to the component data
    return (
        <div className="relative bg-gray-50 dark:bg-secondary-dark-bg p-5 rounded-md shadow-md hover:opacity-80 cursor-pointer max-h-96" onClick={handle_click}>
            <h1 className="text-2xl font-bold dark:text-gray-200">{item.title}</h1>
            <p className="mt-1 text-md text-gray-700 dark:text-gray-300 leading-6" dangerouslySetInnerHTML={{__html: item.introduction}} />
            <p className="mt-3 text-sm font-bold text-primary">Dr. {item.username}</p>
        </div>
    );
};
export default BlogItem;