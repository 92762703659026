// import the react components
import React, {useEffect, useState} from "react";

// import the custome components
import WorkerModal from "./../ui/worker/worker-modal";
import Title from "./../ui/title";
import SearchBar from "./../ui/search-bar";
import Button from "./../ui/button";
import FilterHeader from "./../ui/filter-header";
import ListWrapper from "./../ui/list-wrapper";
import WorkerItem from "./../ui/worker/worker-item";
import NoData from "./../ui/no-data";

// import the icons
import {BsFillPersonVcardFill} from "react-icons/bs";

// import the api components
import axios from "axios";
import {WORKER_API} from "./../assets/data/api";

// import the worker categories
import {workers_category} from "./../assets/data/workers";

// import the context
import {useStateContext} from "./../context/";
import useNavigation from "./../context/navigation";

// the workers functional based component
const Workers = () => {
    const {saved_workers, dispatch_worker, worker_modal, set_worker_modal, worker_filter, set_worker_filter, set_is_loading} = useStateContext();
    const [search, set_search] = useState("");
    useNavigation();

    // get the workers
    useEffect(() => {
        saved_workers.length === 0 && set_is_loading(true);

        // prepare the query
        const endpoint = WORKER_API + "read.php";

        // send the query to the server
        axios.post(endpoint).then(response => {
            const {data} = response;
            if (data.success) dispatch_worker({type: "add", payload: data.workers});
            set_is_loading(false);
        });
    }, []);

    // the request handler
    const request_handler = () => {
        set_worker_modal(true);
    };

    // return to the component data
    return (
        <div>

            {/* the modals */}
            {worker_modal && <WorkerModal />}

            {/* the title of the webpage */}
            <Title text="workers" icon={<BsFillPersonVcardFill />} />

            {/* the header of the webpage */}
            <div className="flex justify-between items-center">
                <SearchBar placeholder="search worker" value={search} handler={value => set_search(value)} />
                <Button title="request" action="button" handler={request_handler} />
            </div>

            {/* the worker categories */}
            <FilterHeader data={workers_category} active={worker_filter} handler={item => set_worker_filter(item)} />

            {/* the workers list */}
            {saved_workers.length > 0 ? <ListWrapper grid="lg:grid-cols-4 md:grid-cols-2 grid-cols-1">
                {saved_workers.map(item => (worker_filter === "all" || worker_filter === item.category) && <WorkerItem key={item.id} item={item} />)}
            </ListWrapper>
            :
            <NoData message="No workers are available, request a worker." />}
        </div>
    );
};
export default Workers;