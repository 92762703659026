// import the react components
import React from "react";
import {useLocation, useNavigate} from "react-router-dom";

// import the custome components
import Title from "./../ui/title";
import NoteView from "./../ui/note/note-view";
import PatientView from "./../ui/patient/patient-view";
import ProductView from "./../ui/product/product-view";
import CourseView from "./../ui/course/course-view";
import LaboratoryView from "./../ui/laboratory/laboratory-view";
import BookView from "./../ui/book/book-view";
import TransactionView from "./../ui/transaction/transaction-view";
import CaseView from "./../ui/case/case-view";
import BlogView from "./../ui/blog/blog-view";

// import the icons
import {BiArrowBack} from "react-icons/bi";

// import the context
import useNavigation from "./../context/navigation";

// the view functional based component
const View = () => {
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const explode = pathname.split("/");
    const from = explode[2];
    const id = explode[3]
    useNavigation();

    // return to the component data
    return (
        <div>

            {/* go back */}
            <div className="max-w-max cursor-pointer" onClick={() => navigate(-1)}>
                <Title text="go back" icon={<BiArrowBack />} />
            </div>

            {/* the body of the view */}
            {from === "note" && <NoteView id={id} />}
            {from === "patient" && <PatientView id={id} />}
            {from === "product" && <ProductView id={id} />}
            {from === "course" && <CourseView id={id} />}
            {from === "laboratory" && <LaboratoryView id={id} />}
            {from === "book" && <BookView id={id} />}
            {from === "transaction" && <TransactionView id={id} />}
            {from === "case" && <CaseView id={id} />}
            {from === "blog" && <BlogView id={id} />}
        </div>
    );
};
export default View;