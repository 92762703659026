// import the dayjs components
import dayjs from "dayjs";

// get the current month of the year
export const get_month = (month = dayjs().month()) => {
    const year = dayjs().year();
    const first_day_of_month = dayjs(new Date(year, month, 1)).day();
    let current_month_count = 0 - first_day_of_month;
    const days_matrix = new Array(5).fill([]).map(() => {
        return new Array(7).fill(null).map(() => {
            current_month_count++;
            return dayjs(new Date(year, month, current_month_count));
        })
    });
    return days_matrix;
};