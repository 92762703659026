// import the react
import React from "react";

// the list wrapper functional based component
const ListWrapper = ({children, grid = "lg:grid-cols-3"}) => {

    // return to the component data
    return (
        <div className="bg-white dark:bg-main-dark-bg">
            <div className="mx-auto max-w-2xl px-4 py-4 lg:max-w-7xl">
                <div className={`mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 ${grid} xl:gap-x-8`}>
                    {children}
                </div>
            </div>
        </div>
    );
};
export default ListWrapper;