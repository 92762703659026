// import the react components
import React, {useRef} from "react";

// import the custome components
import Table from "./../../components/table";
import Print from "./../../components/print";

// import the styles
import {tr_style, td_style} from "./../../assets/data/style";

// the patient data treatment functional based component
const PatientDataTreatment = ({heading, data}) => {
    const reference = useRef();

    // prepare the total fees
    const total_fees = () => {
        let total = 0;
        data.forEach(item => {
            const fees = item.fees;
            const clear_fees = Number(fees.replace("$", ""));
            total += clear_fees;
        });
        return total.toLocaleString() + "$";
    };

    // the headers of the table
    const headers = ["id", "problem", "treatment option", "fees", "date"];

    // the footer of the table
    const footer = ["total fees", total_fees()];

    // return to the component data
    return (
        <div className="mb-10">
            <div ref={reference}>
                <h1 className="text-4xl mb-6 pb-4 border-b-2 border-gray-900 dark:text-gray-200 dark:border-gray-700">{heading}</h1>
                <Table header={headers} body={data} footer={footer} is_search={false}>
                    <tbody>
                        {data.map((item, index) => <tr className={tr_style} key={index}>
                            <td className={td_style}>{index + 1}</td>
                            <td className={td_style}>{item.problem}</td>
                            <td className={td_style}>{item.treatment_option}</td>
                            <td className={td_style}>{item.fees}$</td>
                            <td className={td_style}>{item.date}</td>
                        </tr>)}
                    </tbody>
                </Table>
            </div>
            <Print reference={reference} />
        </div>
    );
};
export default PatientDataTreatment;