// import the react components
import React, {useRef} from "react";

// import the custome components
import Print from "./../../components/print";

// import the api components
import {XRAYS_API, CASE_IMAGES_API} from "./../../assets/data/api";

// the patient data images functional based component
const PatientDataImages = ({heading, data}) => {
    const reference = useRef(null);

    // return to the component data
    return (
        <div className="mb-10">
            <div ref={reference}>
                <h1 className="text-4xl mb-6 pb-4 border-b-2 border-gray-900 dark:text-gray-200 dark:border-b-gray-700">{heading}</h1>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4">
                    {data.length > 0 && data.map(image => <div key={image}>
                        <img src={heading === "xray images" ? XRAYS_API + image : CASE_IMAGES_API + image} className="w-full h-96 object-contain" />
                    </div>)}
                </div>
            </div>
            <Print reference={reference} />
        </div>
    );
};
export default PatientDataImages;