// import the react components
import React, {useEffect, useState} from "react";

// import the custome components
import Card from "./../card";
import PatientData from "./../patient/patient-data";
import PatientDataImages from "./../patient/patient-data-images";
import NoViewData from "./../no-view-data";

// import the api components
import axios from "axios";
import {CASE_API} from "./../../assets/data/api";

// import the context
import {useStateContext} from "./../../context/";

// the case view functional based component
const CaseView = ({id}) => {
    const {set_is_loading} = useStateContext();
    const [case_data, set_case_data] = useState({});

    // get the case data
    useEffect(() => {
        set_is_loading(true);

        // prepare the query
        const endpoint = CASE_API + "read-single.php";
        const data = {id};

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success) set_case_data(data.case);
            set_is_loading(false);
        });
    }, []);

    // return to the component data
    return (
        <div>
            {case_data.id ? <div>
                <div className="mt-8 flex flex-col gap-5 dark:text-gray-200">
                    <Card>
                        <h1 className="text-4xl font-bold mb-4">{case_data.title}</h1>
                        <p dangerouslySetInnerHTML={{__html: case_data.description}} className="leading-loose" id="blog" />
                    </Card>

                    {/* the patient's data */}
                    <PatientData heading="anamnesis" data={case_data.anamnesis} cols="grid-cols-1" do_print={false} />
                    <PatientData heading="compromised patient" data={case_data.compromised_patient} cols="lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1" do_print={false} />

                    {/* the patient's images */}
                    <PatientDataImages heading="xray images" data={case_data.xrays} />
                    <PatientDataImages heading="case images" data={case_data.cases} />
                </div>
            </div>
            :
            <NoViewData />}
        </div>
    );
};
export default CaseView;