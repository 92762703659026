// import the react components
import React, {useEffect, useState, useRef} from "react";

// import the custome component
import Table from "./../../components/table";
import Print from "./../../components/print";
import NoViewData from "./../no-view-data";

// import the api components
import axios from "axios";
import {TRANSACTION_API} from "./../../assets/data/api";

// import the styles
import {tr_style, td_style} from "./../../assets/data/style";

// import the context
import {useStateContext} from "./../../context/";

// the transaction view functional based component
const TransactionView = ({id}) => {
    const {set_is_loading, is_loged} = useStateContext();
    const [transaction, set_transaction] = useState({});
    const reference = useRef(null);

    // get the transaction
    useEffect(() => {
        set_is_loading(true);

        // prepare the query
        const endpoint = TRANSACTION_API + "read-single.php";
        const data = {id, username: is_loged.username};

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success) set_transaction(data.transaction);
            set_is_loading(false);
        });
    }, []);

    // the headers of the transaction
    const headers = ["id", "title", "price", "brief"];

    // return to the component data
    return (
        <div>
            {transaction.id ? <div>

                {/* the transaction */}
                <div ref={reference}>

                    {/* the header of transaction */}
                    <div className="mt-10">
                        <h1 className="capitalize text-4xl dark:text-gray-200">transaction report</h1>
                    </div>

                    {/* the list of items */}
                    <div className="m-5">
                        <Table header={headers} body={transaction.transaction} is_search={false} paragraph={`Transaction was from ${transaction.from_username} to ${transaction.to_username} on ${transaction.created_date}.`}>
                            <tbody>
                                <tr className={tr_style}>
                                    <td className={td_style}>{transaction.id}</td>
                                    <td className={td_style}>{transaction.transaction.title}</td>
                                    <td className={td_style}>{`${transaction.transaction.price}$`}</td>
                                    <td className={td_style} dangerouslySetInnerHTML={{__html: transaction.transaction.brief}} />
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>

                {/* the print button */}
                <div className="mt-5">
                    <Print reference={reference} />
                </div>
            </div>
            :
            <NoViewData />}
        </div>
    );
};
export default TransactionView;