// import the react components
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

// import the custome components
import Modal from "./../modal";
import TextEditor from "./../../components/text-editor";
import FileForm from "./../file-form";

// import the styles
import {input_style} from "./../../assets/data/style";

// import the api components
import axios from "axios";
import {PRODUCT_API, PRODUCT_IMAGE_API} from "./../../assets/data/api";

// import the inputs
import {product_inputs} from "./../../assets/data/inputs";

// import the context
import {useStateContext} from "./../../context/";

// import the categories data
import {categories} from "./../../assets/data/categories";

// import the general data
import {token} from "./../../assets/data/general";

// the product modal functional based component
const ProductModal = () => {
    const {set_product_modal, selected_product, set_selected_product, is_loged, dispatch_product, set_alert} = useStateContext();
    const [state, set_state] = useState({
        title: selected_product ? selected_product.title : "",
        brief: selected_product ? selected_product.brief : "",
        description: selected_product ? selected_product.description : "",
        colors: selected_product ? selected_product.colors : "",
        pack_price: selected_product ? selected_product.pack_price : "",
        unit_price: selected_product ? selected_product.unit_price : "",
        pack_discount: selected_product ? selected_product.pack_discount : "",
        unit_discount: selected_product ? selected_product.unit_discount : "",
        stock: selected_product ? selected_product.stock : "",
        brand: selected_product ? selected_product.brand : "",
        category: selected_product ? selected_product.category : categories[1],
        images: selected_product ? selected_product.images.split(token) : [],
    });
    const navigate = useNavigate();

    // close the modal
    const handle_close = () => {
        set_product_modal(false);
        set_selected_product(null);
    };

    // handle the create and update actions
    const handle_submit = () => {

        // prepare the form data
        const form_data = new FormData();

        // if the action is update
        if (selected_product){
            form_data.append("id", selected_product.id);
            form_data.append("uniq", selected_product.uniq);
        }

        // prepare the query
        const endpoint = PRODUCT_API + `${selected_product ? "update.php" : "create.php"}`;
        for (const key in state) form_data.append(key, state[key]);
        form_data.append("username", is_loged.username);
        const headers = {"content-type": "multipart/form-data"};

        // add the images to the formdata
        const files = state.images;
        for (let i = 0; i < files.length; i++) form_data.append("file" + i, files[i]);

        // send the query to the server
        axios.post(endpoint, form_data, {headers}).then(response => {
            const {data} = response;
            if (data.success){
                selected_product ? dispatch_product({type: "update", payload: data.product}) : dispatch_product({type: "push", payload: data.product});
                set_alert({type: "success", text: "the product saved successfuly"});
                handle_close();
            }

            // if the error occured
            else if (data.error) set_alert({type: "error", text: data.error});
        });
    };

    // handle the delete
    const handle_delete = () => {

        // prepare the query
        const endpoint = PRODUCT_API + "delete.php";
        const data = {id: selected_product.id};

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success){
                dispatch_product({type: "delete", payload: selected_product});
                set_alert({type: "success", text: "the product deleted successfuly"});
                handle_close();
            }

            // if the error occured
            else if (data.error) set_alert({type: "error", text: data.error});
        });
    };

    // handle the product viewing
    const handle_view = () => navigate(`/view/product/${selected_product.id}`);

    // the state handler
    const handle_state = event => set_state({...state, [event.target.name]: event.target.value});

    // handle the image selection
    const handle_change = event => {
        const files = event.target.files;
        for (let i = 0; i < files.length; i++) set_state(preview => ({...preview, images: [...preview.images, files[i]]}));
    };

    // handle the image remove
    const handle_remove = id => {
        const new_images = state.images.filter((item, index) => index !== id);
        set_state({...state, images: new_images});
    };

    // the footer buttons
    const buttons = [
        {
            title: "save",
            handler: handle_submit,
        },
    ];
    if (selected_product){
        buttons.unshift({
            title: "delete",
            handler: handle_delete,
        });
        buttons.unshift({
            title: "view",
            handler: handle_view,
        });
    };

    // return to the component data
    return (
        <Modal handle_close={handle_close} buttons={buttons} z_index={10}>
            <div className="grid grid-cols-1 gap-y-8">
                {product_inputs.map((item, index) => {
                    if (item.type === "input") return <input key={item.name} type="text" name={item.name} placeholder={item.placeholder} value={state[item.name]} className={`${input_style}`} onChange={handle_state} required />;
                    else if (item.type === "editor") return <TextEditor key={item.name} name={item.name} html={state[item.name]} placeholder={item.placeholder} handler={handle_state} />;
                    else if (item.type === "group") return <div key={index} className="flex justify-center items-center gap-x-4">
                        {item.inputs.map(item => <input key={item.name} type="text" name={item.name} placeholder={item.placeholder} value={state[item.name]} className={`${input_style}`} onChange={handle_state} required />)}
                    </div>;
                    else return <div />;
                })}
                <select className={input_style} value={state.category} onChange={event => set_state({...state, category: event.target.value})}>
                    {categories.map(item => item !== "all" && <option key={item} value={item}>{item}</option>)}
                </select>

                <FileForm handle_change={handle_change} handle_delete={handle_remove} files={state.images} folder={selected_product && PRODUCT_IMAGE_API} />
            </div>
        </Modal>
    );
};
export default ProductModal;