// import the react components
import React from "react";

// the input functional based component
const Input = ({handle_change, custome_class, input_data, value, is_disabled = false}) => {
    const {labelfor, labeltext, id, name, type, is_required = false, placeholder, options} = input_data;
    const style = "dark:bg-secondary-dark-bg dark:text-gray-200 dark:border-none rounded-md appearance-none relative block w-full p-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm";

    // return to the component data
    return (
        <div className="my-3">
            <label htmlFor={labelfor} className="sr-only">{labeltext}</label>
            {type !== "select" ? <input
                onChange={handle_change}
                value={value}
                id={id}
                name={name}
                type={type}
                required={is_required}
                placeholder={placeholder}
                className={style + custome_class}
                disabled={is_disabled}
            />
            :
            <select name={name} id={id} onChange={handle_change} value={value} required={is_required} className={style + custome_class} placeholder={placeholder}>
                {options.map(item => <option key={item} value={item}>{item}</option>)}
            </select>
            }
        </div>
    )
};
export default Input;