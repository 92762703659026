// import the react components
import React, {useEffect, useState} from "react";

// import the dayjs components
import dayjs from "dayjs";

// import the custome components
import Modal from "./../modal";
import Filter from "./filter";

// import the context
import {useStateContext} from "./../../context/";

// the day modal functional based component
const DayModal = () => {
    const {set_show_day_modal, set_show_event_modal, selected_day, saved_events, set_selected_event, filter, set_filter} = useStateContext();
    const [day_events, set_day_events] = useState([]);

    // get the events of current day
    useEffect(() => {

        // filter the events
        const format = "DD-MM-YY";
        const events = saved_events.filter(event => dayjs(event.day).format(format) === selected_day.format(format));

        // sort the events
        const new_array = events.sort((a, b) => {
            return a.from_time > b.from_time;
        });
        set_day_events(new_array);
    }, [saved_events]);

    // handle the modal close
    const handle_close = () => {
        set_show_day_modal(false);
        set_filter("all");
    };

    // handle the view of the event
    const handle_view = item => {
        set_selected_event(item);
        set_show_event_modal(true);
    };

    // the footer buttons
    const buttons = [
        {
            title: "add",
            handler: () => set_show_event_modal(true),
        },
    ];

    // return to the component data
    return (
        <Modal handle_close={handle_close} buttons={buttons} z_number={2}>
            {day_events.length !== 0 ?
                <div className="flex flex-col gap-y-2">
                    <div className="flex justify-between items-center border-b-2 pb-3 my-2 dark:border-b-gray-700">
                        <h1 className="text-xl dark:text-gray-200">appointments and events of <span className="text-primary">{selected_day.format("MMMM D")}</span></h1>
                        <Filter />
                    </div>
                    {day_events.map(item => (filter === "all" || item.label === filter) && <div key={item.id} className="grid grid-cols-1/6 justify-center items-center">
                        <div className="flex flex-col w-20 mx-auto gapy-y-3 justify-center items-center">
                            <p className="text-sm font-bold border-b-2 mb-1.5 pb-1.5 dark:text-gray-200 dark:border-b-gray-700">{dayjs(item.from_time).format("hh:mm A")}</p>
                            <p className="text-sm font-bold dark:text-gray-200">{dayjs(item.to_time).format("hh:mm A")}</p>
                        </div>
                        <div className="cursor-pointer p-1.5 rounded-md" style={{background: item.label}} onClick={() => handle_view(item)}>
                            <h2 className="text-md font-bold mb-1">{item.title}</h2>
                            <p>{item.description}</p>
                        </div>
                    </div>)}
                </div>
                :
                <p className="text-lg dark:text-gray-200">No today's appointments or events.</p>
            }
        </Modal>
    );
};
export default DayModal;