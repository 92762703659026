// import the react components
import React from "react";

// import the images
import coming_soon_image from "./../assets/images/coming-soon.svg";

// the coming soon functional based component
const ComingSoon = () => {

    // return to the component data
    return (
        <div className="flex flex-col gap-y-2 justify-center items-center mt-16">
            <h1 className="text-3xl text-primary mb-16">This section is under the development.</h1>
            <img src={coming_soon_image} alt="the section is under the development." className="w-2/6" />
        </div>
    );
};
export default ComingSoon;