// import the react components
import React from "react";

// the search bar functional based components
const SearchBar = ({placeholder, value, handler}) => {

    // return to the component data
    return (
        <div className="bg-white dark:bg-main-dark-bg mt-1">
            <div className="relative mt-1 dark:bg-secondary-dark-bg">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
                </div>

                <input
                    type="text"
                    id="searchbar"
                    className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-md w-80 bg-gray-50 focus:ring-primary focus:border-primary dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-primary"
                    placeholder={placeholder}
                    value={value}
                    onChange={event => handler(event.target.value)}
                />
            </div>
        </div>
    );
};
export default SearchBar;