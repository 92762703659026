// import the react components
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

// import the custome components
import Modal from "./../modal";
import TextEditor from "./../../components/text-editor";

// import the styles
import {input_style} from "./../../assets/data/style";

// import the api components
import axios from "axios";
import {BLOG_API} from "./../../assets/data/api";

// import the context
import {useStateContext} from "./../../context/";

// the blog modal functional based component
const BlogModal = () => {
    const {set_blog_modal, selected_blog, set_selected_blog, is_loged, dispatch_blog, set_alert} = useStateContext();
    const [state, set_state] = useState({
        title: selected_blog ? selected_blog.title : "",
        introduction: selected_blog ? selected_blog.introduction : "",
        description: selected_blog ? selected_blog.description : "",
        summary: selected_blog ? selected_blog.summary : "",
        reference: selected_blog ? selected_blog.reference : "",
    });
    const navigate = useNavigate();

    // close the modal
    const handle_close = () => {
        set_blog_modal(false);
        set_selected_blog(null);
    };

    // handle the create and update
    const handle_submit = () => {

        // prepare the query
        const endpoint = BLOG_API + `${selected_blog ? "update.php" : "create.php"}`;
        const data = {
            ...state,
            id: selected_blog ? selected_blog.id : "",
            uniq: selected_blog ? selected_blog.uniq : "",
            username: is_loged.username,
        };

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success){
                selected_blog ? dispatch_blog({type: "update", payload: data.blog}) : dispatch_blog({type: "push", payload: data.blog});
                set_alert({type: "success", text: "the blog saved successfuly"});
                handle_close();
            }

            // if the error occured
            else if (data.error) set_alert({type: "error", text: data.error});
        })
    };

    // handle the delete
    const handle_delete = () => {

        // prepare the query
        const endpoint = BLOG_API + "delete.php";
        const data = {id: selected_blog.id};

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success){
                dispatch_blog({type: "delete", payload: selected_blog});
                set_alert({type: "success", text: "the blog deleted successfuly"});
                handle_close();
            }

            // if the error occured
            else if (data.error) set_alert({type: "error", text: data.error});
        });
    };

    // handle the view
    const handle_view = () => navigate(`/view/blog/${selected_blog.id}`);

    // the footer buttons
    const buttons = [
        {
            title: "save",
            handler: handle_submit,
        },
    ];
    if (selected_blog){
        buttons.unshift({
            title: "delete",
            handler: handle_delete,
        });
        buttons.unshift({
            title: "view",
            handler: handle_view,
        });
    }

    // extract the data
    const {title, introduction, description, summary, reference} = state;

    // return to the component data
    return (
        <Modal handle_close={handle_close} buttons={buttons} z_index={10}>
            <div className="grid grid-cols-1 gap-y-4">
                <input
                    type="text"
                    name="title"
                    placeholder="set the title"
                    value={title}
                    onChange={event => set_state({...state, title: event.target.value})} 
                    required
                    className={`${input_style}`}
                />

                <TextEditor html={introduction} placeholder="set the introduction" handler={event => set_state({...state, introduction: event.target.value})} />
                <TextEditor html={description} placeholder="set the description" handler={event => set_state({...state, description: event.target.value})} />
                <TextEditor html={summary} placeholder="set the summary" handler={event => set_state({...state, summary: event.target.value})} />
                <TextEditor html={reference} placeholder="set the references" handler={event => set_state({...state, reference: event.target.value})} />
            </div>
        </Modal>
    );
};
export default BlogModal;