// import the react components
import React, {Fragment, useEffect, useState} from "react";

// import the dayjs components
import dayjs from "dayjs";

// import the context
import {useStateContext} from "./../../context/";

// the day functional based component
const Day = ({day}) => {
    const {
        month_index,
        set_show_day_modal,
        set_show_event_modal,
        set_selected_day,
        saved_events,
        set_selected_event,
    } = useStateContext();
    const [day_events, set_day_events] = useState([]);

    // get the events of the current day
    useEffect(() => {
        const format = "DD-MM-YY";
        const events = saved_events.filter(event => dayjs(event.day).format(format) === day.format(format));
        set_day_events(events);
    }, [saved_events, day]);

    // only get the days of current month
    const is_in_current_month = () => {
        const date_format = "MM";
        return day.format(date_format) === dayjs().month(month_index).format(date_format) ? true : false;
    };

    // handle the day selection
    const handle_selected_day = () => {
        set_selected_day(day);
        set_show_day_modal(true);
    };

    // handle the event selection
    const handle_selected_event = item => {
        set_selected_day(day);
        set_selected_event(item);
        set_show_event_modal(true);
    };

    // customize the current day
    const date_format = "DD-MM-YY";
    const get_current_day_class = day.format(date_format) === dayjs().format(date_format) ? "bg-primary text-white" : "";

    // return to the component data
    return (
        <Fragment>
            {is_in_current_month() ?
            <div className="border border-gray-100 dark:border-gray-700 flex flex-col">

                {/* the header of the day */}
                <header className="group flex flex-col items-start cursor-pointer" onClick={handle_selected_day}>
                    <p className={`text-sm p-1 ml-2 my-1 text-center font-extrabold rounded-full w-7 dark:text-gray-200 group-hover:bg-primary group-hover:text-gray-100 ${get_current_day_class}`}>
                        {day.format("DD")}
                    </p>
                </header>

                {/* the body of the day for events */}
                <div className="flex-1">
                    {day_events.map((item, index) => index < 3 && 
                    <div key={item.id} style={{background: item.label}} className="p-1 mr-3 text-gray-900 text-sm rounded mb-0.5 truncate cursor-pointer" onClick={() => handle_selected_event(item)}>
                        {item.title}
                    </div>)}

                    {day_events.length > 3 && <button className="my-1 text-primary font-bold w-full" onClick={handle_selected_day}>
                        show all
                    </button>}
                </div>
            </div>
            :
            <div className="bg-gray-100 dark:bg-secondary-dark-bg"></div>}
        </Fragment>
    );
};
export default Day;