// import the react components
import {useEffect} from "react";
import {useLocation} from "react-router-dom";

// import the context
import {useStateContext} from "./";

// the scroll to top functional based hook
const ScrollToTop = () => {
    const {
        profile, set_profile,
        is_loading, set_is_loading,
    } = useStateContext();
    const {pathname} = useLocation();

    // monitor the page change
    useEffect(() => {
        profile && set_profile(false);
        is_loading && set_is_loading(false);
        window.scrollTo(0, 0);
    }, [pathname]);

    // return to the component data
    return null;
};
export default ScrollToTop;