// import the react components
import React from "react";

// the filter header functional based component
const FilterHeader = ({data, active, handler}) => {

    // return to the component data
    return (
        <div className="my-5 flex flex-wrap justify-center items-center gap-1">
            {data.map(item => <button key={item} className={`bg-primary border-primary border-2 py-2 px-5 text-lg rounded-md text-gray-50 hover:opacity-80 ${item === active && "bg-white text-gray-900 dark:bg-main-dark-bg dark:text-gray-200"}`} onClick={() => handler(item)}>
                {item}
            </button>)}
        </div>
    );
};
export default FilterHeader;