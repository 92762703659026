// import the react components
import React from "react"

// import the custome components
import Button from "./../button";

// import the styles
import {tr_style, td_style} from "./../../assets/data/style";

// the referred item functional based component
const ReferredItem = ({item}) => {

    // return to the component data
    return (
        <tr className={tr_style}>
            <td className={td_style}>{item.id}</td>
            <td className={td_style}>{item.to_username}</td>
            <td className={td_style}>
                <Button title="view" action="link" link={`/view/patient/${item.patient}`} />
            </td>
        </tr>
    );
};
export default ReferredItem;