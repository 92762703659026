// import the react components
import React, {useRef} from "react";

// import the icons
import {MdDragHandle} from "react-icons/md";
import {AiOutlineClose} from "react-icons/ai";

// import the custome components
import Button from "./button";
import ConfirmationDialog from "./../components/confirm";

// import the hooks
import useOutsideAlerter from "./../context/outside-alerter";

// the modal functional based component
const Modal = ({z_number, children, handle_close, buttons}) => {
    const reference = useRef();
    //useOutsideAlerter(reference, handle_close);

    // the styles
    const icon_style = "text-gray-400 text-lg";

    // return to the component data
    return (
        <div className="h-screen w-full fixed left-0 top-0 flex justify-center items-center z-10 bg-white bg-opacity-60">
            <div className="bg-white dark:bg-main-dark-bg rounded-lg shadow-2xl w-1/2" ref={reference} style={{zIndex: z_number}}>

                {/* the header of all modals */}
                <header className="bg-gray-100 dark:bg-secondary-dark-bg px-4 py-2 flex justify-between items-center">
                    <span className={`${icon_style}`}><MdDragHandle /></span>

                    <div className="flex gap-x-5">
                        <button onClick={handle_close} className={icon_style}><AiOutlineClose /></button>
                    </div>
                </header>

                {/* the body of modals */}
                <div className="p-3 max-h-[calc(100vh_-_30vh)] overflow-auto">
                    {children}
                </div>

                {/* the footer of the modal */}
                {buttons && <footer className="flex justify-end border-t p-3 mt-5 dark:border-t-gray-700 gap-1">
                    {buttons.map(button => (button.title === "view" || button.title === "add" || button.title === "book patient") ?
                    <Button key={button.title} action="button" title={button.title} handler={button.handler} custom_class="w-auto px-6 py-2" />
                    :
                    <ConfirmationDialog key={button.title} button_title={button.title} dialog_title={`${button.title}`} dialog_content="are you sure to perform this action?" handle_action={button.handler} />)}
                </footer>}
            </div>
        </div>
    );
};
export default Modal;