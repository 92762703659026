// import the react components
import React from "react";

// import the dayjs components
import dayjs from "dayjs";

// import the icons
import {FaChevronLeft} from "react-icons/fa";
import {FaChevronRight} from "react-icons/fa";

// import the context
import {useStateContext} from "./../../context/";

// import the custom components
import {Tipper} from "./../../components/material";

// the calendar header functional based component
const CalendarHeader = () => {
    const {month_index, set_month_index} = useStateContext();

    // handle the click events
    const handle_preview_month = () => set_month_index(month_index -1);
    const handle_next_month = () => set_month_index(month_index + 1);
    const handle_reset = () => set_month_index(dayjs().month());

    // the current month and year
    const current_date = dayjs(new Date(dayjs().year(), month_index)).format("MMMM YYYY");

    // the month togglers
    const month_toggler = [
        {
            title: "preview month",
            icon: <FaChevronLeft />,
            handler: handle_preview_month,
        },
        {
            title: "next month",
            icon: <FaChevronRight />,
            handler: handle_next_month,
        },
    ];

    // return to the component data
    return (
        <header className="px-4 py-1 flex items-center justify-between">
            <h1 className="font-extrabold text-3xl dark:text-gray-200">{current_date}</h1>
            <div className="flex items-center">
                <button onClick={handle_reset} className="border rounded-md py-2 px-6 dark:text-gray-200">current month</button>
                {month_toggler.map(item => <Tipper key={item.title} title={item.title}>
                    <button onClick={item.handler} className="text-gray-700 text-xl mx-2 dark:text-gray-200">{item.icon}</button>
                </Tipper>)}
            </div>
        </header>
    );
};
export default CalendarHeader;