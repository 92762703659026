// import the react components
import React, {Fragment} from "react";

// import the custome components
import Day from "./day";

// import the colors
import {primary} from "./../../assets/data/colors";

// the month functional based component
const Month = ({month}) => {

    // return to the component data
    return (
        <div className="h-full mb-10">

            {/* the top for the days of the week */}
            <div className="grid grid-cols-7 grid-rows-1 mt-3">
                {month[0].map(item => (
                    <div key={item} className="rounded-sm text-gray-50 py-2 text-center border-r-2 border-gray-100 dark:border-gray-700" style={{background: primary}}>
                        {item.format("ddd")}
                    </div>
                ))}
            </div>

            {/* the days of the month */}
            <div className="h-full grid grid-cols-7 grid-rows-5">
                {month.map((row, row_index) => (
                    <Fragment key={row_index}>
                        {row.map((day, day_index) => (
                            <Day key={day_index} day={day} />
                        ))}
                    </Fragment>
                ))}
            </div>
        </div>
    );
};
export default Month;