// import the react components
import React from "react";
import {useNavigate} from "react-router-dom";

// import the custome components
import Button from "./../button";

// import the context
import {useStateContext} from "./../../context/";

// import the api
import {COURSE_IMAGE_API} from "./../../assets/data/api";

// the course item functional based components
const CourseItem = ({course}) => {
    const {is_loged, set_course_modal, set_selected_course} = useStateContext();
    const navigate = useNavigate();

    // handle the click
    const handle_click = () => {

        // show the update and delete buttons
        if (is_loged.username === course.username){
            set_course_modal(true);
            set_selected_course(course);
        }

        // view the data
        else {
            navigate(`/view/course/${course.id}`);
        };
    };

    // return to the component data
    return (
        <div>
            <img src={COURSE_IMAGE_API + course.cover} className="w-full h-40 object-cover object-top" />

            <div className="mt-4">
                <h1 className="text-xl dark:text-gray-200">{course.title}</h1>
                <h3 className="text-gray-700 dark:text-gray-500">Dr. {course.username}</h3>
            </div>

            <div className="flex items-center gap-2 my-2">
                <p className={course.discount ? "line-through text-red-700" : "dark:text-gray-200"}>{Number(course.price).toLocaleString()}$</p>
                {course.discount && <p className="dark:text-gray-200">{Number(course.discount).toLocaleString()}$</p>}
            </div>

            <Button action="button" title="view course" handler={handle_click} custom_class="w-full" />
        </div>
    );
};
export default CourseItem;