// import the react components
import React, {useState} from "react";

// import the icons
import {RiFilter3Fill} from "react-icons/ri";

// import the custom components
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// import the label colors
import {labels_colors} from "./../../assets/data/colors";

// import the context
import {useStateContext} from "./../../context/";

// the filter functional based component
const Filter = () => {
    const {set_filter} = useStateContext();
    const [anchor, set_anchor] = useState(null);
    const open = Boolean(anchor);

    // handle the filter opener
    const handle_open = event => set_anchor(event.currentTarget);
    const handle_close = value => {
        set_filter(value);
        set_anchor(null);
    };

    // return to the component data
    return (
        <div>
            <button className="flex justify-center items-center bg-primary py-2.5 px-3.5 rounded text-gray-50 gap-x-1.5 capitalize mr-2 hover:opacity-90" onClick={handle_open}>
                <RiFilter3Fill size={19} />
            </button>

            <Menu anchorEl={anchor} open={open} anchorOrigin={{vertical: "top", horizontal: "left"}} transformOrigin={{vertical: "top", horizontal: "left"}} onClick={() => set_anchor(null)}>
                <MenuItem onClick={() => handle_close("all")} className="w-full">all</MenuItem>
                {labels_colors.map(item => <MenuItem onClick={() => handle_close(item)} key={item}><span style={{background: item}} className="w-4 h-4 rounded-full"></span></MenuItem>)}
            </Menu>
        </div>
    );
};
export default Filter;