// import the react components
import React from "react";

//import the editor components
import {
    EditorProvider,
    Editor,
    Toolbar,
    BtnBold,
    BtnItalic,
    BtnBulletList,
    BtnClearFormatting,
    BtnNumberedList,
    BtnUnderline,
    BtnStrikeThrough,
    BtnLink,
    BtnStyles,
} from "react-simple-wysiwyg";

// the text editor functional based component
const TextEditor = ({name, html, placeholder, handler}) => {

    // return to the component data
    return (
        <div className="dark:text-gray-200 dark:bg-secondary-dark-bg">
            <EditorProvider>
                <Editor name={name} value={html} onChange={handler} placeholder={placeholder} id="blog">
                    <Toolbar>
                        <BtnClearFormatting />
                        <BtnBold />
                        <BtnItalic />
                        <BtnUnderline />
                        <BtnStrikeThrough />
                        <BtnLink />
                        <BtnStyles />
                        <BtnBulletList />
                        <BtnNumberedList />
                    </Toolbar>
                </Editor>
            </EditorProvider>
        </div>
    );
};
export default TextEditor;