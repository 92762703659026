// import the react components
import React, {Fragment, useRef} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Divider} from "@mui/material/";

// import the icons
import {CgProfile} from "react-icons/cg";
import {CgWebsite} from "react-icons/cg";

// import the context
import {useStateContext} from "./../context/index";

// import the outside alerter
import useOutsideAlerter from "./../context/outside-alerter";

// the profile drowpdown functional based component
export const ProfileDropdown = () => {
    const {profile, is_loged, set_alert, set_is_loged, set_profile, set_active_menu} = useStateContext();
    const wrapper = useRef(null);
    useOutsideAlerter(wrapper, () => set_profile(false));

    // the navifation
    const navigate = useNavigate();

    // handle the logout
    const handle_logout = () => {
        localStorage.clear();
        set_is_loged(false);
        set_active_menu(false);
        navigate("/");
    };

    // the click handler
    const handle_click = () => set_alert({type: "warning", text: "You don't have site, please contact us"});

    // the styles
    const li_styles = "px-2 py-2 hover:bg-gray-200 flex justify-start items-center gap-x-3 capitalize tracking-wider cursor-pointer dark:text-gray-200 dark:hover:bg-gray-600";
    const your_site = <Fragment><CgWebsite /> <span>your site</span></Fragment>;

    // return to the component data
    return (
        <div ref={wrapper} className={`z-20 bg-white dark:bg-secondary-dark-bg absolute top-16 mx-auto w-3/5 rounded-xl transition-all delay-75 ${profile ? "-translate-y-3 visible" : "translate-y-1 invisible"}`}>
            <div className="shadow-md text-center">
                <ul className="">
                    <Link to="/profile" className={li_styles}><CgProfile /> <span>profile</span></Link>
                    {is_loged.site ?
                    <a href={is_loged.site} target="_blank" rel="noreferrer" className={li_styles}>{your_site}</a>
                    :
                    <div className={li_styles} onClick={handle_click}>{your_site}</div>}

                    <Divider />
                    <button onClick={handle_logout} className="py-2 text-primary font-bold capitalize w-full h-full">logout</button>
                </ul>
            </div>
        </div>
    );
};