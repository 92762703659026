// import the general data
import {dashboard_cost, all_cost} from "./general";

// the login inputs
export const login_fields= [
    {
        labeltext: "username",
        labelfor: "username",
        id: "username",
        name: "username",
        type: "text",
        is_required: true,
        placeholder: "username",
    },
    {
        labeltext: "password",
        labelfor: "password",
        id: "password",
        name: "password",
        type: "password",
        is_required: true,
        placeholder: "password",
    }
];

// the logup inputs
export const logup_fields= [
    {
        labeltext: "username",
        labelfor: "username",
        id: "username",
        name: "username",
        type: "text",
        is_required: true,
        placeholder: "username",
    },
    {
        labeltext: "mail",
        labelfor: "mail",
        id: "mail",
        name: "mail",
        type: "email",
        is_required: true,
        placeholder: "email address",
    },
    {
        labeltext: "phone number",
        labelfor: "phone",
        id: "phone",
        name: "phone",
        type: "text",
        is_required: true,
        placeholder: "phone number",
    },
    {
        labeltext: "platform",
        labelfor: "platform",
        id: "platform",
        name: "platform",
        type: "select",
        is_required: true,
        placeholder: "platform",
        options: [
            `platform`,
            `dashboard - ${dashboard_cost}$`,
            `dashboard and dental site - ${all_cost}$`,
        ],
    },
    {
        labeltext: "password",
        labelfor: "password",
        id: "password",
        name: "password",
        type: "password",
        is_required: true,
        placeholder: "password",
    },
    {
        labeltext: "repassword",
        labelfor: "repassword",
        id: "repassword",
        name: "repassword",
        type: "password",
        is_required: true,
        placeholder: "confirm password",
    },
];

// the forgot inputs
export const forgot_fields= [
    {
        labeltext: "mail",
        labelfor: "mail",
        id: "mail",
        name: "mail",
        type: "text",
        is_required: true,
        placeholder: "email address",
    },
    {
        labeltext: "code",
        labelfor: "code",
        id: "code",
        name: "code",
        type: "text",
        is_required: true,
        placeholder: "code",
    },
    {
        labeltext: "password",
        labelfor: "password",
        id: "password",
        name: "password",
        type: "password",
        is_required: true,
        placeholder: "password",
    },
    {
        labeltext: "repassword",
        labelfor: "repassword",
        id: "repassword",
        name: "repassword",
        type: "password",
        is_required: true,
        placeholder: "confirm password",
    },
];

// the auth fields
export const auth_fields = [
    {
        labeltext: "code",
        labelfor: "code",
        id: "code",
        name: "code",
        type: "text",
        is_required: true,
        placeholder: "authentication code",
    },
];