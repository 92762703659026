// import the react components
import React from "react";
import {Link} from "react-router-dom";

// import the logo
import logo from "./../assets/images/logo.png";

// the outer header functional based component
const OuterHeader = () => {

    // the navigation links
    const nav_links = [
        {
            to: "developer",
            title: "developer",
        },
        {
            to: "contact",
            title: "contact",
        },
        {
            to: "login",
            title: "login",
            custom_style: "bg-primary py-2 px-5 tracking-wider text-white rounded-md text-md",
        },
    ];

    // return to the component data
    return (
        <div className="-m-5 px-6 flex justify-between items-center h-20">

            {/* the logo section */}
            <div className="flex justify-center items-center">
                <Link to="/">
                    <img src={logo} alt="the permanent and primary teeth: the logo" className="w-12" />
                </Link>
            </div>

            {/* the navigation button section */}
            <nav className="flex gap-x-4 items-center">
                {nav_links.map(item => <Link
                    key={item.title}
                    to={`/${item.to}`}
                    className={`capitalize text-lg hover:opacity-80 dark:text-gray-200 ${item.custom_style}`}
                >
                    {item.title}
                </Link>)}
            </nav>
        </div>
    );
};
export default OuterHeader;