// import the react components
import React, {useEffect, useState} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import useNavigation from "./../context/navigation";

// import the custome components
import Alerter from "./../components/alerter";
import Header from "./../ui/registration/header";
import Input from "./../ui/registration/input";
import FormAction from "./../ui/registration/form-action";
import Footer from "./../components/footer";

// import the api data
import axios from "axios";
import {USER_API} from "./../assets/data/api";

// import the context
import {useStateContext} from "./../context/";

// import the general data
import {token} from "./../assets/data/general";

// initiate the auth data
import {auth_fields} from "./../assets/data/registration";
const fields = auth_fields;
let field_state = {};
fields.forEach(field => field_state[field.id] = "");

// the auth functional based component
const Auth = () => {
    const {is_loged, set_is_loged, set_alert, set_is_disable} = useStateContext();
    const [auth, set_auth] = useState(field_state);
    const [resend, set_resend] = useState(true);

    // the navigation
    useNavigation();
    const navigate = useNavigate();

    // get the auth type
    const {pathname} = useLocation();
    const auth_type = pathname.split("/")[2];

    // handle the input change
    const handle_change = event => set_auth({...auth, [event.target.id]: event.target.value});

    // handle the submit
    const handle_submit = event => {
        event.preventDefault();
        set_is_disable(true);

        // prepare the query
        const endpoint = USER_API + "auth.php";
        const data = {
            mail: is_loged.mail,
            code: auth.code,
            stage: "verify",
            type: auth_type,
        };

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success){

                // the user is trying to login
                if (auth_type === "authentication"){
                    const user = {...is_loged, auth: token};
                    set_is_loged(user);
                    navigate("/schedule");
                }

                // the user is trying to logup
                else if (auth_type === "verification"){
                    set_alert({type: "warning", text: "please contact us to activate dashboard"});
                    navigate("/");
                }

                // the user is verifying the email again
                else if (auth_type === "reverification"){
                    set_alert({type: "success", text: "your dashboard is waiting for you"});
                    navigate("/login");
                }
            }

            // if the error occured
            else if (data.error) set_alert({type: "error", text: data.error});

            // remove the disable
            set_is_disable(false);
        });
    };

    // the resend activation
    const activate_resend = () => {
        setTimeout(() => {
            set_resend(false);
        }, 60000);
    };
    useEffect(() => {
        activate_resend();
    }, []);

    // handle the resend
    const handle_resend = event => {
        event.preventDefault();

        // prepare the query
        const endpoint = USER_API + "auth.php";
        const data = {
            mail: is_loged.mail,
            type: auth_type,
            stage: "resend",
        };

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success){
                set_alert({type: "success", text: "the code resent"});
                set_resend(true);
                activate_resend();
            }

            // if the error occured
            else if (data.error) set_alert({type: "error", text: data.error});
        });
    };

    // return to the component data
    return (
        <div className="-m-5 min-h-full h-screen flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <Alerter />

            {/* the authentication form */}
            <div className="max-w-md w-full space-y-8 p-4">
                <Header
                    heading="Authentication"
                    paragraph={`the authentication code sent to`}
                    paragraph_extra={is_loged.mail}
                />

                <form className="space-y-6" action="#">
                    <div className="-space-y-px">
                        {fields.map(field=>
                            <Input
                                key={field.id}
                                input_data={field}
                                handle_change={handle_change}
                                value={auth[field.id]}
                            />
                        )}
                    </div>

                    <div className="flex justify-start items-center text-sm gap-x-2 ml-2">
                        <p className="text-gray-700 dark:text-gray-200">did't recieve code?</p>
                        <button type="button" disabled={resend} onClick={handle_resend} className="text-primary disabled:text-gray-400 dark:disabled:text-gray-500 font-bold">resend code</button>
                    </div>

                    <FormAction text="verify" handle_submit={handle_submit} />
                </form>
            </div>

            {/* the footer */}
            <Footer copy={false} />
        </div>
    );
};
export default Auth;