// import the react components
import React from "react";
import {Link} from "react-router-dom";

// import the colors
import {gradient_primary} from "./../assets/data/colors";

// the button functional based components
const Button = ({title, action, handler, link, custom_class, bg_color = gradient_primary, disable}) => {
    const classname = `w-24 h-12 rounded-md text-lg text-gray-100 hover:opacity-90 block flex justify-center items-center ${custom_class}`;
    const style = {background: bg_color}

    // return to the component data
    return (
        <div>
            {action === "button" && <button disabled={disable ? disable : false} style={style} className={`${classname} disabled:opacity-60`} onClick={handler}>{title}</button>}
            {action === "link" && <Link className={classname} to={link} style={style}>{title}</Link>}
        </div>
    );
};
export default Button;