// import the react components
import React, {useEffect, useState} from "react";

// import the custome components
import CaseModal from "./../ui/case/case-modal";
import Title from "./../ui/title";
import SearchBar from "./../ui/search-bar";
import ListWrapper from "./../ui/list-wrapper";
import CaseItem from "./../ui/case/case-item";
import NoData from "./../ui/no-data";

// import the icons
import {HiBookOpen} from "react-icons/hi";

// import the api components
import axios from "axios";
import {CASE_API} from "./../assets/data/api";

// import the context
import {useStateContext} from "./../context/";
import useNavigation from "./../context/navigation";

// the cases functional based component
const Cases = () => {
    const {saved_cases, dispatch_case, case_modal, set_alert, set_is_loading} = useStateContext();
    const [search, set_search] = useState();
    useNavigation();

    // get the shared cases
    useEffect(() => {
        saved_cases.length === 0 && set_is_loading(true);

        // prepare the query
        const endpoint = CASE_API + "read.php";

        // send the query to the server
        axios.post(endpoint).then(response => {
            const {data} = response;
            if (data.success) dispatch_case({type: "add", payload: data.cases});

            // if the error occured
            else if (data.error) set_alert({type: "error", text: data.error});
            set_is_loading(false);
        });
    }, []);

    // return to the component data
    return (
        <div>

            {/* the modals */}
            {case_modal && <CaseModal />}

            {/* the title of the webpage */}
            <Title text="cases" icon={<HiBookOpen />} />

            {/* the header of the webpage */}
            <div className="flex items-center justify-between">
                <SearchBar placeholder="search cases" value={search} handler={value => set_search(value)} />
            </div>

            {/* the list of the cases */}
            {saved_cases.length > 0 ? <ListWrapper>
                {saved_cases.map(item => <CaseItem key={item.id} item={item} />)}
            </ListWrapper>
            :
            <NoData message="No cases being shared, share your cases to other dentists." />}
        </div>
    );
};
export default Cases;