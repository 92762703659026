// import the react and react-dom components
import React from "react";
import {Link} from "react-router-dom";

// import the colors
import {primary} from "./../../assets/data/colors";

// the form extra functional based component
const FormExtra = ({value, handle_checkbox}) => {

    // return to the component data
    return (
        <div className="flex items-center justify-between">
            <div className="flex items-center">
                <input
                    id="rememberme"
                    name="rememberme"
                    type="checkbox"
                    className="h-4 w-4 focus:outline-none focus:ring-0 focus:border-primary border-gray-300 rounded dark:bg-secondary-dark-bg dark:focus:bg-primary dark:border-none dark:checked:bg-primary"
                    style={{color: primary}}
                    value={value}
                    onChange={handle_checkbox}
                />
                <label htmlFor="rememberme" className="ml-2 block text-sm text-gray-900 dark:text-gray-200">remember me</label>
            </div>

            <div className="text-sm">
                <Link to="/forgot" className="font-medium hover:opacity-90" style={{color: primary}}>forgot password?</Link>
            </div>
        </div>
    );
};
export default FormExtra;