// import the react components
import React, {useEffect, useState} from "react";

// import the custome components
import ProductModal from "./../ui/product/product-modal";
import Title from "./../ui/title";
import SearchBar from "./../ui/search-bar";
import Button from "./../ui/button";
import ListWrapper from "./../ui/list-wrapper";
import FilterHeader from "./../ui/filter-header";
import Product from "./../ui/product/product";
import NoData from "./../ui/no-data";

// import the icons
import {FaBoxes} from "react-icons/fa";

// import the api components
import axios from "axios";
import {PRODUCT_API} from "./../assets/data/api";

// import the categories data
import {categories} from "./../assets/data/categories";

// import the context
import {useStateContext} from "./../context/";
import useNavigation from "./../context/navigation";

// the products functional based componentproducts
const Products = () => {
    const {saved_products, dispatch_product, set_alert, product_modal, set_product_modal, product_category, set_product_category, set_is_loading} = useStateContext();
    const [search, set_search] = useState("");
    useNavigation();

    // get the products
    useEffect(() => {
        saved_products.length === 0 && set_is_loading(true);

        // prepare the query
        const endpoint = PRODUCT_API + "read.php";

        // send the query to the server
        axios.get(endpoint).then(response => {
            const {data} = response;
            if (data.success) dispatch_product({type: "add", payload: data.products});

            // if the error occured
            else if (data.error) set_alert({type: "error", text: data.error});
            set_is_loading(false);
        });
    }, []);

    // return to the component data
    return (
        <div>

            {/* the modals */}
            {product_modal && <ProductModal />}

            {/* the title of the webpage */}
            <Title text="products" icon={<FaBoxes />} />

            {/* the header of the webpage */}
            <div className="flex justify-between items-center mb-3">
                <SearchBar placeholder="search products" value={search} handler={value => set_search(value)} />
                <Button action="button" title="add" handler={() => set_product_modal(true)} />
            </div>

            {/* the list of the products */}
            {saved_products.length > 0 ?
            <div className="flex flex-col">
                <FilterHeader data={categories} active={product_category} handler={item => set_product_category(item)} />
                <ListWrapper grid="lg:grid-cols-5 md:grid-cols-2 grid-cols-1">
                    {saved_products.map(item => (product_category === "all" || product_category === item.category) && <Product key={item.id} product={item} />)}
                </ListWrapper>
            </div>
            :
            <NoData message="There is no available products." />}
        </div>
    );
};
export default Products;