// import the icons
import {BsCalendar2Fill} from "react-icons/bs";
import {GoPerson} from "react-icons/go";
import {RiUserReceived2Fill} from "react-icons/ri";
import {BsFillSendPlusFill} from "react-icons/bs";
import {RiPieChart2Fill} from "react-icons/ri"
import {FaStickyNote} from "react-icons/fa";
import {BsCartFill} from "react-icons/bs";
import {FaBoxes} from "react-icons/fa";
import {BsFillPersonVcardFill} from "react-icons/bs";
import {ImLab} from "react-icons/im";
import {FaChalkboardTeacher} from "react-icons/fa";
import {ImBook} from "react-icons/im";
import {IoIosPaper} from "react-icons/io";
import {HiBookOpen} from "react-icons/hi";
import {TiPin} from "react-icons/ti";
import {BiWorld} from "react-icons/bi";
import {IoMdSettings} from "react-icons/io";
import {MdSupport} from "react-icons/md";

// the sidebar links
export const sidebar_links = [
    {
        title: "dashboard",
        links: [
            {
                name: "schedule",
                icon: <BsCalendar2Fill />,
            },
            {
                name: "statistics",
                icon: <RiPieChart2Fill />,
            },
            {
                name: "notes",
                icon: <FaStickyNote />,
            },
        ],
    },
    {
        title: "patient",
        links: [
            {
                name: "clinic",
                icon: <GoPerson />
            },
            {
                name: "received",
                icon: <RiUserReceived2Fill />
            },
            {
                name: "referred",
                icon: <BsFillSendPlusFill />,
            },
        ],
    },
    {
        title: "market",
        links: [
            {
                name: "orders",
                icon: <BsCartFill />
            },
            {
                name: "products",
                icon: <FaBoxes />
            },
            {
                name: "workers",
                icon: <BsFillPersonVcardFill />
            },
            {
                name: "laboratories",
                icon: <ImLab />
            },
            {
                name: "courses",
                icon: <FaChalkboardTeacher />
            },
            {
                name: "books",
                icon: <ImBook />
            },
            {
                name: "transactions",
                icon: <IoIosPaper />
            },
        ],
    },
    {
        title: "knowledge",
        links: [
            {
                name: "cases",
                icon: <HiBookOpen />
            },
            {
                name: "tips",
                icon: <TiPin />
            },
            {
                name: "blogs",
                icon: <BiWorld />
            },
        ],
    },
    {
        title: "site",
        links: [
            {
                name: "settings",
                icon: <IoMdSettings />
            },
        ],
    },
    {
        title: "support",
        links: [
            {
                name: "contact",
                icon: <MdSupport />
            },
        ],
    },
];

// the active and passive sidebar links style
const share_style = "flex items-center gap-4 pl-4 pt-3 pb-2.5 rounded-md text-md m-2 ";
export const active_link = share_style + "text-gray-100";
export const passive_link = share_style + "text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray";