// import the react components
import React from "react";

// import the custom components
import ComingSoon from "./../ui/coming-soon";

// import the context
import useNavigation from "./../context/navigation";

// the chat functional based component
const Chat = () => {
    useNavigation();

    // return to the component data
    return (
        <div>
            <ComingSoon />
        </div>
    );
};
export default Chat;