// import the react components
import React, {useEffect, useState} from "react";
import {useNavigate, useLocation, Link} from "react-router-dom";

// import the icons
import {BiArrowBack} from "react-icons/bi";

// import the custome components
import Title from "./../ui/title";
import PatientHeader from "./../ui/patient/patient-header";
import AllBooks from "./../ui/patient/all-books";
import PatientForm from "./../ui/patient/patient-form";
import BookActions from "./../ui/patient/book-actions";
import NoViewData from "./../ui/no-view-data";

// import the api components
import axios from "axios";
import {PATIENT_API} from "./../assets/data/api";

// import the inputs
import {
    forms,
    top_patients_info,
    anamnesis,
    medical_info,
    details_medical_info,
    dental_info,
    general_exam,
    extra_exam,
    occlusion_exam,
    tissue_exam,
} from "./../assets/data/inputs";

// import the context
import {useStateContext} from "./../context/";
import useNavigation from "./../context/navigation";

// create the state
const create_state = data => {
    let result = {};
    data.forEach(item => result[item.label] = "");
    return result;
};

// the book functional based component
const Book = () => {

    // the initial state
    const init_state = {
        information: create_state(top_patients_info),
        anamnesis: create_state(anamnesis),
        medical_history: create_state(medical_info),
        compromised_patient: create_state(details_medical_info),
        dental_history: create_state(dental_info),
        general_evaluation: create_state(general_exam),
        extraoral_evaluation: create_state(extra_exam),
        occlusion_evaluation: create_state(occlusion_exam),
        intraoral_tissue_evaluation: create_state(tissue_exam),
        teeth_evaluation: [],
        indexes: [],
        xrays: [],
        cases: [],
        treatment_plan: [],
        books: [],
        name: "",
        code: "",
        is_refer: false,
    };

    // the state
    const {set_alert, is_loged, set_is_loading, set_is_disable} = useStateContext();
    const [open, set_open] = useState(-1);
    const [id, set_id] = useState(0);
    const [state, set_state] = useState(init_state);
    useNavigation();

    // get the id and confirm the id
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const explode = pathname.split("/");
    const url_id = explode[2];

    // get the current patient's data
    useEffect(() => {
        set_is_loading(true);
        set_id(url_id);
        set_state(init_state);

        // prepare the query
        const endpoint = PATIENT_API + "read-single.php";
        const data = {id: url_id, username: is_loged.username};

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success){
                let new_state = {};
                for (const key in data.patient){
                    if (data.patient[key] !== null) new_state[key] = data.patient[key];
                };
                set_state(preview => ({...preview, ...new_state}));
            }

            // if the error occured
            else if (data.error) navigate("/clinic");
            set_is_loading(false);
        });
    }, [pathname]);

    // the state handler
    const state_handler = (event, name) => {
        set_state(preview => (
            {...preview, [name]: {...preview[name], [event.target.name]: event.target.value}}
        ));
    };

    // the array handler
    const array_handler = (name, value) => {
        set_state(preview => (
            {...preview, [name]: [...preview[name], value]}
        ));
    };

    // delete the array handler
    const delete_array_handler = (name, item_index) => {
        set_state(preview => (
            {...preview, [name]: preview[name].filter((item, index) => index != item_index && item)}
        ));
    };

    // update the data
    const handle_update = name => {
        set_is_disable(true);

        // update the images
        if (name === "xrays" || name === "cases"){
            handle_images(name);
            return;
        };

        // prepare the query
        const endpoint = PATIENT_API + "update.php";
        const data = {
            id,
            column: name,
            value: JSON.stringify(state[name]),
        };

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success){
                set_alert({type: "success", text: "patient saved successfully"});
                set_is_disable(false);
            }

            // error occured
            else if (data.error) set_alert({type: "error", text: data.error})
        });
    };

    // the image handler
    const handle_images = name => {

        // prepare the query
        const endpoint = PATIENT_API + "update.php";
        const form_data = new FormData();
        form_data.append("id", id);
        form_data.append("column", name);
        form_data.append("value", JSON.stringify(state[name]));
        form_data.append("images", name === "xrays" ? state.xrays : state.cases);

        const files = state[name];
        for (let i = 0; i < files.length; i++) form_data.append("file" + i, files[i]);

        // send the query to the server
        axios.post(endpoint, form_data).then(response => {
            const {data} = response;
            if (data.success){
                set_alert({type: "success", text: "patient saved successfully"});
                set_is_disable(false);
            }

            // error occured
            else if (data.error) set_alert({type: "error", text: data.error})
        });
    };

    // return to the component data
    return (
        <div>

            {/* the go back button */}
            <div className="max-w-max">
                <Link to="/clinic">
                    <Title text="go back" icon={<BiArrowBack />} />
                </Link>
            </div>

            {state.code ? <div>

                {/* the header of the patient data */}
                <PatientHeader name={state.name} code={state.code} title="view" link={`/view/patient/${id}`} />

                {/* the all books of current patient */}
                <AllBooks state={state} set_state={set_state} id={id} />

                {/* the forms */}
                <div className="mb-16">
                    {forms.map((form, index) =>
                        <PatientForm
                            key={index}
                            title={form.title}
                            paragraph={form.paragraph}
                            styles={form.styles && form.styles}
                            data={form.data}
                            component={form.component && form.component}
                            index={index}
                            state={state[form.name]}
                            set_state={state_handler}
                            set_array={array_handler}
                            delete_array={delete_array_handler}
                            name={form.name}
                            handle_update={handle_update}
                            open={open}
                            set_open={set_open}
                        />
                    )}
                </div>

                {/* the actions */}
                <BookActions patient={state} id={id} />
            </div>
            :
            <NoViewData />}
        </div>
    );
};
export default Book;