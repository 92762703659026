// import the react components
import React, {useEffect, useState} from "react";

// import the custom components
import CartModal from "./../ui/cart/cart-modal";
import Title from "./../ui/title";
import Table from "./../components/table";
import TableBody from "./../components/table-body";
import CartItem from "./../ui/cart/cart-item";

// import the icons
import {RiShoppingCartLine} from "react-icons/ri";

// import the context
import {useStateContext} from "./../context/";
import useNavigation from "./../context/navigation";

// import the api components
import axios from "axios";
import {CART_API, ORDER_API} from "./../assets/data/api";

// the cart functional based component
const Cart = () => {
    const {dispatch_cart, saved_carts, is_loged, set_alert, set_is_loading, cart_modal} = useStateContext();
    const [page, set_page] = useState(1);
    useNavigation();

    // get the carts
    useEffect(() => {
        saved_carts.length === 0 && set_is_loading(true);

        // prepare the query
        const endpoint = CART_API + "read.php";
        const data = {username: is_loged.username};

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success) dispatch_cart({type: "add", payload: data.carts});
            set_is_loading(false);
        });
    }, []);

    // the order handler
    const order_handler = () => {

        // prepare the query
        const endpoint = ORDER_API + "create.php";
        const data = {
            carts: saved_carts,
            dentist: is_loged.username,
        }

        //send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success){
                dispatch_cart({type: "add", payload: []});
                set_alert({type: "success", text: "the orders are sent"});
            }
        });
    };

    // get the total price
    const get_total = () => {
        let final = 0;
        saved_carts.map(item => {
            let type_price = 0;
            if (item.type === "pack") type_price = item.pack_discount ? item.pack_discount : item.pack_price;
            else type_price = item.unit_discount ? item.unit_discount : item.unit_price;
            final += Number(item.count) * Number(type_price);
        });
        return final.toLocaleString();
    };

    // the table header
    const headers = ["id", "image", "type", "color", "count", "price", "", "", ""];
    const footer = ["total", `${get_total()}$`];

    // should the button be disabled
    const is_disabled = saved_carts.length > 0 ? false : true;

    // return to the component data
    return (
        <div>

            {/* the modals */}
            {cart_modal && <CartModal />}

            {/* the title of the page */}
            <Title text="cart" icon={<RiShoppingCartLine />} />

            {/* the table of products */}
            <Table paragraph="This table shows the list of your items on the cart." button_disable={is_disabled} button_title="order all" button_handler={order_handler} header={headers} body={saved_carts} page={page} set_page={set_page} footer={footer} has_print={true}>
                <TableBody page={page} data={saved_carts} Component={CartItem} />
            </Table>
        </div>
    );
};
export default Cart;