// import the react components

import React from "react";

// the card functional based component

const Card = ({children, custome_style, title}) => {

    // return to the component data

    return (
        <div className={`bg-white dark:bg-secondary-dark-bg p-5 shadow rounded-md ${custome_style}`}>
            {title && <h1 className="font-semibold text-lg mb-3 dark:text-gray-200">{title}</h1>}
            {children}
        </div>
    );
};

// export the component as default

export default Card;