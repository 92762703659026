// import the react components
import React from "react";

// import the colors
import {danger} from "./../assets/data/colors";

// the file form functional based component
const FileForm = ({handle_change, handle_delete, folder, files}) => {

    // return to the component data
    return (
        <form className="mt-2">
            <div className="w-full text-center">
                <label className="bg-primary block mx-auto w-1/3 py-3 rounded-md text-white cursor-pointer">
                    <span className="text-lg leading-normal">select files</span>
                    <input type="file" className="hidden" onChange={handle_change} multiple />
                </label>
            </div>

            {/* the images */}
            <div className="flex flex-wrap justify-center items-center gap-4 mt-8">
                {files && files.map((item, index) => <div key={index} className="">
                    <img src={typeof item !== "object" ? folder + item : URL.createObjectURL(item)} className="rounded-md w-24 h-24 object-cover" />
                    <button type="button" className="py-2 text-white w-full mt-2 rounded-md" style={{background: danger}} onClick={() => handle_delete(index)}>remove</button>
                </div>)}
            </div>
        </form>
    );
};
export default FileForm;