// import the react components
import React, {useRef, Fragment} from "react";

// import the custome components
import Print from "./../../components/print";

// the patient data functional based component
const PatientData = ({heading, data, cols = "lg:grid-cols-3 md:grid-cols-2 grid-cols-1", do_print = true}) => {
    const reference = useRef(null);

    // return to the component data
    return (
        <div className="mb-10">
            <div ref={reference}>
                <h1 className="text-4xl mb-6 pb-4 border-b-2 border-gray-900 dark:text-gray-200 dark:border-b-gray-700">{heading}</h1>
                <div className={`grid gap-4 ${cols}`}>
                    {Object.keys(data).map(item => data[item] !== "" && <div key={item}>
                        <h2 className="bg-primary p-2 rounded-md text-gray-100 text-lg">{item}</h2>
                        <p className="mt-1 ml-1 leading-relaxed dark:text-gray-200">{data[item]}</p>
                    </div>)}
                </div>
            </div>

            {do_print && <div className="mt-10">
                <Print reference={reference} />
            </div>}
        </div>
    );
};
export default PatientData;