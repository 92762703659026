// import the react components
import React, {useState} from "react";

// import the dayjs components
import dayjs from "dayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {MobileTimePicker} from "@mui/x-date-pickers/MobileTimePicker";

// import the icons
import {CgCalendarDates} from "react-icons/cg";
import {MdSegment} from "react-icons/md";
import {BsBookmark} from "react-icons/bs";
import {AiOutlineCheck} from "react-icons/ai";
import {MdAccessTime} from "react-icons/md";
import {MdWorkOutline} from "react-icons/md";

// import the context
import {useStateContext} from "./../../context/";

// import the colors
import {labels_colors} from "./../../assets/data/colors";

// import the styles
import {input_style, icon_style} from "./../../assets/data/style";

// import the custome components
import Modal from "./../modal";

// import the api components
import axios from "axios";
import {CALENDAR_API} from "./../../assets/data/api";

// the event modal functional based component
const EventModal = () => {
    const {set_show_event_modal, set_patient_modal, selected_day, dispatch_event, set_selected_event, selected_event, is_loged, set_alert} = useStateContext();
    const [state, set_state] = useState({
        code: selected_event ? selected_event.code : "",
        title: selected_event ? selected_event.title : "",
        from_time: selected_event ? dayjs(selected_event.from_time) : dayjs(),
        to_time: selected_event ? dayjs(selected_event.to_time) : dayjs(),
        description: selected_event ? selected_event.description : "",
        type: selected_event ? selected_event.type : "appointment",
        label: selected_event ? selected_event.label : labels_colors[0],
    });

    // handle the close
    const handle_close = () => {
        set_show_event_modal(false);
        set_selected_event(null);
    };

    // save the event data
    const handle_submit = () => {

        // prepare the query
        const {code, title, from_time, to_time, description, type, label} = state;
        const endpoint = CALENDAR_API + `${selected_event ? "update.php" : "create.php"}`;
        const data = {
            id: selected_event ? selected_event.id : "",
            username: is_loged.username,
            code,
            title,
            day: selected_day,
            from_time: from_time,
            to_time: to_time,
            description,
            type,
            label,
        };

        // send the request to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success){
                selected_event ? dispatch_event({type: "update", payload: data.event}) : dispatch_event({type: "push", payload: data.event});
                set_alert({type: "success", text: "event saved successfully"});
                handle_close();
            }

            // if the error occured
            else if (data.error) set_alert({type: "error", text: data.error});
        });
    };

    // delete the event
    const handle_delete = () => {

        // prepare the query
        const endpoint = CALENDAR_API + "delete.php";
        const data = {id: selected_event.id};

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success){
                dispatch_event({type: "delete", payload: selected_event});
                set_alert({type: "success", text: "event deleted successfully"});
                handle_close();
            }

            // if the error occured
            else if (data.error) set_alert({type: "error", text: data.error});
        });
    };

    // handle the book patient
    const handle_book = () => {
        set_patient_modal(true);
    };

    // the buttons of the footer
    const buttons = [
        {
            title: "save",
            handler: handle_submit,
        }
    ];
    if (selected_event) buttons.unshift({title: "delete", handler: handle_delete});
    if (!selected_event) buttons.unshift({title: "book patient", handler: handle_book});

    // extract the data
    const {code, title, from_time, to_time, description, type, label} = state;

    // return to the component data
    return (
        <Modal handle_close={handle_close} buttons={buttons} z_number={10}>
            <div className="grid grid-cols-1/6 items-center gap-y-7">

                {/* the code input */}
                <div></div>
                <input
                    type="text"
                    name="code"
                    placeholder="set the patient code"
                    value={code}
                    onChange={event => set_state({...state, code: event.target.value})} 
                    required
                    className={`${input_style}`}
                />

                {/* the title input */}
                <div></div>
                <input
                    type="text"
                    name="title"
                    placeholder="set the title"
                    value={title}
                    onChange={event => set_state({...state, title: event.target.value})} 
                    required
                    className={`${input_style}`}
                />

                {/* the selected date */}
                <span className={icon_style}><CgCalendarDates /></span>
                <p className="dark:text-gray-200">{selected_day.format("dddd, MMMM DD")}</p>

                {/* the time input */}
                <span className={icon_style}><MdAccessTime /></span>
                <div className="flex justify-start items-end gap-x-4 ml-1">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <span className="dark:text-gray-200">from:</span>
                        <MobileTimePicker orientation="landscape" value={from_time} onChange={new_value => set_state({...state, from_time: new_value})} slotProps={{textField: {variant: "standard"}}} />
                        <span className="dark:text-gray-200">until:</span>
                        <MobileTimePicker orientation="landscape" value={to_time} onChange={new_value => set_state({...state, to_time: new_value})} slotProps={{textField: {variant: "standard"}}} />
                    </LocalizationProvider>
                </div>

                {/* the description input */}
                <span className={icon_style}><MdSegment /></span>
                <input
                    type="text"
                    name="description"
                    placeholder="set the description"
                    value={description}
                    onChange={event => set_state({...state, description: event.target.value})} 
                    required
                    className={`${input_style}`}
                />

                <span className={icon_style}><MdWorkOutline /></span>
                <select className={input_style} onChange={event => set_state({...state, type: event.target.value})} value={type}>
                    <option value="appointment">appointment</option>
                    <option value="event">event</option>
                </select>

                {/* the label selection */}
                <span className={icon_style}><BsBookmark /></span>
                <div className="flex gap-x-2">
                    {labels_colors.map(item => (
                        <span key={item} style={{background: item}} className={`w-6 h-6 rounded-full flex justify-center items-center cursor-pointer`} onClick={() => set_state({...state, label: item})}>
                            {label === item && <span className="text-sm text-gray-900"><AiOutlineCheck /></span>}
                        </span>
                    ))}
                </div>
            </div>
        </Modal>
    );
};
export default EventModal;