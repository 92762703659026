// import the react components
import React, {useEffect, useState} from "react";

// import the custome components
import CourseModal from "./../ui/course/course-modal";
import Title from "./../ui/title";
import SearchBar from "./../ui/search-bar";
import Button from "./../ui/button";
import FilterHeader from "./../ui/filter-header";
import ListWrapper from "./../ui/list-wrapper";
import CourseItem from "./../ui/course/course-item";
import NoData from "./../ui/no-data";

// import the icons
import {FaChalkboardTeacher} from "react-icons/fa";

// import the api components
import axios from "axios";
import {COURSE_API} from "./../assets/data/api";

// import the book categories
import {book_categories} from "./../assets/data/book";

// import the context
import {useStateContext} from "./../context/";
import useNavigation from "./../context/navigation";

// the courses functional based component
const Courses = () => {
    const {course_modal, set_course_modal, saved_courses, dispatch_course, set_is_loading, course_filter, set_course_filter} = useStateContext();
    const [search, set_search] = useState("");
    useNavigation();

    // get the courses
    useEffect(() => {
        saved_courses.length === 0 && set_is_loading(true);

        // prepare the query
        const endpoint = COURSE_API + "read.php";

        // send the query
        axios.post(endpoint).then(response => {
            const {data} = response;
            if (data.success) dispatch_course({type: "add", payload: data.courses});
            set_is_loading(false);
        });
    }, []);

    // return to the component data
    return (
        <div>

            {/* the modals */}
            {course_modal && <CourseModal />}

            {/* the title of the webpage */}
            <Title text="courses" icon={<FaChalkboardTeacher />} />

            {/* the header of the webpage */}
            <div className="flex justify-between items-center">
                <SearchBar placeholder="search courses" value={search} handler={value => set_search(value)} />
                <Button title="add" action="button" handler={() => set_course_modal(true)} />
            </div>

            {/* the header of course */}
            <FilterHeader data={book_categories} active={course_filter} handler={item => set_course_filter(item)} />

            {/* the courses */}
            {saved_courses.length > 0 ? <ListWrapper grid="lg:grid-cols-4">
                {saved_courses.map(course => (course_filter === "all" || course_filter === course.category) && <CourseItem key={course.id} course={course} />)}
            </ListWrapper>
            :
            <NoData message="No courses being shared, prepare your self to instruct a course." />}
        </div>
    );
};
export default Courses;