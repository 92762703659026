// import the react components
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

// import the custome components
import Modal from "./../modal";

// import the styles
import {input_style} from "./../../assets/data/style";

// import the api components
import axios from "axios";
import {PATIENT_API} from "./../../assets/data/api";

// import the context
import {useStateContext} from "./../../context/";

// the patient modal functional based component
const PatientModal = () => {
    const {set_patient_modal, selected_patient, set_selected_patient, is_loged, dispatch_patient, set_alert} = useStateContext();
    const [state, set_state] = useState({
        name: selected_patient ? selected_patient.name : "",
        code: selected_patient ? selected_patient.code : "",
    });

    // the navigate
    const navigate = useNavigate();

    // close the modal
    const handle_close = () => {
        set_patient_modal(false);
        set_selected_patient(null);
    };

    // handle the create and update
    const handle_submit = () => {

        // prepare the query
        const endpoint = PATIENT_API + `${selected_patient ? "update.php" : "create.php"}`;
        const data = {
            ...state,
            id: selected_patient ? selected_patient.id : "",
            username: is_loged.username,
            status: "main",
        };

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success){
                selected_patient ? dispatch_patient({type: "update", payload: data.patient}) : dispatch_patient({type: "push", payload: data.patient});
                set_alert({type: "success", text: "the patient saved successfuly"});
                handle_close();
            }

            // if the error occured
            else if (data.error) set_alert({type: "error", text: data.error});
        })
    };

    // handle the delete
    const handle_delete = () => {

        // prepare the query
        const endpoint = PATIENT_API + "delete.php";
        const data = {id: selected_patient.id};

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success){
                dispatch_patient({type: "delete", payload: selected_patient});
                set_alert({type: "success", text: "the patient deleted successfuly"});
                handle_close();
            }

            // if the error occured
            else if (data.error) set_alert({type: "error", text: data.error});
        });
    };

    // the footer buttons
    const buttons = [
        {
            title: "save",
            handler: handle_submit,
        },
    ];
    if (selected_patient){
        buttons.unshift({
            title: "delete",
            handler: handle_delete,
        });
        buttons.unshift({
            title: "view",
            handler: () => navigate(`/view/patient/${selected_patient.id}`),
        });
        buttons.unshift({
            title: "update",
            handler: () => navigate(`/book/${selected_patient.id}`),
        });
    }

    // extract the data
    const {name, code} = state;

    // return to the component data
    return (
        <Modal handle_close={handle_close} buttons={buttons} z_index={10}>
            <div className="grid grid-cols-1 gap-y-4">
                <input
                    type="text"
                    name="name"
                    placeholder="set the full name"
                    value={name}
                    onChange={event => set_state({...state, name: event.target.value})}
                    className={`${input_style}`}
                    required
                />

                <input
                    type="text"
                    name="code"
                    placeholder="set the code"
                    value={code}
                    onChange={event => set_state({...state, code: event.target.value})}
                    className={`${input_style}`}
                    required
                />
            </div>
        </Modal>
    );
};
export default PatientModal;