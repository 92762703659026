// import the react components
import {useEffect} from "react";
import {useNavigate, useLocation} from "react-router-dom";

// import the contexts
import useAuth from "./auth";

// check the navigation
const useNavigation = () => {
    const {outsider, insider, auth} = useAuth();

    // the navigation
    const navigate = useNavigate();
    const {pathname} = useLocation();

    // navigate the user
    useEffect(() => {
        if (outsider.includes(pathname) && auth) navigate("/schedule");
        else if ((insider.includes(pathname) || pathname.includes("/view/") || pathname.includes("/book/")) && !auth) navigate("/");
    }, []);
}
export default useNavigation;