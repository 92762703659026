// import the react components
import React, {useEffect, useState} from "react";
import dayjs from "dayjs";

// import the icons
import {GoPerson} from "react-icons/go";
import {BsCalendar2Fill} from "react-icons/bs";
import {VscSymbolEvent} from "react-icons/vsc";

// import the custome components
import DayModal from "./../ui/schedule/day-modal";
import EventModal from "./../ui/schedule/event-modal";
import PatientModal from "./../ui/patient/patient-modal";
import Title from "./../ui/title";
import DashboardCard from "./../ui/schedule/dashboard-card";
import CalendarHeader from "./../ui/schedule/calendar-header";
import Month from "./../ui/schedule/month";

// import the imgae for the cards
import circular_image from "./../assets/images/circle.png";

// import the colors
import {gradient_danger, gradient_info, gradient_success} from "./../assets/data/colors";

// import the utulities
import {get_month} from "./../assets/data/utilities";

// import the context
import {useStateContext} from "./../context/";
import useNavigation from "./../context/navigation";

// import the api components
import axios from "axios";
import {CALENDAR_API} from "./../assets/data/api";

// the schedule funtional based component
const Schedule = () => {
    const {month_index, show_event_modal, show_day_modal, patient_modal, is_loged, dispatch_event, set_is_loading, saved_events} = useStateContext();
    const [current_month, set_current_month] = useState(get_month());
    const [statistic, set_statistic] = useState({patients: 0, events: 0, tomorrow: 0, remaining_patients: 0, remaining_events: 0});
    useNavigation();

    // check if the month changed
    useEffect(() => {
        set_current_month(get_month(month_index));
    }, [month_index]);

    // get the current user events
    useEffect(() => {
        saved_events.length === 0 && set_is_loading(true);

        // prepare the query
        const endpoint = CALENDAR_API + "read.php";
        const data = {username: is_loged.username};

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success) dispatch_event({type: "add", payload: data.events});
            set_is_loading(false);
        });
    }, []);

    // get the statistic
    const card_statistic = () => {

        // the statistic data
        let patients = [];
        let events = [];
        let tomorrow = [];
        let remaining_patients = 0;
        let remaining_events = 0;

        // filter the events
        const format = "DD-MM-YY";
        const day_format = "HH:mm:ss";
        saved_events.filter(event => {
            if (dayjs(event.day).format(format) === dayjs().format(format)){

                // separate the data
                if (event.type === "appointment") patients.push(event);
                else if (event.type === "event") events.push(event);
            }

            else if (dayjs(event.day).format(format) === dayjs().add(1, "day").format(format)){
                if (event.type === "appointment") tomorrow.push(event);
            }
        });

        // check the remaining
        patients.length > 0 && patients.map(item => dayjs(item.from_time).format(day_format) > dayjs().format(day_format) && (remaining_patients += 1))
        events.length > 0 && events.map(item => dayjs(item.from_time).format(day_format) > dayjs().format(day_format) && (remaining_events += 1))

        // update the statistic
        set_statistic({patients: patients.length, events: events.length, tomorrow: tomorrow.length, remaining_patients, remaining_events});
    };
    useEffect(card_statistic, [saved_events]);

    // the dashboard cards
    const card_data = [
        {
            h4: "today's patients",
            h2: `${statistic.patients} patients`,
            h6: `remaining ${statistic.remaining_patients} patients`,
            bg_color: gradient_danger,
            icon: <GoPerson />,
        },
        {
            h4: "today's events",
            h2: `${statistic.events} events`,
            h6: `remaining ${statistic.remaining_events} events`,
            bg_color: gradient_info,
            icon: <VscSymbolEvent />,
        },
        {
            h4: "tomorrow patients",
            h2: `${statistic.tomorrow} patients`,
            h6: `remaining ${statistic.tomorrow} patients`,
            bg_color: gradient_success,
            icon: <GoPerson />,
        },
    ];

    // return to the component data
    return (
        <div>

            {/* show the modals */}
            {show_day_modal && <DayModal />}
            {show_event_modal && <EventModal />}
            {patient_modal && <PatientModal />}

            {/* the title of the page */}
            <Title text="schedule" icon={<BsCalendar2Fill />} />

            {/* the top schedule data */}
            <div className="flex flex-wrap justify-center lg:flex-nowrap gap-3">
                {card_data.map(item => (
                    <DashboardCard
                        key={item.bg_color}
                        image={circular_image}
                        h4={item.h4}
                        h2={item.h2}
                        h6={item.h6}
                        icon={item.icon}
                        bg_color={item.bg_color}
                    />
                ))}
            </div>

            {/* the calender for booking an event */}
            <div className="flex flex-col mt-8 mb-10" style={{height: "1000px"}}>
                <CalendarHeader />
                <Month month={current_month} />
            </div>
        </div>
    );
};
export default Schedule;