// import the react components
import React, {useState} from "react";

// import the icons
import {RiPieChart2Fill} from "react-icons/ri";
import {BsFillPersonFill} from "react-icons/bs";
import {MdProductionQuantityLimits} from "react-icons/md";
import {TbPigMoney} from "react-icons/tb";

// import the custome components
import Title from "./../ui/title";
import DashboardCard from "../ui/schedule/dashboard-card";
import FilterHeader from "./../ui/filter-header";
import StatisticsWrapper from "./../ui/statistic/statistic-wrapper";
import Chart from "./../components/chart";

// import the imgae for the cards
import circular_image from "./../assets/images/circle.png";

// import the colors
import {gradient_dark, gradient_primary, gradient_info} from "./../assets/data/colors";

// import the context
import useNavigation from "./../context/navigation";

// the statistics funtional based component
const Statistics = () => {
    const from_year = ["year", "month", "week"];
    const [time, set_time] = useState(from_year[0]);
    useNavigation();

    // the dashboard cards
    const card_data = [
        {
            h4: "weekly patients",
            h2: "0 patients",
            h6: "increased by 0%",
            bg_color: gradient_primary,
            icon: <BsFillPersonFill />,
        },
        {
            h4: "weekly orders",
            h2: "0 orders",
            h6: "increased by 0%",
            bg_color: gradient_info,
            icon: <MdProductionQuantityLimits />,
        },
        {
            h4: "weekly revenue",
            h2: "0$",
            h6: "increased by 0%",
            bg_color: gradient_dark,
            icon: <TbPigMoney />,
        },
    ];

    // return to the component data
    return (
        <div>

            {/* the title of the page */}
            <Title text="statistics" icon={<RiPieChart2Fill />} />

            {/* the top statistics */}
            <div className="flex flex-wrap justify-center lg:flex-nowrap gap-3">
                {card_data.map(item => (
                    <DashboardCard
                        key={item.bg_color}
                        image={circular_image}
                        h4={item.h4}
                        h2={item.h2}
                        h6={item.h6}
                        icon={item.icon}
                        bg_color={item.bg_color}
                    />
                ))}
            </div>

            {/* the times for the statistics */}
            <FilterHeader data={from_year} active={time} handler={item => set_time(item)} />

            {/* the revenue statistics */}
            <StatisticsWrapper
                has_second_card={false}
                is_grown_first={true}
                first_card_title="the total revenue by USD"
            >
                <Chart type="bar" />
            </StatisticsWrapper>

            <StatisticsWrapper
                has_second_card={true}
                is_grown_first={false}
                first_card_title="the clinical revenue by USD"
                second_card_title="the products revenue by USD"
            >
                <Chart type="line" />
                <Chart type="line" />
            </StatisticsWrapper>

            {/* the patient's related statistics */}
            <StatisticsWrapper
                has_second_card={true}
                is_grown_first={true}
                first_card_title="the total patients"
                second_card_title="the patient traffic"
            >
                <Chart type="bar" />
                <Chart type="pie" />
            </StatisticsWrapper>

            <StatisticsWrapper
                has_second_card={true}
                is_grown_first={false}
                first_card_title="the clinical patients"
                second_card_title="the received patients"
            >
                <Chart type="line" />
                <Chart type="line" />
            </StatisticsWrapper>

            {/* the book's related statistics */}
            <StatisticsWrapper
                has_second_card={false}
                is_grown_first={true}
                first_card_title="the total online book"
            >
                <Chart type="bar" />
            </StatisticsWrapper>

            {/* the order's related statistics */}
            <StatisticsWrapper
                has_second_card={true}
                is_grown_first={false}
                first_card_title="the total orders"
                second_card_title="the total products"
            >
                <Chart type="line" />
                <Chart type="line" />
            </StatisticsWrapper>
        </div>
    );
};
export default Statistics;