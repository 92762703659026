// import the react components
import React, {useState} from "react";

// import the custome components
import Modal from "./../modal";

// import the styles
import {input_style} from "./../../assets/data/style";

// import the api components
import axios from "axios";
import {REQUEST_API} from "./../../assets/data/api";

// import the context
import {useStateContext} from "./../../context/";

// import the general data
import {token} from "./../../assets/data/general";

// the book modal functional based component
const BookModal = ({book}) => {
    const {set_book_modal, is_loged, set_alert} = useStateContext();
    const [state, set_state] = useState({
        description: "",
        location: "",
    });

    // close the modal
    const handle_close = () => {
        set_book_modal(false);
    };

    // handle the create and update
    const handle_submit = () => {

        // prepare the query
        const endpoint = REQUEST_API + "create.php";
        const data = {
            username: is_loged.username,
            type: book.id,
            request: "book",
            description: state.description + token + state.location,
        };

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success){
                set_alert({type: "success", text: "the request saved successfuly"});
                handle_close();
            }

            // if the error occured
            else if (data.error) set_alert({type: "error", text: data.error});
        })
    };

    // the footer buttons
    const buttons = [
        {
            title: "save",
            handler: handle_submit,
        },
    ];

    // extract the data
    const {description, location} = state;

    // return to the component data
    return (
        <Modal handle_close={handle_close} buttons={buttons} z_index={10}>
            <div className="grid grid-cols-1 gap-y-4">
                <input
                    type="text"
                    name="description"
                    placeholder="set the description"
                    value={description}
                    onChange={event => set_state({...state, description: event.target.value})} 
                    required
                    className={`${input_style}`}
                />

                <input
                    type="text"
                    name="location"
                    placeholder="set the location"
                    value={location}
                    onChange={event => set_state({...state, location: event.target.value})} 
                    required
                    className={`${input_style}`}
                />
            </div>
        </Modal>
    );
};
export default BookModal;