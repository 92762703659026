// import the react components
import React, {useEffect, useState, useRef} from "react";

// import the custome components
import Print from "./../../components/print";
import NoViewData from "./../no-view-data";

// import the api components
import axios from "axios";
import {NOTE_API} from "./../../assets/data/api";

// import the context
import {useStateContext} from "./../../context/";

// the note view functional based component
const NoteView = ({id}) => {
    const {set_alert, set_is_loading, is_loged} = useStateContext();
    const [note, set_note] = useState({});
    const print_ref = useRef(null);

    // get the note data
    useEffect(() => {
        set_is_loading(true);

        // prepare the query
        const endpoint = NOTE_API + "read-single.php";
        const data = {id, username: is_loged.username};

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success) set_note(data.note);

            // if the error occured
            else if (data.error) set_alert({type: "error", text: data.error});
            set_is_loading(false);
        });
    }, []);

    // return to the component data
    return (
        <div className="mt-8">

            {/* the note data */}
            {note.id ? <div>
                <h1 className="text-3xl bg-primary py-2 px-6 rounded text-gray-100 -mx-3">{note.type}</h1>
                <div ref={print_ref}>
                    <h1 className="mt-5 text-4xl mb-2 dark:text-gray-200">{note.title}</h1>
                    <p dangerouslySetInnerHTML={{__html: note.description}} className="leading-loose dark:text-gray-200" id="blog" />
                </div>

                <div className="mt-5 flex justify-center items-center">
                    <Print reference={print_ref} />
                </div>
            </div>
            :
            <NoViewData />}
        </div>
    );
};
export default NoteView;