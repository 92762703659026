// import the react and react-dom components
import React from "react";
import {Link} from "react-router-dom";

// import the logo
import logo from "./../../assets/images/logo.png";

// import the colors
import {primary} from "./../../assets/data/colors";

// the header functional based component
const Header = ({heading, paragraph, paragraph_extra, link_name, link_url}) => {

    // return to the component data
    return (
        <div className="mb-8 flex flex-col justify-center items-center">
            <Link to="/">   
                <img src={logo} alt="the dentists hub logo" className="w-20 mb-3" />
            </Link>
            <h2 className="text-center text-3xl font-extrabold text-gray-900 dark:text-gray-200">{heading}</h2>
            <p className="text-center text-sm text-gray-600 dark:text-gray-400 mt-3">
                {paragraph} {" "} {paragraph_extra && <span className="font-extrabold text-gray-700 dark:text-gray-300">{paragraph_extra}</span>}
                {link_name && <Link to={link_url} className="font-medium hover:opacity-90" style={{color: primary}}>{link_name}</Link>}
            </p>
        </div>
    );
};
export default Header;