// import the react components
import React from "react";

// import the rechapta
import ReCAPTCHA from "react-google-recaptcha";

// import the context
import {useStateContext} from "./../../context/";

// the form action functional based component
const FormAction = ({action = "submit", handle_submit, text}) => {
    const {is_disable} = useStateContext();

    //return to the component data
    return (
        <div>
            <ReCAPTCHA sitekey={process.env.REACT_APP_SECRET_KEY} size="invisible" />
            <button
                type={action}
                className="capitalize tracking-wider group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:opacity-90 focus:outline-none focus:ring-0 mt-10 disabled:opacity-50"
                onClick={handle_submit}
                disabled={is_disable}
            >
                {text}
            </button>
        </div>
    );
};
export default FormAction;