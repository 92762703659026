// import the react components
import React, {useEffect, useState} from "react";

// import the icons
import {RiNotification4Line} from "react-icons/ri";

// import the custom components
import Title from "./../ui/title";
import Table from "./../components/table";
import TableBody from "./../components/table-body";
import NotificationItem from "./../ui/notification/notification-item";

// import the api components
import axios from "axios";
import {NOTIFICATION_API} from "./../assets/data/api";

// import the context
import {useStateContext} from "./../context/";
import useNavigation from "./../context/navigation";

// the notification functional based component
const Notification = () => {
    const {is_loged, saved_notifications, dispatch_notification, set_is_loading} = useStateContext();
    const [page, set_page] = useState(1);
    useNavigation();

    // get the notifications
    useEffect(() => {
        saved_notifications.length === 0 && set_is_loading(true);

        // prepare the endpoint
        const endpoint = NOTIFICATION_API + "read.php";
        const data = {
            username: is_loged.username,
            mail: is_loged.mail,
        };

        // send the query to the server
        axios.post(endpoint, data).then(response => {
            const {data} = response;
            if (data.success) dispatch_notification({type: "add", payload: data.notifications});
            set_is_loading(false);
        });
    }, []);

    // the header of the table
    const headers = ["id", "date", "from", "notification"];

    // return to the component data
    return (
        <div>

            {/* the title of the page */}
            <Title text="notification" icon={<RiNotification4Line />} />

            {/* the table of notifications */}
            <Table paragraph="This table shows the list of notifications either from the events or system." header={headers} body={saved_notifications} page={page} set_page={set_page}>
                <TableBody page={page} data={saved_notifications} Component={NotificationItem} />
            </Table>
        </div>
    );
};
export default Notification;