// the patient's information related inputs
export const top_patients_info = [
    {
        type: "input",
        label: "date of birth",
    },
    {
        type: "select",
        label: "gender",
        options: ["male", "female"],
    },
    {
        type: "input",
        label: "city",
    },
    {
        type: "input",
        label: "nationality",
    },
    {
        type: "input",
        label: "marital status",
    },
    {
        type: "input",
        label: "occupation",
    },
    {
        type: "input",
        label: "phone number",
    },
    {
        type: "input",
        label: "email address",
    },
    {
        type: "input",
        label: "home address",
    },
    {
        type: "input",
        label: "last dentist name",
    },
    {
        type: "input",
        label: "last dentist date",
    },
    {
        type: "input",
        label: "last consult name",
    },
    {
        type: "input",
        label: "last consult date",
    },
];

// the anamnesis information
export const anamnesis = [
    {
        type: "textarea",
        label: "chief complain",
    },
    {
        type: "textarea",
        label: "history of the present illness",
    },
];

// the medical history information
export const medical_info = [
    {
        type: "input",
        label: "patient's medical condition",
    },      
    {
        type: "input",
        label: "physicion name",
    },
    {
        type: "input",
        label: "physicion number",
    },
    {
        type: "input",
        label: "patient's current medications",
    },
    {
        type: "input",
        label: "patient's preview medications",
    },
    {
        type: "input",
        label: "patient received dental anesthesia",
    },
    {
        type: "input",
        label: "patient been hospitalized",
    },
    {
        type: "input",
        label: "patient undergone surgery",
    },
    {
        type: "input",
        label: "patient gained or lost weight quickly recently",
    },
    {
        type: "input",
        label: "patient practice sports or physical activity",
    },
    {
        type: "input",
        label: "patient is alcoholism",
    },
    {
        type: "input",
        label: "patient is smoker",
    },
    {
        type: "input",
        label: "patient receive cancer therapy",
    },
    {
        type: "input",
        label: "patient received cancer therapy",
    },
];

// the details medical history information
export const details_medical_info = [
    {
        type: "textarea",
        label: "patients medications",
    },
    {
        type: "textarea",
        label: "breathing problems",
    },
    {
        type: "textarea",
        label: "cardiovascular problems",
    },
    {
        type: "textarea",
        label: "allergies",
    },
    {
        type: "textarea",
        label: "kidney problems",
    },
    {
        type: "textarea",
        label: "women condition",
    },
    {
        type: "textarea",
        label: "hepatic problems",
    },
    {
        type: "textarea",
        label: "joint and bone problems",
    },
    {
        type: "textarea",
        label: "transmissible diseases",
    },
    {
        type: "textarea",
        label: "endocrine disorders",
    },
    {
        type: "textarea",
        label: "ophthalmic problems",
    },
    {
        type: "textarea",
        label: "blood problems",
    },
    {
        type: "textarea",
        label: "salivary problems",
    },
    {
        type: "textarea",
        label: "neurological problems",
    },
    {
        type: "textarea",
        label: "gastrointestinal problems",
    },
    {
        type: "textarea",
        label: "family history",
    },
];

// the dental history inputs
export const dental_info = [
    {
        type: "input",
        label: "patient receive professional oral hygiene instructions",
    },
    {
        type: "input",
        label: "teeth brush per day",
    },
    {
        type: "input",
        label: "patient uses dental floss",
    },
    {
        type: "input",
        label: "patient gums bleed",
    },
    {
        type: "input",
        label: "teeth sensitive to temperature changes",
    },
    {
        type: "input",
        label: "teeth sensitive to sweets",
    },
    {
        type: "input",
        label: "teeth sensitive when biting",
    },
    {
        type: "input",
        label: "feeling any pain on teeth",
    },
    {
        type: "input",
        label: "location of sensitivity",
    },
    {
        type: "input",
        label: "location of pain",
    },
    {
        type: "input",
        label: "patient's oral habits",
    },
    {
        type: "input",
        label: "patient breathe through the mouth",
    },
    {
        type: "input",
        label: "patient drink coffee/soda during the day",
    },
    {
        type: "input",
        label: "patient frequently have mouth sores",
    },
    {
        type: "input",
        label: "patient have tooth mobility",
    },
    {
        type: "input",
        label: "patient receive orthodontic treatment",
    },
    {
        type: "input",
        label: "patient satisfied with oral aesthetics",
    },
    {
        type: "input",
        label: "patient grind or cliench teeth",
    },
    {
        type: "input",
        label: "feel pain in the ear, head, face or neck",
    },
    {
        type: "input",
        label: "difficulty to open mouth",
    },
    {
        type: "input",
        label: "problems with dental treatment",
    },
    {
        type: "input",
        label: "practices any sports",
    },
    {
        type: "input",
        label: "food deposit between teeth",
    },
    {
        type: "input",
        label: "how regularily patient visit dentist",
    },
];

// general clinical exam
export const general_exam = [
    {
        type: "input",
        label: "blood pressure",
    },
    {
        type: "input",
        label: "random blood glucose level",
    },
    {
        type: "input",
        label: "fasting blood glucose level",
    },
    {
        type: "input",
        label: "heart rate",
    },
    {
        type: "input",
        label: "respiratory rate",
    },
    {
        type: "input",
        label: "body tempreture",
    },
];

// the extra oral exam
export const extra_exam = [
    {
        type: "textarea",
        label: "lymph nodes",
    },
    {
        type: "textarea",
        label: "swellings",
    },
    {
        type: "textarea",
        label: "asymmetry",
    },
    {
        type: "textarea",
        label: "muscle palpation",
    },
    {
        type: "textarea",
        label: "TMJ palpation",
    },
    {
        type: "textarea",
        label: "lesions",
    },
    {
        type: "textarea",
        label: "neck",
    },
    {
        type: "textarea",
        label: "others",
    },
];

// the occlusion exam
export const occlusion_exam = [
    {
        type: "input",
        label: "angle classification",
    },
    {
        type: "input",
        label: "overbite",
    },
    {
        type: "input",
        label: "overjet",
    },
    {
        type: "input",
        label: "tooth extrusions",
    },
    {
        type: "input",
        label: "tooth migration or leaning",
    },
    {
        type: "input",
        label: "anterior open bite",
    },
    {
        type: "input",
        label: "posterior open bite",
    },
    {
        type: "input",
        label: "loss of molar support",
    },
    {
        type: "input",
        label: "loss of vertical dimension",
    },
    {
        type: "input",
        label: "relation between CR and CO",
    },
    {
        type: "input",
        label: "anterior disocclusion guidance",
    },
    {
        type: "input",
        label: "right lateral disocclusion guidance",
    },
    {
        type: "input",
        label: "left lateral disocclusion guidance",
    },
    {
        type: "input",
        label: "wearing facet from attrition",
    },
    {
        type: "input",
        label: "abfraction?",
    },
    {
        type: "input",
        label: "nonworking side interference",
    },
    {
        type: "input",
        label: "protrusion interference",
    },
    {
        type: "input",
        label: "amount of mouth opening",
    },
    {
        type: "input",
        label: "deviation when opening",
    },
];

// the tissue exam
export const tissue_exam = [
    {
        type: "textarea",
        label: "lips",
    },
    {
        type: "textarea",
        label: "checks",
    },
    {
        type: "textarea",
        label: "vestibule",
    },
    {
        type: "textarea",
        label: "dorsum of tongue",
    },
    {
        type: "textarea",
        label: "ventral surface of tongue",
    },
    {
        type: "textarea",
        label: "floor of mouth",
    },
    {
        type: "textarea",
        label: "hard palate",
    },
    {
        type: "textarea",
        label: "soft palate",
    },
    {
        type: "textarea",
        label: "tonsils",
    },
    {
        type: "textarea",
        label: "pharynx",
    },
];

// the treatment inputs
export const treatment_inputs = [
    {
        label: "problem",
        name: "problem",
    },
    {
        label: "treatment option",
        name: "treatment_option",
    },
    {
        label: "fees",
        name: "fees",
    },
    {
        label: "date",
        name: "date",
    },
];

// the teeth inputs
export const conditions = ["lesion", "deficient restoration", "satisfactory restoration", "absent tooth", "endodontic present", "implant", "direct restoration", "temporary restoration", "crown", "veneer", "fixed partial denture", "removable partial denture", "fissure sealant", "discolored", "white spot"];
export const teeth_inputs = [
    {
        label: "teeth number",
        name: "tooth",
    },
    {
        label: "condition",
        name: "condition",
        options: [...conditions],
    },
    {
        label: "surfaces condition",
        name: "surface",
    },
    {
        label: "periodontium",
        name: "periodontium",
    },
    {
        label: "root",
        name: "root",
    },
    {
        label: "periapical",
        name: "periapical",
    },
]

// the forms of the patient's data
export const forms = [
    {
        title: "patient's information",
        paragraph: "the general personal information related to the patient.",
        data: top_patients_info,
        name: "information",
    },
    {
        title: "anamnesis",
        paragraph: "the details about the patient's condition.",
        data: anamnesis,
        styles: "grid-cols-2",
        name: "anamnesis",
    },
    {
        title: "medical history",
        paragraph: "the details about the patient's medical history.",
        data: medical_info,
        name: "medical_history",
    },
    {
        title: "compromised patient",
        paragraph: "the details about the compromised patient's condition.",
        data: details_medical_info,
        name: "compromised_patient",
    },
    {
        title: "dental history",
        paragraph: "the details about the patient's dental history.",
        data: dental_info,
        name: "dental_history",
    },
    {
        title: "general evaluation",
        paragraph: "general patient's health.",
        data: general_exam,
        name: "general_evaluation",
    },
    {
        title: "extraoral evaluation",
        paragraph: "examination of patient's extraoral region.",
        data: extra_exam,
        name: "extraoral_evaluation",
    },
    {
        title: "occlusion evaluation",
        paragraph: "examination of patient's occlusion.",
        data: occlusion_exam,
        name: "occlusion_evaluation",
    },
    {
        title: "intraoral tissue evaluation",
        paragraph: "examination of patient's intraoral soft and hard tissues.",
        data: tissue_exam,
        name: "intraoral_tissue_evaluation",
    },
    {
        title: "teeth evaluation",
        paragraph: "examination of patient's teeth.",
        component: true,
        name: "teeth_evaluation",
    },
    {
        title: "periodontal evaluation",
        paragraph: "the periodontal health of the patient.",
        component: true,
        name: "indexes",
    },
    {
        title: "xray images",
        paragraph: "the patient's xray images.",
        component: true,
        name: "xrays",
    },
    {
        title: "case images",
        paragraph: "the extraoral and intraoral images of the patient.",
        component: true,
        name: "cases",
    },
    {
        title: "treatment plan",
        paragraph: "the details of the patient's treatment plan.",
        component: true,
        name: "treatment_plan",
    },
];

// the products inputs
export const product_inputs = [
    {
        type: "input",
        name: "title",
        placeholder: "set the title",
    },
    {
        type: "editor",
        name: "brief",
        placeholder: "set brief description",
    },
    {
        type: "editor",
        name: "description",
        placeholder: "set the detail",
    },
    {
        type: "input",
        name: "colors",
        placeholder: "set the colors",
    },
    {
        type: "group",
        inputs: [
            {
                name: "pack_price",
                placeholder: "set the pack price",
            },
            {
                name: "unit_price",
                placeholder: "set the unit price",
            },
        ],
    },
    {

        type: "group",
        inputs: [
            {
                name: "pack_discount",
                placeholder: "set the pack discount",
            },
            {
                name: "unit_discount",
                placeholder: "set the unit discount",
            },
        ],
    },
    {

        type: "group",
        inputs: [
            {
                name: "stock",
                placeholder: "products in stock",
            },
            {
                name: "brand",
                placeholder: "set brand name",
            },
        ],
    },
];

// the profile sections
export const profile_sections = [
    {
        title: "user details",
        data: [
            {
                title: "id",
                value: "id",
            },
            {
                title: "username",
                value: "username",
            },
            {
                title: "platform",
                value: "platform",
            },
            {
                title: "joined date",
                value: "created_date",
            },
            {
                title: "account status",
                value: "status",
            },
            {
                title: "dental site",
                value: "site",
            },
            {
                title: "phone number",
                value: "phone",
            },
            {
                title: "email address",
                value: "mail",
            },
            {
                title: "full name",
                value: "name",
            },
            {
                title: "degree",
                value: "degree",
            },
            {
                title: "facebook",
                value: "facebook",
            },
            {
                title: "instagram",
                value: "instagram",
            },
            {
                title: "whatsapp",
                value: "whatsapp",
            },
        ],
    },
    {
        title: "update information",
        data: [
            {
                name: "name",
                title: "full name",
                value: "name",
                type: "input",
            },
            {
                name: "degree",
                title: "degree",
                value: "degree",
                type: "input",
            },
            {
                name: "about",
                title: "about",
                value: "about",
                type: "input",
            },
            {
                name: "facebook",
                title: "facebook link",
                value: "facebook",
                type: "input",
            },
            {
                name: "instagram",
                title: "instagram link",
                value: "instagram",
                type: "input",
            },
            {
                name: "whatsapp",
                title: "whatsapp number",
                value: "whatsapp",
                type: "input",
            },
        ],
    },
    {
        title: "change password",
        data: [
            {
                name: "password",
                title: "new password",
                value: "password",
                type: "password",
            },
            {
                name: "repassword",
                title: "confirm new password",
                value: "repassword",
                type: "password",
            },
            {
                name: "postpassword",
                title: "old password",
                value: "postpassword",
                type: "password",
            },
        ],
    },
    {
        title: "change profile",
        data: [
            {
                name: "logo",
                title: "profile picture",
                type: "file",
            },
        ],
    },
];

// the contact inputs
export const contact_inputs = [
    {
        name: "email",
        type: "text",
        placeholder: "Your email address",
        value: "email",
    },
    {
        name: "subject",
        type: "text",
        placeholder: "Let us know how we can help you",
        value: "subject",
    },
    {
        name: "message",
        type: "textarea",
        placeholder: "Leave a comment",
        value: "message",
    },
];