// import the react components
import React from "react";

// import the custome components
import FileForm from "./../file-form";

// the patient images functional based component
const PatientImages = ({name, state, state_handler, delete_handler, folder}) => {

    // the change handler
    const handle_change = event => {
        const files = event.target.files;
        for (let i = 0; i < files.length; i++) state_handler(name, files[i]);
    };

    // the delete handler
    const handle_delete = index => {
        delete_handler(name, index);
    };

    // return to the component data
    return (
        <div className="mt-10">
            <FileForm files={state} folder={folder} handle_change={handle_change} handle_delete={index => handle_delete(index)} />
        </div>
    );
};
export default PatientImages;