// import the react components
import React from "react";

// import the custome components
import Button from "./../button";

// the patient header functional based component
const PatientHeader = ({name, code, title, link}) => {

    // return to the component data
    return (
        <div className="my-14 max-w-max mx-auto block text-gray-900 py-5 px-10 shadow-md dark:bg-secondary-dark-bg rounded-md">
            <h1 className="flex flex-col justify-start items-start gap-4 text-lg">
                <span className="dark:text-gray-200"><span className="font-bold text-xl">full name:</span> {name}</span>
                <span className="dark:text-gray-200"><span className="font-bold text-xl">code:</span> {code}</span>
                <Button title={title} action="link" link={link} custom_class="w-32" />
            </h1>
        </div>
    );
};
export default PatientHeader;